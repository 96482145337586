import React, { PropsWithChildren } from "react";
import { Descriptions } from "antd";
import TenantConfiguration from "../../../../../util/tenantConfiguration";

interface Props<T> {
  showApproveTime?: boolean
  data: T;
  show?: boolean;
}

const Current = <T extends {[name: string]: unknown}>({ data, showApproveTime = false }: PropsWithChildren<Props<T>>)=> {
  return (
    <Descriptions title={data.formName} bordered size="small" column={3} style={{marginBottom: 10}}>
      <Descriptions.Item label="所属项目">{data.projectName}</Descriptions.Item>
      <Descriptions.Item label="所属中心">{data.centerName}</Descriptions.Item>
      <Descriptions.Item label="访视">{data.visitName}</Descriptions.Item>
      <Descriptions.Item label="表单">{data.formName}</Descriptions.Item>
      <Descriptions.Item label={TenantConfiguration.read('label')}>{data.subjectName} ({data.subjectCode})</Descriptions.Item>
      <Descriptions.Item label="采集人">{data.collector}{data.approveTime && showApproveTime ? `(${data.approveTime})` : ''}</Descriptions.Item>
    </Descriptions>
  )
}

export default Current;