import React from "react"

export const ControlContext = React.createContext({
  workbench: false,
  createUserCode: ''
})

interface ControlContextProps {
  workbench: boolean
  createUserCode: string
  children: React.ReactNode
}

export const ControlProvider:React.FC<ControlContextProps> = ({ children, createUserCode, workbench = false })=> {
  return (
    <ControlContext.Provider value={{workbench, createUserCode}}>
      {children}
    </ControlContext.Provider>
  )
}
