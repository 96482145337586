import { requestPostRes } from "../EdcService";

export interface BatchMosaicDataFile {
  deCode: string
  dataCode: string
  // 原图地址
  oldUrl: string
  // 新图地址
  newUrl: string
}

// 签署模板信息
type BatchMosaicPayload = {
  formId: string
  files: BatchMosaicDataFile[]
}
export const batchMosaic = (payload: BatchMosaicPayload)=> {
  return requestPostRes('/file/image/new/desensitizate', payload)
}
