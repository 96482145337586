import React, { useRef, useState } from "react"
import Statistics, { StatisticsActions } from "./components/Statistics"

import indexStyl from './index.module.scss'
import Condition, { TaskStatus, Params, StatusEnum } from "./components/Condition"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import DetailModal from "./components/DetailModal"
import { getTaskDistributeInputList, getTaskDistributeInputSubjectList } from "../../../../services/dct/DctService"

export type DataResult = {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: DataType[];
  }
}

export type DataType = {
  id: string;
  label: string;
  projectName: string;
  centerName: string;
  subjectCode: string;
  subjectName: string;
  createUser: string;
  tenantCode: string;
  collector: string;
  url: string;
  version: string;
  approveStatus: TaskStatus;
  visitName: string;

  desensitization: 1 | 2;
  desensitizationPass: 1 | 2;

  formId: number;
  formCode: string;
  formName: string;

  completeUserCode: string;
  completeTime: string;
}

const DataCheck = ()=> {

  const [visible, setVisible] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [records, setRecords] = useState<DataType[]>([])

  const [dataSource, setDataSource] = useState<DataType[]>([])
  
  const statRef = useRef<StatisticsActions>(null)
  const conditionRef = useRef<Params>({
    subjectCode: "",
    projectCode: "",
    approveStatus: 'RUNNING', 
    centerName: "",
    createUser: "",
    startTime: "",
    endTime: ""
  })

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    // {
    //   width: 80,
    //   title: '#',
    //   align: 'right',
    //   key: 'linenumber',
    //   render: (_: any, _1: DataType, index: number)=> {
    //     return ((pageNum - 1) * pageSize) + index + 1
    //   }
    // },
    {
      width: 120,
      title: '所属项目',
      ellipsis: true,
      dataIndex: 'projectName',
    },
    {
      width: 120,
      title: '所属中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      title: '访视',
      dataIndex: 'visitName',
    },
    {
      width: 120,
      title: '表单',
      dataIndex: 'formName',
    },
    {
      width: 120,
      title: '采集人',
      dataIndex: 'collector',
    },
    {
      width: 160,
      title: `${sessionStorage.getItem('targetName') || '受试者'}`,
      dataIndex: 'subjectName',
      render: (value: string, record: DataType)=> {
        return `${value}(${record['subjectCode']})`
      }
    },
    {
      width: 160,
      title: '提交时间',
      dataIndex: 'createTime',
      render: (value: string)=> {
        return value ? value.replace('T', ' ') : '-'
      }
    },
    {
      width: 160,
      title: '录入人员',
      dataIndex: 'completeUserCode',
    },
    {
      width: 100,
      title: '任务状态',
      dataIndex: 'approveStatus',
      render: (value: TaskStatus)=> {
        return StatusEnum[value]
      }
    },
    {
      width: 160,
      title: '任务完成时间',
      dataIndex: 'completeTime',
      render: (value: string)=> {
        return value ? value.replace('T', ' ') : '-'
      }
    },
    {
      width: 100,
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (_: any, record: DataType) => (
        <Button onClick={ ()=> { fetchSubjectRecords(record)}} type="primary" size="small">查看详情</Button>
      ),
    },
  ];
  //#endregion

  const fetchSubjectRecords = async(record: DataType)=> {
    const { data } = await getTaskDistributeInputSubjectList(record.subjectCode, record.approveStatus)

    if (data.length > 1) {
      let index = 0
      for(const item of data) {
        if (item.formId === record.formId) {
          data.unshift(data.splice(index, 1).pop() as DataType)
          break;
        }
        index ++
      }
    }
    setRecords(data)
    setVisible(true)
  }

  const fetchData = async (params: Params, page = 1)=> {
    setLoading(true)
    const {code, data}: DataResult = await getTaskDistributeInputList({
      ...params,
      page,
      pageSize: pageSize
    })
    setPageNum(page)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
  }

  const onSearch = (params: Params)=> {
    conditionRef.current = params
    fetchData(params)
  }

  // 当图片审核状态变更后及时刷新列表数据
  const refreshControl = (needReload=false)=> {
    setVisible(false)
    if(needReload) {
      statRef.current?.refresh()
      fetchData(conditionRef.current)
    }
  }

  return (
    <>
    <div className={indexStyl.wrapper}>
      <Statistics/>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 420}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            onChange: (page: number)=> {
              fetchData(conditionRef.current, page)
            },
            total: total,
            current: pageNum,
            pageSize: pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,
            position: ["bottomRight"]}}/>
      </div>
    </div>
    {
      visible && (
        <DetailModal onClose={refreshControl} data={records}/>
      )
    }
    </>
  )
}

export default DataCheck