import React from 'react';
import {connect} from 'react-redux';
import './echartsTwo.less';

import ReactEcharts from '../../../../base/echarts/ReactEcharts';
import {dimValueGetter} from "echarts/lib/component/marker/markerHelper";

import {GetProjectMonitor} from "../../../../../services/project/ProjectService";


let _this;

class EchartsTwo extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {}
    }

    componentDidMount() {
    }

    getOption1Data = () => {
        let {questionNum, dataNum} = _this.props.projectMonitor;
        let noQuestionNum = dataNum - questionNum;
        const res = [
            // {value: noQuestionNum, name: '未被质疑数据'},
            // {value: questionNum, name: '被质疑数据'},
            {value: 0, name: '未被质疑数据'},
            {value: 0, name: '被质疑数据'},
        ]
        return res;
    }

    getOption1 = () => {
        let option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '45%',
                right: '1%',
                orient: 'vertical',
                textStyle: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#484848',
                },
                itemHeight: 10,
                icon: 'circle',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['29%', '42%'],
                    avoidLabelOverlap: false,
                    color: ['#E0E0E0', '#E0E0E0'],
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '16',
                            color: '#484848',
                        }
                    },
                    data: _this.getOption1Data(),
                }
            ]
        };
        return option;
    }

    getOption2Data = () => {
        let {replyNotNum, replyNotCloseNum, replyCloseNum} = _this.props.projectMonitor;
        const res = [
            // {value: replyNotNum, name: '未回复'},
            // {value: replyNotCloseNum, name: '回复未关闭'},
            // {value: replyCloseNum, name: '已关闭'},
            {value: 0, name: '未回复'},
            {value: 0, name: '回复未关闭'},
            {value: 0, name: '已关闭'},
        ]
        return res;
    }

    getOption2 = () => {
        let option = {
            tooltip: {
                trigger: 'item',
            },
            legend: {
                top: '42%',
                right: '1%',
                orient: 'vertical',
                textStyle: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#484848',
                },
                itemHeight: 10,
                icon: 'circle',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['29%', '42%'],
                    avoidLabelOverlap: false,
                    // color: ['#EF8767', '#5ebcbc', '#6c96f7'],
                    color: ['#E0E0E0', '#E0E0E0', '#E0E0E0'],
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '16',
                            color: '#484848',
                        }
                    },
                    data: _this.getOption2Data(),
                }
            ]
        };
        return option;
    }

    getOption3Data = () => {
        let {inputNum, lockNum, signNum, questionReviewNum, freezeNum, examineReviewNum} = _this.props.projectMonitor;
        const res = [
            {value: inputNum, name: '录入'},
            {value: lockNum, name: '锁定'},
            {value: signNum, name: '签名'},
            {value: questionReviewNum, name: '人工核查'},
            {value: freezeNum, name: '冻结'},
            {value: examineReviewNum, name: '数据核查'},
        ]
        return res;
    }

    getOption3 = () => {
        let option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '40%',
                right: '5%',
                orient: 'vertical',
                textStyle: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#484848',
                },
                itemHeight: 10,
                icon: 'circle',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['29%', '42%'],
                    avoidLabelOverlap: false,
                    color: ['#6C96F7', '#5EBCBC', '#EE8F8F', '#FEBB01', '#425BA1', '#E2EEFD'],
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '16',
                            color: '#484848',
                        }
                    },
                    data: _this.getOption3Data(),
                }
            ]
        };
        return option;
    }

    getOption4Data = () => {
        let {
            visitingTotalNum,
            lostVisitTotalNum,
            finishTotalNum,
            filterNotTotalNum,
            filteringTotalNum
        } = _this.props.projectMonitor;
        const res = [
            {value: visitingTotalNum, name: '访视中'},
            {value: lostVisitTotalNum, name: '失访'},
            {value: finishTotalNum, name: '完成'},
            {value: filterNotTotalNum, name: '筛选失败'},
            {value: filteringTotalNum, name: '筛选中'},
        ]
        return res;
    }

    getOption4 = () => {
        let option = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '40%',
                right: '2%',
                orient: 'vertical',
                textStyle: {
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#484848',
                },
                itemHeight: 10,
                icon: 'circle',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['29%', '42%'],
                    avoidLabelOverlap: false,
                    color: ['#5ebcbc', '#fa413b', '#6c96f7', '#ef8767', '#8ce693'],
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                    },
                    label: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '16',
                            color: '#484848',
                        }
                    },
                    data: _this.getOption4Data(),
                }
            ]
        };
        return option;
    }

    render() {
        return (
            _this.props.projectMonitor && _this.props.projectMonitor.questionNum != undefined ?
                <div className={'e_Group2'}>
                    <ul>
                        <li>
                            <ReactEcharts
                                id={'id11'}
                                option={_this.getOption4()}
                                style={{width: 450, height: 450, position: 'absolute', left: '-100px', top: '-59px'}}/>
                            <p className={'e_Group2_title'}>{sessionStorage.getItem('targetName') || '受试者'}状态占比</p>
                        </li>
                        <li>
                            <ReactEcharts
                                id={'id10'}
                                option={_this.getOption3()}
                                style={{width: 450, height: 450, position: 'absolute', left: '-83px', top: '-59px'}}/>
                            <p className={'e_Group2_title'}>数据各状态占比</p>
                        </li>
                        {/* <li>
                            <ReactEcharts
                                id={'id8'}
                                option={_this.getOption1()}
                                style={{width: 450, height: 450, position: 'absolute', left: '-100px', top: '-59px'}}/>
                            <p className={'e_Group2_title'}>数据质疑率</p>
                        </li>
                        <li>
                            <ReactEcharts
                                id={'id9'}
                                option={_this.getOption2()}
                                style={{width: 450, height: 450, position: 'absolute', left: '-83px', top: '-59px'}}/>
                            <p className={'e_Group2_title'}>质疑回复/关闭率</p>
                        </li> */}
                    </ul>
                </div> : ''
        )
    }
}

const mapStateToProps = (state) => ({
    projectMonitor: state.getIn(['project', 'projectMonitor'])
})

const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(EchartsTwo);