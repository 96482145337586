import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Slider from "react-slick";
import { connect } from 'react-redux';
import { actionCreator } from '../../../store';
import './subjects.less';

import { DatePicker, Radio, Row, Col, Modal, Button, message, Space, Spin, Upload, Form, Dropdown, Menu, Empty  } from 'antd';
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from 'moment';
import 'moment/locale/zh-cn';
import ReactEcharts from '../../../../base/echarts/ReactEcharts';
import  { DownSquareOutlined } from  "@ant-design/icons";

import {
    createFromIconfontCN,
    ExclamationCircleOutlined,
    PlaySquareOutlined,
    LeftOutlined,
    WarningFilled,
    WarningOutlined,
    LikeFilled,
    EyeOutlined,
    UploadOutlined,
    DownloadOutlined,
    AuditOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import RemarksModal from "./remarksModal/remarksModal";
import ModalOperate from './modalOperate/modalOperate';
import PatientSelfReported from './patientSelfReported/patientSelfReported';
import SubjectBind from './subjectBind/subjectBind';

import {
    GetSubjectDetail,
    GetFormsById,
    GetProjectSubject,
    ModifyAppointmentDate,
    ModifyVisitDate,
    SubjectPass,
    SubjectJoin,
    GetSubjectVisitsInput,
    subjectContinue,
    ModifyPlanDate,
    GetCaseSts,
    singleAudit,
    downloadCases,
    GetProjectChartStat
} from "../../../../../services/data/DataService";
import { GetAuditSign } from '../../../../../services/audit/auditService'
import { AuditResubmit, submitAudit, GetAuditInfo } from '../../../../../services/dct/DctService'
import modal from '../../../../project/detail/participants/modal/modal';
import CrfForm from '../../../../crf_form/Index';
import OSS from 'ali-oss'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { clearThreeTitle } from '../../../../../util/menuModuleUtil';
import { get, set } from "immutable";
import { BASE_URL } from '../../../../../comm/config';
import $ from 'jquery';
import axios from 'axios';
// import utils from "../../../../util/utils";
import utils from "../../../../../util/utils";
import { makeUploadName, makeUploadOptions } from '../../../../crf_form/components/ocr-autofill/utils';
import TransoutModal from './transout-modal/TransoutModal'
import SignAgreementQR from './signAgreementQR/signAgreementQR';
import SubjectInfo from './subjectInfo/subjectInfo';
import Remarks from './remarks';
import Todo from './todo';
import CreateFormModal from './create-form-modal';
import TenantConfiguration from '../../../../../util/tenantConfiguration';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/c/font_2343612_enl0kq66cmg.js'
    ],
});

const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
    },
};
const formTailLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 8,
        offset: 4,
    },
};

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const echartsStyle = { width: '100%', height: 54, margin: '0 auto' };
const EchartsList = [
    { id: 'viewId', name: '访视进度', color: ['#2D6DFF', '#E9F2FF'], style: echartsStyle },
    { id: 'formId', name: '表单进度', color: ['#F9A228', '#E9F2FF'], style: echartsStyle },
    { id: 'lockId', name: '锁定进度', color: ['#FB5388', '#E9F2FF'], style: echartsStyle },
]

// const disabledDate = (current) => {
//     return current && current < moment().subtract(1, "days");;
// };
class subjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // 当前选择的访视详情
            currentVisit: {},
            // 显示自定义表单弹窗
            visibleCustomizeModal: false,
            downList: null,
            currentSlideIndex: 0,
            // 转移受试者面板
            transoutModalVisible: false,
            getOptionData: [],
            visible: false,
            getSubjectDetailData: {}, // 受试者详情页面数据
            visibleFormData: [], // 访视列表的数据
            settings: {
                dots: false,
                infinite: false,//无限循环
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 4
            }, // Slider 配置参数
            visibleCheckedId: '',// 访视列表选中的id,
            visibleCheckedId2: '',// 访视列表选中的id,
            formModalVisible: false,// 问卷登记弹框是否显示
            stateFlag: this.props.location.stateFlag, // 进入页面的状态 Active => 受试者首页,Init => 入排
            tenantCode: this.props.location.tenantCode,
            dateFlag: true, // 控制日期 显隐
            appointmentDateSelect: '',// 选中 的预约日期
            visitDateSelect: '',// 选中 的实际访问日期
            tasksListType: '',// 选中 任务列表的类型
            joinGroupDisable: false,//通过入组按钮加载状态
            joinPassDisable: false, //拒绝排除按钮加载状态
            visibleOperate: false, // 模态框显隐
            btnType: null, // 顶部操作按钮 1-病史备注, 2-受试者暂停, 3-事件登记
            formRef: React.createRef(),
            resumeVisitId: null, // 受访者id
            crfParams: null, // crf表单初始化
            rejectReasons: [],// 审核失败原因
            showUploadModal: false, //签名回传模态框
            uploadLoading: false, //签名回传加载状态
            fileList: [], //签名回传列表
            noFileTips: false, //无文件提示
            uploadBtnFlag: false,//上传确认按钮点击上传文件按钮
            uploadFlag: true,//上传确认按钮点击
            ossData: null,//oss令牌
            caseFileType: 'pdf',//pdf image
            caseImageUrl: '',//病例签名文件地址
            showCaseImage: false,//显示病例签名modal
            numPages: null,//pdf文件总页数
            rejectReasonList: [], //签名拒绝理由列表
            rejectReasonModal: false,//拒绝理由模态框
            showReSubmitBtn: true, //显示重新审核按钮
            planDateVisible: false, //计划日期同步弹窗是否显示
            selfReportedList: [], // 患者自报列表
            subjectScaleReportState: this.props.location.subjectScaleReportState, // 是否显示患者上报
            slickRef: React.createRef(null), // slick组件
            // 多恩达 隐藏实际访视日期
            hiddenVisitDate: false,
        };
    }

    componentWillMount() {
        /*this.getSubjectDetail()*/
    }

    componentWillUnmount() {
        clearThreeTitle()
    }

    componentDidMount() {
        this.setState({
            hiddenVisitDate: TenantConfiguration.read('hiddenVisitDate')
        })
        this.getSubjectDetail()
        this.getProjectSubject()
    }

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 设置显示隐藏状态和要传递的参数
     */
    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    /**
     * 设置关闭隐藏状态和要传递的参数
     */
    closeModal = () => {
        this.setState({
            visible: false,
        });
    }

    /**
     * 返回首页
     */
    handleCallBack = () => {
        this.props.history.push('/data/workbench')
    }

    /**
     * 拒绝排除
     */
    handleSubjectPass = () => {
        this.setState({
            joinPassDisable: true
        }, () => {
            SubjectPass(this.props.location.state).then(res => {
                if (res.code == 0) {
                    this.handleCallBack()
                }
                this.setState({
                    joinPassDisable: false
                })
            })
        })
    }

    /**
     * 通过入组
     */
    handleSubjectJoin = () => {
        this.setState({
            joinGroupDisable: true
        }, () => {
            SubjectJoin(this.props.location.state).then(res => {
                if (res.code == 0) {
                    this.setState({
                        stateFlag: 'Active',
                        visibleCheckedId2: '',
                    }, () => {
                        this.getSubjectDetail()
                        this.getProjectSubject()
                        setTimeout(() => {
                            this.getProjectSubject()
                        }, 2000)
                    })
                }
                this.setState({
                    joinGroupDisable: false
                })
            })
        });
    }

    /**
     * 受试者进度
     * @param key
     */
    getProjectSubject = () => {
        setTimeout(() => {
            GetProjectChartStat(this.props.location.state).then(res => {
                this.setState({
                    getOptionData: res.data
                })
            })
        }, 0)
    }

    /**
     * 进度数据 echarts
    */
    getOption = (item) => {
        let option = {
            series: [
                {
                    type: 'pie',
                    radius: ['90%', '100%'],
                    hoverAnimation: false,
                    label: {
                        color: 'rgba(0, 0, 0, 0.65)',
                        formatter: (item) => {
                            const data = item.data;
                            if (data.total == 0) {
                                return "0/0";
                            } else {
                                return data.num + '/' + data.total
                            }
                        },
                        position: 'center',
                        fontSize: '14',
                        fontWeight: '500',
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.getOptionData(item.id),
                    color: item.color
                }
            ]
        }
        return option;
    }
    getOptionData = (id) => {
        let { visitFinishNum, visitTotalNum, inputNum, inputTotalNum, lockNum, lockTotalNum } = this.state.getOptionData;

        let res = [];
        if (id === 'viewId') {
            res = [
                { value: visitFinishNum, name: '访视数', num: visitFinishNum, total: visitTotalNum },
                { value: visitTotalNum - visitFinishNum, name: '未访视数', num: visitFinishNum, total: visitTotalNum },
            ]
        } else if (id === 'formId') {
            res = [
                { value: inputNum, name: '录入数', num: inputNum, total: inputTotalNum },
                { value: inputTotalNum - inputNum, name: '未录入数', num: inputNum, total: inputTotalNum },
            ]
        } else {
            res = [
                { value: lockNum, name: '锁定数', num: lockNum, total: lockTotalNum },
                { value: lockTotalNum - lockNum, name: '未锁定数', num: lockNum, total: lockTotalNum },
            ]
        }

        return res;
    }

    /**
     * 受试者详情
     * @param key
     */
    getSubjectDetail = () => {
        let { dateFlag } = this.state;
        GetSubjectDetail(this.props.location.state).then(res => {
            let au = [];
            let checkedIndex = -1;
            if (res.data.visits.length != 0) {
                res.data.visits.map(t => {
                    au.push(t.state)
                    t.checked = false;
                })
                if (au.indexOf('Running') !== -1) {
                    checkedIndex = au.indexOf('Running')
                } else if (au.indexOf('Wait') !== -1) {
                    checkedIndex = au.indexOf('Wait')
                } else if (au.indexOf('Complete') !== -1) {
                    checkedIndex = au.indexOf('Complete')
                }
                if (checkedIndex !== -1) {
                    res.data.visits[checkedIndex].checked = true;
                }
            }
           
            this.props.handleSubjectDetail(res.data)
            res.data.tasks = res.data.tasks.filter((item) => {
                return item.message != ''
            })


            sessionStorage.setItem('projectRoleCode', res.data.roleCode)
            
          
            this.setState({
                getSubjectDetailData: res.data
            }, () => {
                if (res.data.tasks.length == 0) {
                    if (res.data.visits.length != 0) {
                        let id = res.data.visits[checkedIndex].id
                        this.handleClick(id)
                        this.setState({
                            visibleCheckedId: id,
                            dateFlag: true,
                        })
                    }

                } else {
                    if (dateFlag) {
                        if (res.data.visits.length != 0) {
                            let id = this.state.visibleCheckedId2 ? this.state.visibleCheckedId2 : res.data.visits[checkedIndex].id
                            this.handleClick(id)
                            this.setState({
                                visibleCheckedId: id
                            })
                        }
                    } else {
                        this.handleLiClick(this.state.tasksListType)
                    }
                }
            })
        })
    }

    /**
     * 访视节点列表点击
     */
    handleClick = (id,index) => {
        // 定位当前选中
        this.setState({ currentSlideIndex: index });
        this.state.slickRef.current.slickGoTo(index);
        this.setState({
            resumeVisitId: id
        })
        //切换选中的访视节点
        let currentVisit = {}
        let data = [...this.state.getSubjectDetailData.visits];
        data.map((i) => {
            i.checked = false
            if (i.id === id) {
                currentVisit = i
                i.checked = true
            }
        })
        GetFormsById(id).then(res => {
            let b = [];
            b.push(res.data)
            this.setState({
                visibleFormData: res.data.forms.length > 0 ? b : [],
                dateFlag: true,
                currentVisit,
                getSubjectDetailData: {
                    ...this.state.getSubjectDetailData,
                    visits: data
                },
                visibleCheckedId: id,
                visibleCheckedId2: id,
                appointmentDateSelect: '',
                visitDateSelect: '',
                selfReportedList: [res?.data]
            })



            let currentVisitInfo = JSON.parse(JSON.stringify(res.data))
            delete currentVisitInfo.forms
            sessionStorage.setItem('currentVisitInfo', JSON.stringify(currentVisitInfo));
        })
    }

    // 提醒刷新访视信息
    refreshForms = () => {
        let data = [...this.state.getSubjectDetailData.visits];
        let id = this.state.resumeVisitId;
        GetFormsById(id).then(res => {
            let b = [];
            b.push(res.data)
            this.setState({
                visibleFormData: b,//测试
                dateFlag: true,
                getSubjectDetailData: {
                    ...this.state.getSubjectDetailData,
                    visits: data
                },
                visibleCheckedId: id,
                visibleCheckedId2: id,
                appointmentDateSelect: '',
                visitDateSelect: '',
                selfReportedList: [res?.data]
            })

        })
    }


    /**
     * 点击待处理事件
     */
    handleLiClick = (type) => {
        GetSubjectVisitsInput(this.props.location.state, type).then(res => {
            if (res.code === 0) {
                let data = [...this.state.getSubjectDetailData.visits];
                data.map((i) => {
                    i.checked = false
                })

                let partial = {
                    selfReportedList: [],
                    visibleFormData: res.data
                }
                if(type === 'Quest') {
                    partial = {
                        selfReportedList: res.data,
                        visibleFormData: []
                    }
                }
                
                this.setState({
                    dateFlag: (type === 'Quest'),
                    appointmentDateSelect: '',
                    visitDateSelect: '',
                    getSubjectDetailData: {
                        ...this.state.getSubjectDetailData,
                        visits: data
                    },
                    tasksListType: type,
                    ...partial
                })
            }
            else {
                let data = [...this.state.getSubjectDetailData.visits];
                data.map((i) => {
                    i.checked = false
                })
                this.setState({
                    visibleFormData: [],
                    dateFlag: false,
                    appointmentDateSelect: '',
                    visitDateSelect: '',
                    getSubjectDetailData: {
                        ...this.state.getSubjectDetailData,
                        visits: data
                    },
                    tasksListType: type
                })
            }
        })
    }

    /**
     * 受试者 状态
     */
    subjectState = (state, auditState) => {
        if (auditState == 'Pass') {
            return '病例已审核'
        } else if (auditState == 'Fail') {
            return '病例未通过'
        } else if (auditState == 'Wait') {
            return '病例待审核'
        } else {
            switch (state) {
                case 'Active':
                case 'Running':
                    return '访视中'
                    break;
                case 'Finish':
                case 'Complete':
                    return '已完成'
                    break;
                case 'Lose':
                    return '失访'
                    break;
                case 'Stop':
                    return '已停止'
                    break;
                case 'Pause':
                    return '暂停中'
                    break;
                case 'Init':
                    return '待入组'
                    break;
                case 'Pass':
                    // return '已排除'
                    return '入组筛选失败'
                    break;
                case 'Wait':
                    return '计划中'
                    break;
                case 'Void':
                    return '已作废'
                    break;
            }
        }
    }

    /**
     * 受试者 签署状态
     */
    subjectSignState = (singleSignState) => {
        switch (singleSignState) {
            case 0:
                return '待签名'
                break
            case 1:
                return '已签名'
                break
            case 2:
                return '签名已审'
                break
            case 3:
                return '签名不合格'
                break
        }
    }

    /**
     * 受试者 签署状态 颜色
     */
    signStateColor = (singleSignState) => {
        switch (singleSignState) {
            case 0:
                return 'wait'
                break
            case 1:
            case 2:
                return 'finish'
                break
            case 3:
                return 'fail'
                break
        }
    }

    /**
     * 受试者 状态 颜色
     */
    subjectStateColor = (state, auditState) => {
        if (auditState == 'Fail') {
            return 'fail'
        } else if (auditState == 'Wait') {
            return 'wait'
        }
        switch (state) {
            case 'Active':
            case 'Init':
                return ''
                break;
            case 'Finish':
                return 'finish'
                break;
        }
    }

    /**
     * 访视Slider 状态
     */
    visitsState = (state, lockState) => {
        if (lockState == 'Complete') {
            return '已锁定'
        } else {
            switch (state) {
                case 'Active':
                case 'Running':
                    return '访视中'
                    break;
                case 'Finish':
                case 'Complete':
                    return '已完成'
                    break;
                case 'Lose':
                    return '失访'
                    break;
                case 'Stop':
                    return '已停止'
                    break;
                case 'Pause':
                    return '暂停中'
                    break;
                case 'Init':
                    return '待入组'
                    break;
                case 'Pass':
                    // return '已排除'
                    return '入组筛选失败'
                    break;
                case 'Wait':
                    return '计划中'
                    break;
                case 'Void':
                    return '已作废'
                    break;
            }
        }
    }

    /**
     * 访视Slider
     */
    visitsTimeFn = (data) => {
        switch (data.state) {
            case 'Running':
            case 'Complete':
                return data.visitDate
                break;
            case 'Wait':
            case 'Lose':
                return data.appointmentDate ? data.appointmentDate : data.planDate
                break;
        }
    }
  
    /**
     * 访视Slider
     */
    visitsFn = (data) => {
        let p = [];
        const auditState = this.state.getSubjectDetailData.auditState
        data.map((u ,index)=> {
            //数据添加选中标记
            /* console.log(u)*/
            p.push(
                <div key={u.id}>
                    <div
                        className={`s_item ${u.checked ? 'checked' : ''}`}
                        onClick={() => this.handleClick(u.id, index)}
                    >
                        <div className={'checkLine'} style={{ display: `${u.checked ? "block" : "none"}` }}></div>
                        <div className={'state'}>
                            <span className={`${this.visitsStateFn(u.state, u.formPass)}`}>{this.visitsState(u.state, u.formPass)}</span>
                        </div>
                        <h3>{u.name}</h3>
                        {
                            !this.state.hiddenVisitDate && (
                                <p>{this.visitsTimeFn(u)}</p>
                            )
                        }
                    </div>
                </div>
            )
        })
        switch (data.length) {
            case 1:
                for (let i = 0; i < 4; i++) {
                    p.push(
                        <div>
                            <div
                                className={'s_item'}>
                                <h3 style={{ color: '#fff' }}>0</h3>
                                <p></p>
                            </div>
                        </div>
                    )
                }
                break
            case 2:
                for (let i = 0; i < 3; i++) {
                    p.push(
                        <div>
                            <div
                                className={'s_item'}>
                                <h3 style={{ color: '#fff' }}>0</h3>
                                <p></p>
                            </div>
                        </div>
                    )
                }
                break
            case 3:
                for (let i = 0; i < 2; i++) {
                    p.push(
                        <div>
                            <div
                                className={'s_item'}>
                                <h3 style={{ color: '#fff' }}>0</h3>
                                <p></p>
                            </div>
                        </div>
                    )
                }
                break
            case 4:
                for (let i = 0; i < 1; i++) {
                    p.push(
                        <div>
                            <div
                                className={'s_item'}>
                                <h3 style={{ color: '#fff' }}>0</h3>
                                <p></p>
                            </div>
                        </div>
                    )
                }
                break
        }
        return p
    }

    /**
     * 访视Slider下的line 状态 颜色
     */
    visitsLineFn = (data) => {
        //Complete => 完成，Running => 进行中，Wait => 未开始,Lose => 失访
        let progressCon = [];
        data.map((i, v) => {
            let clName = this.visitsStateFn(i.state)
            progressCon.push(<li className={clName} key={v}></li>)
        })
        return progressCon
    }

    /**
     * 访视Slider下的 右上角状态颜色
     */
    visitsStateFn = (state, lockState) => {
        //lockState  Complete => 已锁定
        //Complete => 完成，Running => 进行中，Wait => 未开始,Lose => 失访
        if (lockState == 'Complete') {
            return 'wait'
        } else {
            switch (state) {
                case 'Running':
                    return 'running'
                    break;
                case 'Complete':
                    return 'complete'
                    break;
                case 'Wait':
                    return 'wait'
                    break;
                case 'Lose':
                    return 'lose'
                    break;
            }
        }
    }

    /**
     * 访视列表 icon图标颜色
     */
    visibleFormIcon = (u, uVal) => {
        switch (u) {
            case 'Ocr':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconxiangji')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconOcr_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconOcr_Wait')}</span>
                }
                break;
            case 'Input':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconluru2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconInput_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconInput_Wait')}</span>
                }
                break;
            case 'Check':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconxitonghecha')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconCheck_Complete')}</span>
                } else if (uVal === 'Question') {
                    return <span>{this.formatIcon('iconCheck_Question')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconCheck_Wait')}</span>
                }
                break;
            case 'Question':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconrengonghecha')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconQuestion_Complete')}</span>
                } else if (uVal === 'Question') {
                    return <span>{this.formatIcon('iconQuestion_Question')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconQuestion_Wait')}</span>
                } else if (uVal === 'Answer') {
                    return <span>{this.formatIcon('iconQuestion_Answer')}</span>
                }
                break;
            case 'Examine':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconshujuhecha')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconExamine_Complete')}</span>
                } else if (uVal === 'Question') {
                    return <span>{this.formatIcon('iconExamine_Question')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconExamine_Wait')}</span>
                } else if (uVal === 'Answer') {
                    return <span>{this.formatIcon('iconExamine_Answer')}</span>
                }
                break;
            case 'Freeze':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('icondongjie1')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconFreeze_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconFreeze_Wait')}</span>
                }
                break;
            case 'Autograph':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconqianming1')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconAutograph_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconAutograph_Wait')}</span>
                }
                break;
            case 'Lock':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconsuoding2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Complete':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconsuoding2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Wait':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('iconsuoding2')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Confirm':
                if (uVal === "Running") {
                    return <span>{this.formatIcon('icondairuzu')}</span>
                } else if (uVal === 'Complete') {
                    return <span>{this.formatIcon('iconLock_Complete')}</span>
                } else if (uVal === 'Wait') {
                    return <span>{this.formatIcon('iconLock_Wait')}</span>
                }
                break;
            case 'Answer': 
            case 'Quest': // 随访量表结果待查看
            case 'ImageAnswer': // 图片质疑
                if (uVal === "Question") {
                    return <><WarningFilled /> </>
                } else if (uVal === "Answer") {
                    return <><WarningFilled /> </>
                }
                break;
            default:
                break;
        }
    }

    /**
     * 访视列表 每条数据左侧的line颜色
     */
    vFormListColor = (c) => {
        switch (c) {
            case 'Running':
                return 'running'
                break;
            case 'Complete':
                return 'complete'
                break;
            case 'Wait':
                return 'wait'
                break;
            case 'Question':
                return 'question'
                break;
            case 'Answer':
                return 'running'
                break;
        }
    }

    /**
     * 获取当前日期
     */
    getCurrentDate = () => {
        return new Date().toLocaleDateString().split('/').join('-');
    }

    /**
     * 实际访视日期
     */
    handleVisitDateChange = (a, b) => {
        let param = {
            visitDate: b,
            visitId: this.state.visibleCheckedId
        }
        ModifyVisitDate(param).then(() => {
            this.setState({
                visitDateSelect: b
            })
            this.getSubjectDetail()
        })
    }

    /**
     * 预约访视日期
     */
    handleAppointmentDateChange = (a, b) => {
        let param = {
            appointmentDate: b,
            visitId: this.state.visibleCheckedId
        }
        ModifyAppointmentDate(param).then(() => {
            this.setState({
                appointmentDateSelect: b
            })
            this.getSubjectDetail()
        })
    }

    handleConfirm = (dateVal, changeOtherVisitDate) => {
        let param = {
            planDate: dateVal,
            visitId: this.state.visibleCheckedId,
            subjectCode: this.props.subjectDetailData.code,
            changeOtherVisitDate: changeOtherVisitDate
        }
        ModifyPlanDate(param).then(() => {
            this.getSubjectDetail()
        })
    }
    /**
     * 计划访视日期修改
     */
    handlePlanDateChange = (a, b) => {
        // 获取剩余访视次数
        const index = this.props.subjectDetailData.visits.findIndex(item => item.id === this.state.visibleFormData[0].id);
        const surplusVisitsArr = this.props.subjectDetailData.visits.slice(index + 1);
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: '确认同步计划访视日期',
            content: `此次访视后还有${surplusVisitsArr.length}次访视，是否同步计划访视日期？`,
            okText: '是',
            cancelText: '否',
            onOk: () => {
                // 触发另一个回调函数
                this.handleConfirm(b, 1);
            },
            onCancel: () => {
                this.handleConfirm(b, 0);
            },
        });

        // let param = {
        //     planDate: b,
        //     visitId: this.state.visibleCheckedId
        //      subjectCode: this.props.subjectDetailData.code,
        //      changeOtherVisitDate: 1
        // }
        // ModifyPlanDate(param).then(() => {
        //     this.getSubjectDetail()
        // })
    }

    /**
     * 当点击表单
     */
    onClickVisitForm = (form, parentId) => {
        // 照着实际访视日期写的 防止出错
        const sDetailDtata = this.state.getSubjectDetailData
        const _istime = (sDetailDtata?.state === 'Stop' || sDetailDtata?.state === 'Pause' || this.state.visibleFormData[0]?.state === 'Wait')
        // 南宁经治项目特殊处理
        if (sDetailDtata.projectCode === 'PROJECT10000618' && form.name === '实验室检查' && _istime) {
            message.warn('请先登记实际访视日期!');
            return
        }
        // 服务客户：鞍石项目-伯瑞替尼项目 
        // 需求：访视2需要在基线访视提交并审核通过日期大于等于30天后，才可填写表单
        // const env = process.env.REACT_APP_ENV;
        // if (((env === 'test' || env === 'development') && sDetailDtata.projectCode === 'PROJECT10000635') || (env === 'production' && ['PROJECT10000502', 'PROJECT10000503'].includes(sDetailDtata.projectCode))) {
        //     if (this.state.getSubjectDetailData.visits?.length > 1) {
        //         let startDate;
        //         if (this.state.getSubjectDetailData.basePassTime) {
        //             startDate = moment(this.state.getSubjectDetailData.basePassTime).startOf('day')
        //         }
        //         // 基线与访视2
        //         const formPass = this.state.getSubjectDetailData.visits.find(e => e.name == '基线')?.formPass
        //         const isVisit2 = this.state.visibleFormData[0].name === '访视2'
        //         if (isVisit2 && formPass !== 'Complete') {
        //             return message.warning(`基线审核通过30天之后，可填写该访视2`);
        //         } else if (isVisit2 && formPass == 'Complete' && startDate && new Date().getTime() < new Date(startDate).getTime() + 30 * 24 * 60 * 60 * 1000) {
        //             const date = moment(new Date(startDate).getTime() + 30 * 24 * 60 * 60 * 1000).format("YYYY月MM月DD日")
        //             return message.warning(`${date}之后，可填写该访视2`);
        //         }
        //     }
        // }

        // crf表单
        if (form.type == "CRF") {
            let tenantCode = localStorage.getItem('tenantCode');
            let userName = sessionStorage.getItem('userName')
            let photoEditItem = sessionStorage.getItem('photoEditItem')

            let crfParams = {
                caseCode: "",
                crfCode: form.code,
                crfVersion: form.version,
                eventCode: parentId,
                headers: {},
                projectCode: "",
                recordCode: form.id,
                tenantCode,
                userCode: userName,
                editModel: sDetailDtata.editModel,
                editNew: sDetailDtata.editNew,
            }

            if (photoEditItem) {
                let item = JSON.parse(photoEditItem)
                crfParams['caseCode'] = item.subjectCode
                crfParams['projectCode'] = item.projectCode
            }

            this.setState({
                crfParams
            })
        }

        let filterArr = [];
        if (this.state.visibleFormData && this.state.visibleFormData.length != 0) {
            this.state.visibleFormData.map((e, u) => {
                if (e.id == parentId) {
                    for (let i of this.state.visibleFormData[u].forms) {
                        filterArr.push({
                            title: i.name,
                            id: i.id,
                            formCode: form.code
                        })
                    }
                }
            })
        }
        let forms = {
            activeId: form.id,
            forms: filterArr,
            state: form.inputState
        };
        localStorage.setItem('localStorage_forms', JSON.stringify(forms));
        this.setState({
            formModalVisible: true
        })
    }

    /**
     * 当关闭表单触发
     */
    onCloseModal = () => {
        if (this.state.formRef.current) {
            this.state.formRef.current.closeModal();
        }
    }


    /**
     * 关闭弹框
     */
    closeFormModal = (resetSlick=false) => {
        // bugfix
        if(resetSlick) {
            this.state.slickRef.current.slickGoTo(0)
        }
        this.setState({
            formModalVisible: false,
            visible: false,
        }, () => {
            this.getSubjectDetail()
            this.getProjectSubject()
        });
    }

    /**
    * 打开模态框
    */
    onOperateModal = (value) => {
        this.setState({
            btnType: value,
            visibleOperate: true
        })
    }

    /**
    * 关闭模态框
    */
    closeModalOperate = (v) => {
        this.setState({
            visibleOperate: false,
        })

        // v-1停止受试，v-2暂停受试，v-3废弃受试
        if (v == 3) {
            this.handleCallBack();
        } else if (v == 1 || v == 2) {
            this.getSubjectDetail();
            this.getProjectSubject();
        }
    }

    /**继续访试 */
    onSubjectContinue = () => {
        subjectContinue(this.props.subjectDetailData.code).then(res => {
            if (res && res.code == 0) {
                message.success('继续访试成功！');
                // 首页刷新
                this.getSubjectDetail()
                this.getProjectSubject()
            }
        })
    }


    //审核失败质疑，重新提交
    reSubmit = () => {
        let params = {
            code: this.state.getSubjectDetailData.code
        }
        AuditResubmit(params).then((res) => {
            if (res.code == 0) {
                message.success('审核重新提交成功', 1);
                // setShowReSubmitBtn(false);
                this.setState({
                    showReSubmitBtn: false
                })
                this.getSubjectDetail()
                this.getProjectSubject()
            } else {
                message.fail(res.message || '提交失败', 1);
            }
        })
    }


    //立即提交审核
    handleSubmitAudit = () => {
        let params = {
            code: this.state.getSubjectDetailData.code
        }
        submitAudit(params).then((res) => {
            if (res.code == 0) {
                message.success('提交成功', 1);
                this.getSubjectDetail()
                this.getProjectSubject()
            } else {
                message.fail(res.message || '提交失败', 1);
            }
        })
    }

    /**
     * 提取上传类型
     * @param {*} json 
     * @returns 
     */
    filterPathPre = (json, type) => {
        let newJson = json;
        let data = [];
        data = newJson.pathPre.split(',')
        data.map((i) => {
            if (i.includes(type)) {
                newJson.pathPre = i
            }
        })
        return newJson
    }

    // 签名回传-确定
    onSubmit = async () => {
        if (!this.state.uploadFlag) {
            return false
        }
        const { fileList } = this.state;
        if (fileList.length === 0) {
            this.setState({
                noFileTips: true
            })
            return;
        }

        const pieces = await makeUploadOptions(message.error)

        if(!pieces) {
            message.error('获取上传参数错误')
            return
        }

        const [prefix, options] = pieces

        let client = new OSS(options)

        return new Promise((resolve, reject) => {
            const file = fileList[0]
            //处理FileReader
            const reader = new FileReader()
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
                const pathname = makeUploadName(prefix, file)
                if (!pathname) {
                    message.error("上传缺少必要参数");
                    return;
                }
                // oss上传
                client.put(pathname, file).then(res => {
                    let url = res.url
                    resolve({ url: url })
                    this.handleSign(url)
                }).catch((err) => {
                    message.error('上传失败,请重新上传!', err)
                })
            }
        })
       
    }

    //确定签名回传
    handleSign = (url) => {
        let params = {
            subjectCode: this.state.getSubjectDetailData.code,
            signUrls: url
        }

        singleAudit(params).then((res) => {
            if (res.code === 0) {
                message.info('回传成功')
                this.setState({
                    showUploadModal: false,
                    uploadFlag: true,
                    uploadBtnFlag: false,
                    fileList: []
                })
                this.getSubjectDetail()
            }
        })
    }

    // 签名回传-取消
    handleCancel = () => {
        this.setState({
            showUploadModal: false,
            uploadBtnFlag: false,
            noFileTips: false,
            uploadFlag: true,
            fileList: []
        })
    }

    // 签名查看
    getSign = () => {
        let params = {
            subjectCode: this.state.getSubjectDetailData.code,
        }
        GetAuditSign(params).then((res) => {
            if (res.code == 0) {
                if (res.data) {
                    let fileType = res.data.includes('pdf') ? 'pdf' : 'image'
                    this.setState({
                        caseFileType: fileType,
                        caseImageUrl: res.data,
                        showCaseImage: true
                    })
                } else {
                    message.info('暂无签名文件', 1)
                }
            } else {
                message.info('获取签名文件失败')
            }
        })
    }

    // 签名模态框关闭
    handleCaseCancel = () => {
        this.setState({ showCaseImage: false })
    }

    //pdf加载获取页码
    onDocumentLoadSuccess = (numPages) => {
        this.setState({ numPages: numPages })
    }

    //pdf获取所有页数
    //  getPrintRender = () => {
    //     const {numPages} = this.state
    //     return new Array(numPages || []).fill(0).map((ele, i) => {
    //         return (
    //             <Page key={i + 1} pageNumber={i + 1} />
    //         )
    //     })
    // }

    // 下载病例
    downloadCase = () => {
        const { getSubjectDetailData } = this.state
        const params = {
            subjectCode: getSubjectDetailData.code
        }
        downloadCases({
            subjectCode: params.subjectCode
        }).then((res) => {
            if (res.code === 0 && res.data.length > 0) {
                message.success('病例下载成功')
                const _imgs = res.data || []
                utils.downloadZip(_imgs, '病例单')
            } else if (res.data.length === 0) {
                message.info('暂无病例下载')
            }
        })
    }

    /**
   * 可选的时间区间
   */

    disabledRealDate = (current) => {
        let max;
        let min;
        let sDetailDtata = this.state.getSubjectDetailData;
        let currentVisitId = this.state.visibleFormData[0].id
        let visitLength = sDetailDtata.visits.length
        let Index = sDetailDtata.visits.findIndex((v) => v.id == currentVisitId)
        if (Index == 0) {
            if (visitLength > 1) {
                let data = sDetailDtata.visits[1]
                max = new Date(data.visitDate)
            }
        } else if ((Index + 1) == visitLength) {
            let data = sDetailDtata.visits[Index - 1]
            min = new Date(data.visitDate)
        } else {
            let upData;
            let downData;
            for (let i = Index - 1; i >= 0; i--) {
                const isHave = sDetailDtata.visits[i].visitDate && sDetailDtata.visits[i].state !== 'Wait'
                if (isHave) {
                    upData = sDetailDtata.visits[i]
                    min = new Date(upData.visitDate)
                    break;
                }
            }

            for (let i = Index + 1; i < sDetailDtata.visits.length; i++) {
                const isHave = sDetailDtata.visits[i].visitDate && sDetailDtata.visits[i].state !== 'Wait'
                if (isHave) {
                    downData = sDetailDtata.visits[i]
                    max = new Date(downData.visitDate)
                    break;
                }
            }

        }
        if (max && min) {
            return current >= moment(max).endOf('day') || current <= moment(min).startOf('day')
        }
        else if (max) {
            return current >= moment(max).endOf('day')
        }
        else if (min) {
            return current < moment(min).startOf('day')
        } else {
            return false
        }
    }

    disabledDate = (current) => {
        let max;
        let min;
        let sDetailDtata = this.state.getSubjectDetailData;
        let currentVisitId = this.state.visibleFormData[0].id
        let visitLength = sDetailDtata.visits.length

        let Index = sDetailDtata.visits.findIndex((v) => v.id == currentVisitId)

        let isInclusionCriteria = false
        if (Index == 0) {
            if (visitLength > 1) {
                let data = sDetailDtata.visits[1]
                if (data.state == 'Wait') {
                    max = new Date(data.planDate)
                } else {
                    max = new Date(data.visitDate)
                }
            }
        } else if ((Index + 1) == visitLength) {
            let data = sDetailDtata.visits[Index - 1]
            if (data.type == 'InclusionCriteria') {
                isInclusionCriteria = true
            }
            if (data.state == 'Wait') {
                min = new Date(data.planDate)
            } else {
                min = new Date(data.visitDate)
            }
        } else {
            let upData = sDetailDtata.visits[Index - 1]
            let downData = sDetailDtata.visits[Index + 1]
            if (upData.type == 'InclusionCriteria') {
                isInclusionCriteria = true
            }
            if (upData.state == 'Wait') {
                min = new Date(upData.planDate)
            } else {
                min = new Date(upData.visitDate)
            }

            if (downData.state == 'Wait') {
                max = new Date(downData.planDate)
            } else {
                max = new Date(downData.visitDate)
            }
        }

        if (max && min) {
            return current >= moment(max).endOf('day') || (isInclusionCriteria ? current < moment(min).startOf('day') : current <= moment(min).startOf('day'))
        }
        else if (max) {
            return current >= moment(max).endOf('day')
        }
        else if (min) {
            return (isInclusionCriteria ? current < moment(min).startOf('day') : current <= moment(min).startOf('day'))
        } else {
            return false
        }

    };

    render() {
        const targetName = sessionStorage.getItem('targetName') || '受试者';
        let sDetailDtata = this.state.getSubjectDetailData;
        let { isModalSubject, stopType, btnType, visibleOperate, dateFlag, joinGroupDisable, crfParams, showReSubmitBtn, tenantCode, showCaseImage, caseImageUrl, caseFileType, rejectReasonModal, rejectReasonList, numPages } = this.state;
        sessionStorage.setItem('subjectsState', sDetailDtata.state);
   
        let currentRole = sessionStorage.getItem('roleCode');
        let projectRoleCode = sessionStorage.getItem('projectRoleCode');

        const uploadProps = {
            name: 'file',
            accept: ".pdf,image/jpeg,image/jpg",
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }), () => {
                    if (this.state.fileList.length === 1) {
                        this.setState({
                            uploadBtnFlag: true,
                            noFileTips: false
                        })
                    }
                });
                return false
            },
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
                this.setState({
                    uploadBtnFlag: false,
                    uploadFlag: true
                })
            },
        }
         // 查看更多
        const downList = [];
        if (sDetailDtata?.visits && sDetailDtata?.visits.length != 0) {
            sDetailDtata.visits.forEach((e, index)=>{
                downList.push({
                    key: index,
                    label: (
                      <div onClick={()=>this.handleClick(e.id, index)}>
                        {e.name}
                      </div>)
                })

           
        })}

        const MenuOptions = ({ items }) => (
            <Menu className="menu-options">
              {items.map(item => (
                <Menu.Item
                  key={item.key}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          );
        
        return (
            sDetailDtata && JSON.stringify(sDetailDtata) != '{}' ?
                <div className={'subjects'}>
             
                    <div className={'operateBtns'}>
                        {/* {sDetailDtata.editModel === 1 && sDetailDtata.roleCode !== 'ReadOnly' && (
                            <Button
                                ghost
                                type="primary"
                                icon={<UserSwitchOutlined />}
                                onClick={()=> this.setState({ transoutModalVisible: true})}
                            >{targetName}转移</Button>
                        )} */}
                        {
                            sDetailDtata.auditState === 'Pass' && sDetailDtata.singleSignState !== null && <div className={'btn_left'}>
                                <Button ghost type='primary' onClick={this.downloadCase}><DownloadOutlined style={{ fontSize: '16px' }} />下载病例</Button>
                                <Button ghost type='primary' onClick={() => { this.setState({ showUploadModal: true }) }} disabled={sDetailDtata.singleSignState === 1 || sDetailDtata.singleSignState === 2}><UploadOutlined style={{ fontSize: '16px' }} />签名回传</Button>
                                <Button type='primary' onClick={this.getSign} disabled={sDetailDtata.singleSignState === 0 || sDetailDtata.singleSignState === null}><EyeOutlined style={{ fontSize: '16px' }} />签名查看</Button>
                            </div>
                        }
                        {/* v2.4开发-保留 */}
                        {/* <Button type="primary" ghost onClick={()=>this.onOperateModal(1)}><PlaySquareOutlined />病史备注</Button> */}
                        {sDetailDtata.state == 'Active' && sDetailDtata.auditButtonStatus == '1' && <Button hidden={projectRoleCode == 'ReadOnly' || currentRole === 'EDC_READ'} type="primary" ghost onClick={this.handleSubmitAudit}><PlaySquareOutlined />立即提交审核</Button>}
                        {sDetailDtata.auditState == 'Fail' && showReSubmitBtn && <Button hidden={projectRoleCode == 'ReadOnly' || currentRole === 'EDC_READ'} type="primary" ghost onClick={this.reSubmit}>重新提交审核</Button>}

                        <Button type="primary" ghost
                            onClick={() => { sDetailDtata.state === 'Pause' ? this.onSubjectContinue() : this.onOperateModal(2) }}
                            hidden={ sDetailDtata.editModel === 1 || sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Init' || projectRoleCode == 'ReadOnly' || currentRole === 'EDC_READ'}

                        >
                            <PlaySquareOutlined />{sDetailDtata.state === 'Pause' ? '继续访视' : `${targetName}停止`}
                        </Button>

                        {/* v2.4开发-保留 */}
                        {/* <Button type="primary" ghost onClick={()=>this.onOperateModal(3)}><PlaySquareOutlined />事件登记</Button> */}
                        <Button type="primary" ghost onClick={this.handleCallBack}><LeftOutlined />返回首页</Button>
                    </div>

                    <Row gutter={16} className={'subjects_con'}>
                        <Col className="gutter-row" span={6}>
                            <div className="subjects_left">
                                <div className={'l_top'}>
                                    <div className={'top'}>
                                        <div className={'time'}>
                                            {sDetailDtata.joinDate ? sDetailDtata.joinDate : ''}
                                        </div>
                                        <div className={'info'}>
                                            <div className={'left'}>
                                                {
                                                    this.formatIcon('iconrenyuandanganguanli')
                                                }
                                            </div>
                                            <div className={'right'}>
                                                {/* {
                                                    this.state.tenantCode === 'TENANT-041' ? (
                                                        <>
                                                            <p className='subjectCode'>{sDetailDtata.subjectName || '暂无'}</p>
                                                            <p>{sDetailDtata.code}</p>
                                                        </>
                                                    ) : (
                                                        <p className='subjectCode'>{sDetailDtata.code}</p>
                                                    )
                                                }
                                                <p>{sDetailDtata.projectName}</p> */}
                                                {
                                                    (tenantCode === 'TENANT-0178') ? (
                                                        <>
                                                            <p className='subjectCode'>{sDetailDtata.subjectName || '暂无'}</p>
                                                            <p>{sDetailDtata.code}</p>
                                                        </>
                                                    ) : (
                                                        <p className='subjectCode'>{sDetailDtata.code}</p>
                                                    )
                                                }

                                                <p>{sDetailDtata.projectName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'bottom'}>
                                        <div>
                                            {
                                                this.formatIcon('iconluru')
                                            }
                                            录入：
                                            <span>{sDetailDtata.createUser}</span>
                                        </div>
                                        <div>
                                            {
                                                this.formatIcon('iconjinzhanjiaokuaiqian100mingxiangmu')
                                            }
                                            状态：
                                            {sDetailDtata.singleSignState === null ?
                                                <span className={this.subjectStateColor(sDetailDtata.state, sDetailDtata.auditState)}>
                                                    {this.subjectState(sDetailDtata.state, sDetailDtata.auditState)}
                                                </span> :
                                                <span className={this.signStateColor(sDetailDtata.singleSignState)}>
                                                    {this.subjectSignState(sDetailDtata.singleSignState)}
                                                </span>
                                            }

                                        </div>
                                    </div>
                                    <div className={'groupName'}>
                                        {sDetailDtata.groupName}
                                    </div>
                                </div>
                                <div className={'l_middle'}>
                                    <ul>
                                        {
                                            EchartsList.map(item => {
                                                return (
                                                    <li key={item.id}>
                                                        <ReactEcharts
                                                            id={item.id}
                                                            option={this.getOption(item)}
                                                            style={item.style} />
                                                        <p>{item.name}</p>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                {/* 受试者信息 */}
                                {
                                    this.state.subjectScaleReportState === 1 && sDetailDtata.editModel === 1 && dateFlag && (
                                        <SubjectInfo mobile={sDetailDtata.mobile} subjectName={sDetailDtata.subjectName} subjectCode={this.props.location.state} updateSubjectData={this.getSubjectDetail}/>
                                    )
                                }
                                {/* 受试者信息绑定 */}
                                { 
                                    this.state.subjectScaleReportState === 1 && sDetailDtata.state !== 'Init' && dateFlag &&  (
                                        <SubjectBind signState={sDetailDtata.signatureState} informed={sDetailDtata.informed} editModel={sDetailDtata.editModel} updateSubjectData={this.getSubjectDetail} mobile={sDetailDtata.mobile} subjectName={sDetailDtata.subjectName} subjectCode={this.props.location.state} refresh={this.handleClick} resumeVisitId={this.state.resumeVisitId} state={this.state?.getSubjectDetailData?.state} />
                                    )
                                }
                                {/* 君实协议签署 */}
                                {
                                    (currentRole == 'EDC_CRC' && projectRoleCode !== 'ReadOnly' ) &&  sDetailDtata.editModel === 1 && sDetailDtata.informed !== 2 && (
                                        <SignAgreementQR data={sDetailDtata}/>
                                    )
                                }
                                {/* 待处理事件 */}
                                {
                                    sDetailDtata.code && <Todo onClick={(type) => (this.handleLiClick(type))} renderIcon={(type, state)=> this.visibleFormIcon(type, state)} data={sDetailDtata}/>
                                }
                                {/* 备注 */}
                                {
                                    sDetailDtata.code && <Remarks items={sDetailDtata.remarks}/>
                                }
                            </div>
                        </Col>
                        <Col className="gutter-row" span={18}>
                            <div className="subjects_right">
                                <div className={'r_top'}>
                                    <div className={'r_top_left'}>
                                        <div className={'left'}>
                                            <Slider ref={this.state.slickRef} {...this.state.settings}>
                                                {
                                                    sDetailDtata.visits.length === 0 ? '' : this.visitsFn(sDetailDtata.visits)
                                                }
                                            </Slider>
                                       
                                            <div className={'line'}>
                                                <ul>
                                                    {
                                                        sDetailDtata.visits.length === 0 ? '' : this.visitsLineFn(sDetailDtata.visits)
                                                    }
                                                </ul>
                                            </div>
                                   
                                          
                                        </div>
                                        <div className={'right'}>         
                                            <Dropdown  trigger={['click']} overlay={<MenuOptions items={downList}   placement="bottom" arrow={{ pointAtCenter: true }}/>}  >
                                                <a onClick={e => e.preventDefault()} >
                                    
                                                    <DownSquareOutlined  title='点击查看更多' className='menu_icons'/>
                                                
                                                </a>
                                                
                                            </Dropdown>
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className={'r_bottom'}>

                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={dateFlag ? 16 : 24}>
                                            <div className={`left`}>
                                                {
                                                    this.state.visibleFormData.length === 0 && (
                                                        <div className='item'>
                                                            <Empty description="暂无表单信息" />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    this.state.visibleFormData && this.state.visibleFormData.length > 0 && (
                                                        this.state.visibleFormData.map(c => (
                                                            <div className={'item'} key={c.code}>
                                                                <p>{c.name}</p>
                                                                {
                                                                    c.forms.length === 0 && (
                                                                        <Empty description="暂无表单信息" />
                                                                    )
                                                                }
                                                                <ul>
                                                                    {
                                                                        c.forms && c.forms.length > 0 ? c.forms.map(u => (
                                                                            <li key={u.name} onClick={() => {
                                                                                this.onClickVisitForm(u, c.id)
                                                                            }}>
                                                                                <span
                                                                                    className={`line ${this.vFormListColor(u.inputState)}`}></span>
                                                                                <div className={'title'}>{u.name}</div>
                                                                                <div className={'icons'}>
                                                                                    {this.visibleFormIcon('Ocr', u.ocrState)}
                                                                                    {this.visibleFormIcon('Input', u.inputState)}
                                                                                    {this.visibleFormIcon('Check', u.checkState)}
                                                                                    {this.visibleFormIcon('Question', u.questionState)}
                                                                                    {this.visibleFormIcon('Examine', u.examineState)}
                                                                                    {this.visibleFormIcon('Freeze', u.freezeState)}
                                                                                    {this.visibleFormIcon('Autograph', u.autographState)}
                                                                                    {this.visibleFormIcon('Lock', u.lockState)}
                                                                                </div>
                                                                            </li>
                                                                        )) : ''
                                                                    }
                                                                </ul>
                                                                <div className='r_btns' hidden={projectRoleCode == 'ReadOnly' || currentRole === 'EDC_READ'}>
                                                                    <div className='fun_btns'>
                                                                        {
                                                                            this.state.stateFlag == 'Init' ? <Button className='pass_btn' shape="round" danger disabled={this.state.joinPassDisable} onClick={this.handleSubjectPass}>
                                                                                <span className='icon'></span> 拒绝排除
                                                                            </Button> : null
                                                                        }
                                                                        {
                                                                            this.state.stateFlag == 'Init' ? <Button className='join_btn' type="primary" shape="round" disabled={joinGroupDisable} onClick={this.handleSubjectJoin}>
                                                                                <span className='icon'></span>  通过入组
                                                                            </Button> : null
                                                                        }
    
                                                                        {/*<Button type="dashed" hidden={(sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause')} onClick={this.showModal}>添加备注</Button>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) 
                                                }
                                                {/* 石药多恩达 自定义表单 */}
                                                {
                                                    this.state.currentVisit.customize && (
                                                        <div className='foot-btns'>
                                                            <Button onClick={()=> this.setState({visibleCustomizeModal: true})} type="primary">添加表单</Button>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    this.state.visibleCustomizeModal && (
                                                        <CreateFormModal visitId={this.state.currentVisit.id} onClose={()=> {
                                                            this.setState({visibleCustomizeModal: false})
                                                            this.handleClick(this.state.currentVisit.id, this.state.currentSlideIndex)
                                                        }}/>
                                                    )
                                                }
                                                
                                                {
                                                    this.state.subjectScaleReportState === 1 && dateFlag && this.state.selfReportedList.length > 0 && (
                                                        this.state.selfReportedList.map((t)=> (
                                                            t.scales.length > 0 && (
                                                                <div className='scales'>
                                                                    <h2>{t.name}</h2>
                                                                    <PatientSelfReported refreshForms={this.refreshForms} selfReportedList={t.scales} subjectCode={this.props.location.state} resumeVisitId={t.id} />
                                                                </div>
                                                            ))
                                                        )
                                                    )
                                                }
                                            </div>

                                        </Col>
                                        <Col className="gutter-row" span={8} hidden={!dateFlag}>
                                            <div className={`right`}>
                                                <div className={'title'}>
                                                    <span>访视日程</span>
                                                </div>
                                                <div className={'con'}>
                                                    <div className={'visible'} hidden={this.state.visibleFormData && this.state.visibleFormData.length > 0 && this.state.visibleFormData[0].type == 'InclusionCriteria'}>
                                                        <div>{this.formatIcon('iconjihuaxinxi')}计划访视日期</div>
                                                        <p className={'visit'}>
                                                            {
                                                                this.state.visibleFormData[0]?.state === 'Wait' ?
                                                                    <DatePicker
                                                                        inputReadOnly
                                                                        bordered={false}
                                                                        locale={locale}
                                                                        disabledDate={this.disabledDate}
                                                                        value={
                                                                            this.state.visibleFormData[0].planDate ?
                                                                                moment(this.state.visibleFormData[0].planDate, 'YYYY-MM-DD') : null
                                                                        }
                                                                        format={'YYYY-MM-DD'}
                                                                        suffixIcon={
                                                                            (sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause') ? null : this.formatIcon('iconModify2')
                                                                        }
                                                                        onChange={this.handlePlanDateChange}
                                                                        allowClear={false}
                                                                        disabled={(projectRoleCode == 'ReadOnly' || currentRole === 'EDC_READ' || sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause' || this.state.visibleFormData[0]?.lockState === 'Complete')}
                                                                    /> : <div className={'time'} style={{ color: (sDetailDtata.auditState == 'Pass' || sDetailDtata.auditState == 'Wait' || sDetailDtata.auditState == 'Fail') ? 'rgb(161, 161, 161)' : '' }}>
                                                                        {this.state.visibleFormData && this.state.visibleFormData.length > 0 ? (this.state.visibleFormData[0].planDate ? this.state.visibleFormData[0].planDate : '暂无') : '暂无'}
                                                                    </div>
                                                            }
                                                        </p>
                                                    </div>
                                                    {/* <div className={'appoint'}
                                                        style={{ display: `${this.state.stateFlag == 'Init' ? 'none' : 'block'}` }}>
                                                        <div>{this.formatIcon('iconjihua')}预约访视日期</div>
                                                        <p className={'appoint'}>
                                                            {
                                                                this.state.visibleFormData && this.state.visibleFormData.length != 0 ? this.state.visibleFormData[0].state === 'Wait' ?
                                                                    <DatePicker
                                                                        inputReadOnly
                                                                        bordered={false}
                                                                        locale={locale}
                                                                        value={this.state.appointmentDateSelect == '' ?
                                                                            this.state.visibleFormData[0].appointmentDate ?
                                                                                moment(this.state.visibleFormData[0].appointmentDate, 'YYYY-MM-DD') : null
                                                                            : moment(this.state.appointmentDateSelect, 'YYYY-MM-DD')
                                                                        }
                                                                        format={'YYYY-MM-DD'}
                                                                        suffixIcon={
                                                                            (sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause') ? null : this.formatIcon('iconModify2')
                                                                        }
                                                                        onChange={this.handleAppointmentDateChange}
                                                                        allowClear={false}

                                                                        disabled={(sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause')}
                                                                    /> : this.state.visibleFormData[0].appointmentDate ?
                                                                        <span style={{ paddingLeft: '12px', fontSize: '14px' }}>{this.state.visibleFormData[0].appointmentDate}</span> : <span style={{ paddingLeft: '12px', fontSize: '14px' }}>未预约</span> : <span style={{ paddingLeft: '12px', fontSize: '14px' }}>未预约</span>
                                                            }
                                                        </p>
                                                    </div> */}
                                                    {
                                                        !this.state.hiddenVisitDate && (
                                                            <div className={'visit'} style={{ 'filter': (sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause' || this.state.visibleFormData[0]?.state === 'Wait') ? 'grayscale(1)' : '' }} >
                                                                <div>{this.formatIcon('icondengdai')}实际访视日期</div>
                                                                <p className={'visit'}>
                                                                    {
                                                                        this.state.visibleFormData && this.state.visibleFormData.length != 0 ? (sDetailDtata.state === 'Finish' || sDetailDtata.state === 'Complete') ?
                                                                            this.state.visibleFormData[0].visitDate ? <span className={'time'} style={{ color: (sDetailDtata.auditState == 'Pass' || sDetailDtata.auditState == 'Wait' || sDetailDtata.auditState == 'Fail') ? 'rgb(161, 161, 161)' : '' }}>{this.state.visibleFormData[0].visitDate}</span> : <span style={{ paddingLeft: '12px', fontSize: '14px' }}>'暂无'</span> :
                                                                            (
                                                                                (sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause' || this.state.visibleFormData[0]?.state === 'Wait') ?
                                                                                    <div style={{ color: 'rgb(161, 161, 161)', paddingLeft: '12px', fontSize: '14px' }}>未开始</div>
                                                                                    :
                                                                                    <DatePicker
                                                                                        inputReadOnly
                                                                                        bordered={false}
                                                                                        locale={locale}
                                                                                        value={this.state.visitDateSelect == '' ?
                                                                                            this.state.visibleFormData[0].visitDate ?
                                                                                                moment(this.state.visibleFormData[0].visitDate, 'YYYY-MM-DD') : null
                                                                                            : moment(this.state.visitDateSelect, 'YYYY-MM-DD')
                                                                                        }
                                                                                        format={'YYYY-MM-DD'}
                                                                                        suffixIcon={
                                                                                            (sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause') ? null : this.formatIcon('iconModify2')
                                                                                        }
                                                                                        onChange={this.handleVisitDateChange}
                                                                                        allowClear={false}
                                                                                        disabledDate={this.disabledRealDate}
                                                                                        disabled={(projectRoleCode == 'ReadOnly' || currentRole === 'EDC_READ' || sDetailDtata.state === 'Stop' || sDetailDtata.state === 'Pause' || this.state.visibleFormData[0]?.lockState === 'Complete' || this.state.visibleFormData[0]?.state === 'Wait')}
                                                                                    />) : <div style={{ color: 'rgb(161, 161, 161)', paddingLeft: '12px', fontSize: '14px' }}>暂无</div>
                                                                    }
                                                                </p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* 添加备注 模态框 */}
                    <div className={'modal'}>
                        <RemarksModal
                            open={this.state.visible}
                            closeModal={this.closeModal}
                            visibleId={this.props.location.state}
                            getSubjectDetail={this.getSubjectDetail}
                        />
                        {/* 表单填写弹框 */}
                        <Modal open={this.state.formModalVisible}
                            title={null}
                            onCancel={this.onCloseModal}
                            width={'90%'}
                            centered
                            closable={false}
                            destroyOnClose
                            maskClosable={false}
                            footer={null}>
                            <CrfForm workbench createUserCode={sDetailDtata.createUserCode} autoOcr={sDetailDtata.autoOcr} editNew={sDetailDtata.editNew} editModel={sDetailDtata.editModel} closeModal={(resetSlick)=> this.closeFormModal(resetSlick)} ref={this.state.formRef} textAreaMaxLen={1000} crfParams={crfParams} ></CrfForm>
                        </Modal>
                    </div>

                    {/* 病史备注-1、受试者停止-2、事件登记-3 模态框*/}
                    {
                        (!(sDetailDtata.state === 'Pause')) && visibleOperate &&
                        <ModalOperate
                            open={visibleOperate}
                            closeModal={(v) => this.closeModalOperate(v)}
                            btnType={btnType}
                            subjectCode={this.props.subjectDetailData.code}
                            enableCancel={sDetailDtata.enableCancel}
                        />
                    }

                    {/* 签名回传模态框 */}
                    <Modal
                        title="签名回传"
                        open={this.state.showUploadModal}
                        onOk={this.onSubmit}
                        okText='确定'
                        cancelText='取消'
                        onCancel={this.handleCancel}
                        width='30%'
                        destroyOnClose
                    >
                        <Spin spinning={this.state.uploadLoading}>
                            <Form
                                {...layout}
                                name="nest-messages"
                                requiredMark={true}
                                ref={this.formRef}
                                autoComplete="off"
                            >
                                <p style={{ fontSize: '14px', fontWeight: 'bold' }}>请点击下方按钮上传签名后的文件,请确保文件格式正确。</p>
                                <p style={{ fontSize: '12px', color: '#666', marginLeft: '5px' }}>(*支持文件格式: PDF、JPG、JEPG)</p>
                                <Form.Item
                                    label="上传文件: "
                                    name='file'
                                    rules={[{ required: true, message: '请选择文件上传!' }]}
                                >
                                    <div className={'upFile'} >
                                        <Upload {...uploadProps} >
                                            <Button
                                                icon={<UploadOutlined />}
                                                disabled={this.state.uploadBtnFlag}
                                            >
                                                点击上传
                                            </Button>
                                        </Upload>
                                        <div
                                            className={'tips'}
                                            style={{ display: `${this.state.noFileTips ? 'block' : 'none'}` }}
                                        >
                                            {this.formatIcon('icon-guanbi')}
                                            <span style={{ marginLeft: 4 }}>请先选择文件</span>
                                        </div>
                                    </div>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Modal>
                    {/* 签名查看模态框 */}
                    <Modal
                        open={showCaseImage}
                        animationType={"slide-up"}
                        className={'case_container'}
                        maskClosable
                        onCancel={this.handleCaseCancel}
                        footer={null}
                        width={1000}
                        height={800}
                    >
                        <div className={'case_content'}>
                            <div className={'imgBox'}>
                                {caseFileType == 'pdf' ? <Document file={caseImageUrl} onLoadSuccess={({ numPages }) => { this.onDocumentLoadSuccess(numPages) }}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                            width={930}
                                        />
                                    ))}
                                </Document> : <img src={caseImageUrl} alt="" />}
                            </div>
                        </div>
                    </Modal>
                    
                    {/* 受试者转移 */}
                    <TransoutModal
                    open={this.state.transoutModalVisible}
                    projectId={this.props.location.projectId}
                    subjectCode={this.props.location.state}
                    onTransSuccess={()=> this.props.history.goBack()}
                    onClose={()=> this.setState({transoutModalVisible: false})}/>
                </div> : ''
        )
    }
}

const mapStateToProps = (state) => ({
    subjectDetailData: state.getIn(['data', 'subjectDetail']),
})

const mapDispatchToProps = (dispatch) => ({
    handleSubjectDetail(data) {
        const action = actionCreator.SubjectDetailAction(data);
        dispatch(action)
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(subjects));
