import React from 'react';
import { Form, Input, Button, Select, DatePicker, Tabs, InputNumber, Radio, Modal, message, Row, Col, Checkbox } from 'antd';
import './setting.less';
import {
    ModifyNewProject,
    GetProjectStatusList,
    GetGraftList,
    GetConfigTypeList,
    GetConfigProjectTypeList,
    GetProjectAreaList,
    GetProjectDetailInfo,
    GetTenantUsers
} from "../../../../services/project/ProjectService";
import localStorageUtil from "../../../../comm/localStorage";
import PubSub from "pubsub-js";
import { createFromIconfontCN, ExclamationCircleOutlined } from '@ant-design/icons';
import locale from "antd/es/date-picker/locale/zh_CN";
import moment from 'moment';
import 'moment/locale/zh-cn';
import ModalLabor from './modalLabor/modalLabor';
import ModalSpecialFields from './modal/modal';
import Item from 'antd/lib/list/Item';

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 12,
    },
};
const editStyle = { width: '76%', marginLeft: 6 }
const validateMessages = {
    required: '请输入${label}!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const IconFont = createFromIconfontCN({
    scriptUrl: [
        '//at.alicdn.com/t/font_2283082_862m1tbppl6.js',//icon-shanchu icon-tongji icon-quxiao icon-baocun icon-quyufangwenqushi icon-tianjia1 icon-xiugai
    ],
});

let _this;
const { Option } = Select;

const cycleOptions = [
    { code: 2, message: '周结算' },
    { code: 1, message: '半月结算' },
    { code: 0, message: '月结算' }
]
const groupOptions = [
    { code: 2, message: '非随机分组' },
    { code: 1, message: '随机分组' },
    { code: 0, message: '无' }
]

class Setting extends React.Component {

    constructor(props) {
        super(props);
        _this = this;
        _this.state = {
            // 新版eCRF编辑模式
            editNew: 0,
            autoOcr: 0,
            statusList: [],// 下拉项目状态集合
            planCodeData: [],// 下拉方案集合
            typeData: [],// 下拉项目类别集合
            planVersion: _this.props.planVersion,// 方案版本号
            userSelectList: [],// 参与人员下拉集合
            isEdit: false, // 是否可编辑
            cycleOptions: cycleOptions, // 结算周期
            groupOptions: groupOptions,
            laborAgreementContent: '',
            visibleLabor: false,// 服务协议
            readOnly: true, // 只读
            enableForceSign: null,
            isFrame: null, // 是否抽帧
            isNameSearch: null, // 是否开启姓名搜索
            coverSwitch: null, // 覆盖目标数
            visibleTrigger: false, // 特殊字段触发
            auditType: this.props.auditType ? this.props.auditType.split(',') : [], // 审核类型
            pointFieldDtoList: null,
            orderIndex: null, // 排序
            isSingleSignNew: null, // 单病例签名
            signType: null, // 签名方式
            enableCancel: null, // 是否支持废弃
            subjectScaleReportState: null,//  是否epro
            subjectCodeRule: null, // 受试者编码生成（是否可填写）
            isCustom: null, // 项目类型
        };
    }

    componentDidMount() {
        _this.getProjectStatusList() // 状态下拉集合
        _this.getGraftList() // 方案下拉集合
        _this.getConfigTypeList() // 类型下拉集合
        _this.getTenantUsers(); // 查询租户下的用户下拉集合

        this.setState({
            isSingleSignNew: _this.props.isSingleSignNew
        })
    }

    formRef = React.createRef();

    /**
     * icon 图标
     */
    formatIcon = iconName => {
        return iconName ? (<IconFont type={iconName} />) : undefined;
    }

    /**
     * 状态 下拉集合
     * @param values
     */
    getProjectStatusList = () => {
        GetProjectStatusList().then(res => {
            let statusList = [];
            res.map(x => {
                if (x == "PROCESS") {
                    statusList.push({ code: x, message: "进行中" })
                } else if (x == "STOP") {
                    statusList.push({ code: x, message: "已暂停" })
                } else if (x == "END") {
                    statusList.push({ code: x, message: "已完成" })
                }
            })

            _this.setState({
                statusList
            })
        })
    }

    /**
     * 查询租户下的用户下拉集合
     */
    getTenantUsers = () => {
        GetTenantUsers().then(res => {
            _this.setState({
                userSelectList: res
            })
        })
    }

    /**
     * 方案 下拉集合
     * @param values
     */
    getGraftList = () => {
        GetGraftList().then(res => {
            let drafts = [];
            if (res) {
                res.forEach(x => {
                    drafts.push({ code: x.code, version: x.version, name: x.name });
                })
            }
            _this.setState({
                planCodeData: drafts
            });
        });
    }

    /**
     * 类型 下拉集合
     * @param values
     */
    getConfigTypeList = () => {
        // GetConfigTypeList().then(res => {
        GetConfigProjectTypeList().then(res => {
            let types = [];
            if (res.typeEnumList.length != 0) {
                res.typeEnumList.forEach(x => {
                    types.push({ code: x.code, name: x.name });
                })
            }
            _this.setState({
                typeData: types
            });
        });
    }

    /**
     * 表单提交
     * @param values
     */
    handleFinish = (values) => {
        values.planVersion = _this.state.planVersion;
        values.id = _this.props.projectId

        if (values['endTimeVal'] instanceof Object) {
            values['endTimeVal'] = values['endTimeVal'].format('YYYY-MM-DD');
        }

        values.name = values.name.trim();
        values['laborAgreementContent'] = _this.state.laborAgreementContent != '' ? _this.state.laborAgreementContent : _this.props.laborAgreementContent;
        values['enableForceSign'] = _this.state.enableForceSign != null ? _this.state.enableForceSign : _this.props.enableForceSign;
        values['isFrame'] = _this.state.isFrame != null ? _this.state.isFrame : _this.props.isFrame;
        values['isNameSearch'] = _this.state.isNameSearch != null ? _this.state.isNameSearch : _this.props.isNameSearch;
        values['coverSwitch'] = _this.state.coverSwitch != null ? _this.state.coverSwitch : _this.props.coverSwitch;
        values.auditType = (values.auditType instanceof Array) ? values.auditType.join(',') : values.auditType
        values['pointFieldDtoList'] = _this.state.pointFieldDtoList != null ? _this.state.pointFieldDtoList : _this.props.pointFieldDtoList;
        values['isSingleSignNew'] = _this.state.isSingleSignNew != null ? _this.state.isSingleSignNew : _this.props.isSingleSignNew;
        values['enableCancel'] = _this.state.enableCancel != null ? _this.state.enableCancel : _this.props.enableCancel;
        values['subjectScaleReportState'] = _this.state.subjectScaleReportState != null ? _this.state.subjectScaleReportState : _this.props.subjectScaleReportState;
        values['subjectCodeRule'] = _this.state.subjectCodeRule != null ? _this.state.subjectCodeRule : _this.props.subjectCodeRule;
        values['projectType'] = _this.state.projectType != null ? _this.state.projectType : _this.props.projectType;

        /*values.description = values.description.replace(/\r{0,}\n/g, '<br/>').replace(/\s/g, '&nbsp;')*/

        ModifyNewProject(values).then(res => {
            if (res && res.code == 0) {
                const menus = localStorageUtil.get('menus', true);
                const menuModule = localStorageUtil.get('menuModule', true);
                const param = {
                    isInit: false,
                    menus: menus[menuModule.levelOneIndex].children
                }
                PubSub.publish("onLeftNavChange", param);
                message.success('保存成功！');
                _this.props.onSave('6')
            }
        })
    };

    /**
     * 下拉框 change
     * @param keys
     */
    handleChange = (value, option) => {
        if (option && option.version) {
            _this.setState({
                planVersion: option.version
            })
        }
    };

    /**
     * 编辑
     */
    onEdit = () => {
        _this.setState({
            isEdit: true
        })
    }

    // 修改服务协议
    modifyLabor = (value) => {
        this.setState({
            visibleLabor: true,
            readOnly: value
        })
    }

    closeLaborModal = () => {
        this.setState({
            visibleLabor: false,
        });
    }

    getLaborValue = (v) => {
        this.setState({
            laborAgreementContent: v
        })
    }

    forceSignChange = (e) => {
        this.setState({
            enableForceSign: e.target.value
        });
    }

    // 是否抽帧
    onFrameExtraction = (e) => {
        this.setState({
            isFrame: e.target.value
        })
    }

    // 是否开启工作台姓名搜索功能
    onNameSearchSwitch = (e) => {
        this.setState({
            isNameSearch: e.target.value
        })
    }

    // 是否覆盖目标数
    changeCoverSwitch = (e) => {
        this.setState({
            coverSwitch: e.target.value
        })
    }

    // 单病例签名
    forceSignNewChange = (e) => {
        this.setState({
            isSingleSignNew: e.target.value
        })
    }

    // 是否支持废弃
    changeEnableCancel = (e) => {
        this.setState({
            enableCancel: e.target.value
        })
    }

    // 是否epro
    changeIsEpro = (e) => {
        this.setState({
            subjectScaleReportState: e.target.value
        })
    }

    changeSubjectCodeRule = (e) => {
        this.setState({
            subjectCodeRule: e.target.value
        })
    }
    // 项目类型
    changeProjectType= (e) => {
        this.setState({
            projectType: e.target.value
        })
    }

    changeSubjectCustomType = (e) => {
        this.setState({
            isCustom: e.target.value
        })
    }

    changeEditNew = (e)=> {
        this.setState({
            editNew: e.target.value
        })
    }

    // 改变属性
    changeField =(e, key)=>{
        this.setState({
            [key]: e.target.value
        })
    }

    // 审核类型
    onTriggerRules = (e) => {
        let auditType = this.state.auditType;

        // 选中
        if (e.target.checked) {
            auditType.push(e.target.value);

            if (e.target.value == 'POINT_FIELD') {
                this.setState({
                    visibleTrigger: true
                })
            } else {
                this.setState({
                    visibleTrigger: false
                })
            }
        } else {
            auditType = auditType.filter(item => item != e.target.value)
        }

        this.setState({
            auditType
        }, () => {
            this.formRef.current.setFieldsValue({
                auditType,
            })
        })
    }

    // 关闭指定字段模态框
    closeTriggerModal = (value) => {
        let auditType = this.state.auditType;

        if (!value && auditType && auditType.includes('POINT_FIELD')) {
            auditType = auditType.filter(item => item != 'POINT_FIELD')
        }

        this.setState({
            visibleTrigger: false,
            auditType
        }, () => {
            this.formRef.current.setFieldsValue({
                auditType,
            })
        })
    }

    // 排序
    onOrderIndex = (e) => {
        let value = e.target.value;
        let reg = /^((?!0)\d{1,5}|100000)$/;

        if (value && value.length == 1 && value == '0') {
            value = '0'
        } else if (!value.match(reg)) {
            value = ''
        }

        this.setState({
            orderIndex: value
        }, () => {
            this.formRef.current.setFieldsValue({
                orderIndex: value
            })
        })
    }

    // 获取指定字段数据
    getTriggerValue = (values) => {
        this.setState({
            pointFieldDtoList: values.pointFieldDtoList || null
        })
    }

    render() {
        let { isEdit } = _this.state;
        let { name, icon, statusName, adminCode, planName, groupGoal,
            allowPass, redundancy, subjectCodePrefix, endTimeVal, typeName, description, isSettlement,
            laborAgreementContent, billingCycle, groupType, amount, isAudit, enableForceSign, isFrame, coverSwitch, isRepeatCheck, orderIndex,
            isSingleSignNew, signType, enableCancel,isNameSearch, subjectScaleReportState, subjectCodeRule, isCustom, editNew,autoOcr }
            = _this.props;

        let adminName = '';
        _this.state.userSelectList.map(v => {
            if (v.userCode === adminCode) {
                adminName = v.userName
            }
        })

        const targetName = sessionStorage.getItem('targetName') || '受试者';
        const editContent = [
            { name: '项目名称：', text: name },
            { name: '项目Logo：', text: icon },
            { name: '项目状态：', text: statusName },
            { name: '项目管理员：', text: adminName },
            { name: '项目方案：', text: planName },
            { name: '入组目标：', text: groupGoal },
            { name: '是否允许超过目标数：', text: allowPass == 1 ? '不容许' : allowPass == 2 ? '容许' : '--' },
            { name: '是否开启结算申请：', text: isSettlement == 0 ? '不开启' : isSettlement == 1 ? '开启' : '--' },
            { name: '项目冗余度：', text: `${redundancy} %` },
            { name: `${targetName}编码前缀：`, text: subjectCodePrefix },
            { name: '截止日期：', text: endTimeVal },
            { name: '项目类别：', text: typeName },
            { name: '是否开启病例审核：', text: isAudit == 0 ? '不开启' : isAudit == 1 ? '开启' : '--' },
            { name: '是否开启重复病例校验：', text: isRepeatCheck == 0 ? '不开启' : isRepeatCheck == 1 ? '开启' : '--' },
            { name: '项目说明：', text: description },
            { name: '单病例金额：', text: amount },
            { name: '结算周期：', text: billingCycle == 0 ? '月结算' : billingCycle == 1 ? '半月结算' : '周结算' },
            { name: '分组设置：', text: groupType == 0 ? '无' : groupType == 1 ? '随机分组' : '非随机分组' },
            { name: '服务协议：', text: '服务协议模板', url: laborAgreementContent },
            { name: '强制签署协议：', text: enableForceSign === true ? '开启' : enableForceSign === false ? '不开启' : '--' },
            { name: '是否抽帧：', text: isFrame == 1 ? '是' : isFrame == 0 ? '否' : '--' },
            { name: '是否开启工作台姓名搜索：', text: isNameSearch == 1 ? '开启' : isNameSearch == 0 ? '不开启' : '--' },
            { name: '是否覆盖目标数：', text: coverSwitch == 1 ? '是' : coverSwitch == 0 ? '否' : '--' },
            { name: '排序设置：', text: orderIndex },
            { name: '单病例签名：', text: isSingleSignNew == 1 ? '开启' : isSingleSignNew == 0 ? '不开启' : '--' },
            { name: '签名方式：', text: signType == 0 ? '纸质签名' : '' },
            { name: '是否支持废弃：', text: enableCancel == 0 ? '支持' : enableCancel == 1 ? '不支持' : '--' },
            { name: '是否epro：', text: subjectScaleReportState == 0 ? '否' : subjectScaleReportState == 1 ? '是' : '--' },
            { name: `${targetName}编码生成（是否可填写）：`, text: subjectCodeRule == 0 ? '可填写' : subjectCodeRule == 1 ? '不可填写自动生成' : '--' },
            { name: '项目类型：', text: ['--', 'EDC项目', '患管项目'][isCustom] ?? '--' },
            { name: '启用新版编辑模式：', text: ['否', '是'][editNew] || '--' },
            { name: '是否开启ocr识别', text: ['否', '是'][autoOcr] || '--' },
        ]

        const auditTypeList = [
            { label: '病例完成', value: 'CASE_FINISH' },
            { label: '访视完成', value: 'VISIT_FINISH' },
            { label: '指定字段', value: 'POINT_FIELD' },
        ]

        return (
            <div className={'setting'}>
                <div className={'setting_con'}>

                    <div hidden={isEdit} className={'pTop'}>
                        {
                            editContent.map((item, key) => (
                                <Row key={key} className={'rowBottom'} hidden={(item.name == '签名方式：' && isSingleSignNew == 0)}>
                                    <Col span={7} className={'leftLable'}>
                                        {item.name}
                                    </Col>
                                    <Col span={12} className={'rightText'}>
                                        {
                                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                                            item.name == '服务协议：' ?
                                                <>
                                                    {item.text}
                                                    <Button type="link" onClick={() => this.modifyLabor(true)}>查看</Button>
                                                </> : item.text
                                        }
                                    </Col>
                                </Row>
                            ))
                        }
                        <Row className={'rowBottom'}>
                            <Col span={7} offset={15}>
                                <Button
                                    hidden={isEdit}
                                    type="primary"
                                    style={{ height: 36 }}
                                    onClick={_this.onEdit}
                                >编 辑</Button>
                            </Col>
                        </Row>
                    </div>

                    <Form
                        hidden={!isEdit}
                        {...layout}
                        name="nest-messages"
                        onFinish={_this.handleFinish}
                        validateMessages={validateMessages}
                        requiredMark={false}
                        ref={this.formRef}
                    >

                        <Form.Item
                            label="项目名称"
                            name='name'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入项目名称!',
                                },
                                {
                                    whitespace: true,
                                    message: '不能输入空格',
                                }
                            ]}
                            initialValue={_this.props.name}
                        >
                            <Input
                                style={editStyle}
                                placeholder="请输入项目名称"
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目Logo"
                            name='icon'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入参与人员账号!',
                                },
                            ]}
                            initialValue={_this.props.icon}
                        >
                            <Input
                                style={editStyle}
                                placeholder="请输入参与人员账号"
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目状态"
                            name='status'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择项目状态!',
                                },
                            ]}
                            initialValue={_this.props.status}
                        >
                            <Select
                                placeholder='请选择项目状态'
                                style={editStyle}
                                onChange={_this.handleChange}
                                disabled={_this.props.projectMnger ? false : true}
                                allowClear
                            >
                                {
                                    _this.state.statusList.map(v => (
                                        <Option value={v.code}
                                            key={v.code}>{v.message}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="项目管理员"
                            name='adminCode'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择项目管理员!',
                                },
                            ]}
                            initialValue={_this.props.adminCode}
                        >
                            <Select
                                placeholder='请选择项目管理员'
                                style={editStyle}
                                onChange={_this.handleChange}
                                disabled={_this.props.projectMnger ? false : true}
                                allowClear
                            >
                                {
                                    _this.state.userSelectList.map(v => (
                                        <Option value={v.userCode}
                                            key={v.userCode}>{v.userName}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="项目方案"
                            name='planCode'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择项目方案!',
                                },
                            ]}
                            initialValue={_this.props.planCode}
                        >
                            <Select
                                onChange={_this.handleChange}
                                placeholder='请选择项目方案'
                                style={editStyle}
                                disabled={_this.props.projectMnger ? false : true}
                                allowClear
                            >
                                {
                                    _this.state.planCodeData.map(v => (
                                        <Option value={v.code}
                                            version={v.version}
                                            code={v.code}
                                            key={v.code}>{v.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="入组目标"
                            name='groupGoal'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入入组目标!',
                                },
                                {
                                    type: 'number',
                                    max: 999999999,
                                    message: '请输入小于10位的正整数!',
                                }
                            ]}
                            initialValue={_this.props.groupGoal}
                        >
                            <InputNumber
                                min={0}
                                max={999999999}
                                style={editStyle}
                                parser={value => value.replace(/^(0+)|[^\d]+/g, '')}
                                placeholder="请输入入组目标"
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>

                        <Form.Item
                            label="是否允许超过目标数"
                            name='allowPass'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择容许值'
                                }
                            ]}
                            initialValue={_this.props.allowPass}
                        >
                            <Radio.Group style={editStyle}>
                                <Radio value={1}>不容许</Radio>
                                <Radio value={2}>容许</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="是否开启结算申请"
                            name='isSettlement'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择是否开启结算申请'
                                }
                            ]}
                            initialValue={_this.props.isSettlement}
                        >
                            <Radio.Group style={editStyle}>
                                <Radio value={0}>不开启</Radio>
                                <Radio value={1}>开启</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="项目冗余度"
                            name='redundancy'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入项目冗余度'
                                },
                                {
                                    pattern: /^[\d.%]+$/,
                                    message: '请输入正确的数值'
                                }
                            ]}
                            initialValue={_this.props.redundancy}
                        >
                            <InputNumber
                                min={0}
                                formatter={value => `${value}%`}
                                parser={value => value.replace(/^(-1+)|[^\d]+/g, '')}
                                placeholder="30%"
                                style={editStyle}
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>


                        <Form.Item
                            label={`${targetName}编码前缀`}
                            name='subjectCodePrefix'
                            initialValue={_this.props.subjectCodePrefix}
                        >
                            <Input
                                placeholder={`请输入${targetName}编码前缀`}
                                style={editStyle}
                                disabled
                            />
                        </Form.Item>

                        <Form.Item
                            label="截止日期"
                            name="endTimeVal"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: '请选择日期!',
                                },
                            ]}
                            initialValue={moment(_this.props.endTimeVal, 'YYYY-MM-DD')}
                        >
                            <DatePicker
                                locale={locale}
                                placeholder='请选择日期'
                                style={editStyle}
                                inputReadOnly
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>

                        <Form.Item
                            label="项目类别"
                            name='type'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择项目类别!',
                                },
                            ]}
                            initialValue={_this.props.type}
                        >
                            <Select
                                style={editStyle}
                                onChange={_this.handleChange}
                                placeholder='请选择项目类别'
                                disabled={_this.props.projectMnger ? false : true}
                                allowClear
                            >
                                {
                                    _this.state.typeData.map(v => (
                                        <Option value={v.code}
                                            code={v.code}
                                            key={v.code}>{v.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="是否开启病例审核"
                            name='isAudit'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择是否开启病例审核"'
                                }
                            ]}
                            initialValue={_this.props.isAudit}
                        >
                            <Radio.Group style={editStyle}>
                                <Radio value={0}>不开启</Radio>
                                <Radio value={1}>开启</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="是否开启重复病例校验"
                            name='isRepeatCheck'
                            initialValue={_this.props.isRepeatCheck}
                        >
                            <Radio.Group style={editStyle}>
                                <Radio value={0}>不开启</Radio>
                                <Radio value={1}>开启</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="项目说明"
                            name='description'
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            initialValue={_this.props.description}
                        >
                            <Input.TextArea
                                placeholder="多行输入"
                                style={{ width: '76%', marginLeft: 6, height: 138, whiteSpace: 'pre' }}
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>

                        <Form.Item
                            label="单病例金额"
                            name='amount'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入单病例金额!',
                                },
                            ]}
                            initialValue={_this.props.amount}
                        >
                            <Input
                                style={editStyle}
                                placeholder="请输入单病例金额"
                                disabled={_this.props.projectMnger ? false : true}
                            />
                        </Form.Item>

                        <Form.Item
                            label="结算周期"
                            name='billingCycle'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择结算周期!',
                                },
                            ]}
                            initialValue={_this.props.billingCycle}
                        >
                            <Select
                                placeholder='请选择结算周期'
                                style={editStyle}
                                onChange={_this.handleChange}
                                disabled={_this.props.projectMnger ? false : true}
                                allowClear
                            >
                                {
                                    _this.state.cycleOptions.map(v => (
                                        <Option value={v.code}
                                            key={v.code}>{v.message}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="分组设置"
                            name='groupType'
                            rules={[
                                {
                                    required: true,
                                    message: '请选择分组设置!',
                                },
                            ]}
                            initialValue={_this.props.groupType}
                        >
                            <Select
                                placeholder='请选择分组设置'
                                style={editStyle}
                                onChange={_this.handleChange}
                                disabled={_this.props.projectMnger ? false : true}
                                allowClear
                            >
                                {
                                    _this.state.groupOptions.map(v => (
                                        <Option value={v.code}
                                            key={v.code}>{v.message}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="服务协议"
                            name='laborAgreementContent'
                            // rules={[
                            //     {
                            //         required: true,
                            //     },
                            // ]}
                            initialValue={'服务协议模板'}
                        >
                            <>
                                {'服务协议模板'}
                                <Button type="link" onClick={() => this.modifyLabor(false)}>修改</Button>
                            </>
                        </Form.Item>

                        <Form.Item
                            label="强制签署协议"
                            name='enableForceSign'
                            initialValue={_this.props.enableForceSign}
                        >
                            <>
                                <Radio.Group onChange={this.forceSignChange} value={this.state.enableForceSign !== null ? this.state.enableForceSign : this.props.enableForceSign}>
                                    <Radio value={true}>开启</Radio>
                                    <Radio value={false}>不开启</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>

                        <Form.Item
                            label="是否抽帧"
                            name='isFrame'
                            initialValue={_this.props.isFrame}
                        >
                            <>
                                <Radio.Group onChange={this.onFrameExtraction} value={this.state.isFrame !== null ? this.state.isFrame : this.props.isFrame}>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>

                        <Form.Item
                            label="是否开启工作台姓名搜索"
                            name='isNameSearch'
                            initialValue={_this.props.isNameSearch}
                        >
                            <>
                                <Radio.Group onChange={this.onNameSearchSwitch} value={this.state.isNameSearch !== null ? this.state.isNameSearch : this.props.isNameSearch}>
                                    <Radio value={1}>开启</Radio>
                                    <Radio value={0}>不开启</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>

                        <Form.Item
                            label="是否覆盖目标数"
                            name='coverSwitch'
                            initialValue={_this.props.coverSwitch}
                        >
                            <>
                                <Radio.Group onChange={this.changeCoverSwitch} value={this.state.coverSwitch !== null ? this.state.coverSwitch : this.props.coverSwitch}>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>

                        <Form.Item
                            name="auditType"
                            label="审核类型"
                            initialValue={this.state.auditType}>
                            {
                                auditTypeList.map(item =>
                                    <Checkbox
                                        key={item.value}
                                        checked={this.state.auditType.indexOf(item.value) !== -1 ? true : false}
                                        value={item.value}
                                        onChange={this.onTriggerRules}>{item.label}
                                    </Checkbox>)
                            }
                        </Form.Item>

                        <Form.Item
                            label="排序设置"
                            name='orderIndex'
                            initialValue={_this.props.orderIndex}
                        >
                            <Input
                                allowClear
                                style={editStyle}
                                placeholder="请输入序号"
                                onChange={this.onOrderIndex}
                            />
                        </Form.Item>

                        <Form.Item
                            label="单病例签名"
                            name='enableForceSign8'
                            initialValue={_this.props.isSingleSignNew}
                        >
                            <>
                                <Radio.Group onChange={this.forceSignNewChange} value={this.state.isSingleSignNew !== null ? this.state.isSingleSignNew : this.props.isSingleSignNew}>
                                    <Radio value={1}>开启</Radio>
                                    <Radio value={0}>不开启</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>
                        <Form.Item
                            label="签名方式"
                            name='signType'
                            initialValue={_this.props.signType}
                            hidden={_this.state.isSingleSignNew == 0}
                        >
                            <>
                                <Radio.Group value={this.state.signType !== null ? this.state.signType : this.props.signType}>
                                    <Radio value={0}>纸质签名</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>
                        <Form.Item
                            label="是否支持废弃"
                            name='enableForceSign8'
                            initialValue={_this.props.enableCancel}
                        >
                            <>
                                <Radio.Group onChange={this.changeEnableCancel} value={this.state.enableCancel !== null ? this.state.enableCancel : this.props.enableCancel}>
                                    <Radio value={0}>是</Radio>
                                    <Radio value={1}>否</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>
                        <Form.Item
                            label="是否epro"
                            name='subjectScaleReportState'
                            initialValue={_this.props.subjectScaleReportState}
                        >
                            <>
                                <Radio.Group onChange={this.changeIsEpro} value={this.state.subjectScaleReportState !== null ? this.state.subjectScaleReportState : this.props.subjectScaleReportState}>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>
                        <Form.Item
                            label={`${targetName}编码生成（是否可填写）`}
                            name='subjectCodeRule'
                            initialValue={_this.props.subjectCodeRule}
                        >
                            <>
                                <Radio.Group onChange={this.changeSubjectCodeRule} value={this.state.subjectCodeRule !== null ? this.state.subjectCodeRule : this.props.subjectCodeRule}>
                                    <Radio value={0}>可填写</Radio>
                                    <Radio value={1}>不可填写自动生成</Radio>
                                </Radio.Group>
                            </>
                        </Form.Item>
                        <Form.Item
                            label="项目类型："
                            name='isCustom'
                            initialValue={_this.props.isCustom}
                        >
                            <Radio.Group onChange={this.changeSubjectCustomType} value={this.state.isCustom}>
                                <Radio value={1}>EDC项目</Radio>
                                <Radio value={2}>患管项目</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label='启用新版编辑模式'
                            name='editNew'
                            initialValue={_this.props.editNew}
                        >
                            <Radio.Group onChange={this.changeEditNew} value={this.state.editNew}>
                                <Radio value={1}>是</Radio>
                                <Radio value={0}>否</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label='开启ocr识别'
                            name='autoOcr'
                            initialValue={_this.props.autoOcr}
                        >
                            <Radio.Group onChange={(e)=>{this.changeField(e, 'autoOcr')}} value={this.state.autoOcr}>
                                <Radio value={1}>是</Radio>
                                <Radio value={0}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{ span: 7, offset: 15 }}
                            style={{
                                display: (_this.props.projectMnger ? '' : 'none'),
                                marginBottom: 0
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ height: 36 }}
                            >
                                {/*{_this.formatIcon('icon-baocun')}*/}
                                保 存
                            </Button>
                        </Form.Item>

                    </Form>
                </div>

                <ModalLabor
                    visible={_this.state.visibleLabor}
                    closeModal={_this.closeLaborModal}
                    content={_this.props.laborAgreementContent}
                    getValue={(v) => this.getLaborValue(v)}
                    readOnly={_this.state.readOnly}
                />

                {/* 审核指定字段 */}
                <ModalSpecialFields
                    visible={_this.state.visibleTrigger}
                    closeModal={(v) => _this.closeTriggerModal(v)}
                    getValue={(v) => this.getTriggerValue(v)}
                />
            </div>
        );
    }
}

export default Setting