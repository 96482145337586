import React from 'react'

import modStyl from './index.module.scss'

interface Props {
  min?: number
  max?: number
  suffix?: string
  value: number
  onChange: (value: number) => void
}

const Slider: React.FC<Props> = ({ onChange, value, min = 5, max = 100, suffix = '' }) => {
  return (
    <div className={modStyl.wrapper}>
      <div className={modStyl.preview}>{value}</div>
      <div data-suffix={suffix} data-min={0} data-max={max} className={modStyl.slider}>
        <span>{min}{suffix}</span>
        <input onChange={(e)=> onChange?.(Number(e.target.value))} step={5} value={value} min={min} max={max} type="range"/>
        <span>{max}{suffix}</span>
      </div>
    </div>
  )
}

export default Slider