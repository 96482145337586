import { Button, Empty, message as messager, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { createJoinProjectTask, getJoinProjectList, JoinProjectListResult } from "../../../../../services/data/data";

type Props = {
  projectCode: string
}

const GroupReportTable: React.FC<Props> = ({ projectCode })=> {

  const [pageSize] = useState(10)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<JoinProjectListResult[]>([])

  //#region 表格列
  const columns: ColumnsType<JoinProjectListResult> = [
    {
      title: '任务名称',
      dataIndex: 'taskName',
    },
    {
      width: 100,
      title: '任务状态',
      dataIndex: 'taskState',
      render: (value)=> ['进行中', '已完成'][value] ?? '--'
    },
    {
      width: 180,
      title: '创建时间',
      dataIndex: 'createdTime',
    },
    {
      width: 180,
      title: '完成时间',
      dataIndex: 'finishTime',
    },
    {
      width: 80,
      title: '操作',
      dataIndex: 'taskState',
      render(value, record) {
        return (
          <Button disabled={record.taskState === 0} href={record.url} target="_blank" type="link" size="small">下载</Button>
        )
      }
    }
  ];
  //#endregion

  const fetchPaginate = async (page = 1)=> {
    setLoading(true)
    const { data } = await getJoinProjectList(projectCode, page, pageSize)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
    setPageNum(page)
  }


  const confirmSubmit = ()=> {
    Modal.confirm({
      title: '操作确认',
      content: '确定添创建入组数据报表任务？',
      okText: '确认',
      cancelText: '取消',
      onOk: async ()=> {
        const { code, message } = await createJoinProjectTask(projectCode)
        if (code === 0) {
          fetchPaginate()
          messager.success('操作成功')
        } else {
          messager.error(message)
        }
      }
    })
  }


  useEffect(()=> {
    fetchPaginate()
  }, [])

  return (
    <>
      <div style={{padding: '12px 0'}}>
        <Button onClick={confirmSubmit} size="small" type="primary">新增</Button>
      </div>
      <Table
        loading={loading}
        size="middle"
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        locale={{emptyText: <Empty description="暂无数据"/>}}
        pagination={{
          onChange: (page: number)=> fetchPaginate(page),
          showTotal: total=> `共${total}条，每页${pageSize}条`,
          style: {marginBottom: 0},
          total: total,
          current: pageNum,
          pageSize: pageSize,
          hideOnSinglePage: true,
          showSizeChanger: false,
          showQuickJumper: false,
          position: ["bottomRight"]}}/>
    </>
  )
}

export default GroupReportTable
