import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FabricImage, Canvas, Group, FabricObject, PatternBrush, Point } from 'fabric';

import Toolbar from "./toolbar";
import ContextMenu from "./context-menu";
import HelpModal from "./help-modal";
import Slider from "./slider";
import Spin from "./spin";

import modStyl from './index.module.scss'
import { ActionEnum, CommandEnum, dataURLToFile, defaultBrushValue, defaultColorValue, defaultZoomValue, loadRemoteImage } from "./utils";
import { mouseDownHandle, mouseMoveHandle, mouseUpHandle } from "./rect-utils";

export interface MosaicEditorRef {
  takeFiles: ()=> Promise<Map<string, File>>
  clearSnapshot: (onlyInitBrush?: boolean)=> void
}

interface Props {
  url: string
  preview?: boolean
  onChange?: ()=> void
}

let canvas: Canvas;

const ll = (tagName: string, ...rest: unknown[])=> {
  console.log(`?canvas:${tagName}`, ...rest)
}


export function unbind(canvas: Canvas) {
  canvas.selection = true
  // 从矩形打码切到其它功能时移除矩形绘制事件
  canvas.off({
    'mouse:down': mouseDownHandle,
    'mouse:move': mouseMoveHandle,
    'mouse:up': mouseUpHandle,
  })
}

const lockObject = (o: FabricObject)=> {
  o.selectable = false
  o.lockMovementX = true
  o.lockMovementY = true
  o.hoverCursor = 'crosshair'
  o.moveCursor = 'crosshair'
}

const unlockObject = (o: FabricObject)=> {
  o.selectable = true
  o.lockMovementX = false
  o.lockMovementY = false
  o.hoverCursor = 'default'
  o.moveCursor = 'default'
}

const makeGroup = (hasControls=true, objects: FabricObject[] = [])=> {
  return new Group(objects, {
    cornerColor: defaultColorValue,
    borderColor: defaultColorValue,
    transparentCorners: false,
    objectCaching: false,
    hasControls,
  })
}

// 编组处理
const makeGroupOfClone = async()=> {
  const group = makeGroup(true, [])
  // group.cornerColor = 'red'
  for(const o of canvas.getObjects()) {
    if (o.type === 'group') {
      return o as Group
    }
    group.add(o)
  }
  return group
}

// 创建快照
const makeSnapshot = async(url: string)=> {
  const state = await makeGroupOfClone()
  if (state.getObjects().length > 0) {
    const zoom = canvas.getZoom()
    window.__mosaic.set(url, {
      state,
      zoom,
    })
  }
}

/**
 * `ImageEditor` 组件是一个基于 Fabric.js 的图片编辑器，提供了图片加载、缩放、画笔绘制、对象移动、清空画布等功能。
 * 
 * @param {Object} props - 组件属性
 * @param {boolean} [props.debug=false] - 是否开启调试模式
 * @param {boolean} [props.preview=false] - 是否为预览模式
 * @param {string} props.url - 要编辑的图片 URL
 * @param {React.RefObject<MosaicEditorRef>} ref - 反向引用，用于父组件访问编辑器实例的方法
 * 
 * @returns {JSX.Element} 返回编辑器的 JSX 元素
 * 
 * @example
 * // 使用示例
 * <ImageEditor url="path/to/image.jpg" />
 */
const ImageEditor = forwardRef<MosaicEditorRef, Props>(({ preview = false, url, onChange }, ref) => {

  // 缩放比例
  const [zoomValue, setZoomValue] = useState(defaultZoomValue)
  // 笔刷粗细
  const [brushValue, setBrushValue] = useState(defaultBrushValue)

  // 右键时选择的对象
  const [currentPoint, setCurrentPoint] = useState<Point>()
  const [currentTarget, setCurrentTarget] = useState<FabricObject>()

  // 工具栏当前操作
  const [action, setAction] = useState<ActionEnum>(ActionEnum.Move);

  // 显示帮助
  const [visibleHelp, setVisibleHelp] = useState(false)

  // 画布
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // 加载状态
  const [loading, setLoading] = useState(false)

  const makePatternBrash = ()=> {
    const source = new Image()
    source.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPLkyX8GPEBYWBifNAPjqAHDIgx27dqFNx3IysriTwejBjAwDv0wAADzZztR4yZAZQAAAABJRU5ErkJggg=='
    const mosaicPatternBrash = new PatternBrush(canvas);
    mosaicPatternBrash.source = source
    mosaicPatternBrash.width = brushValue
    mosaicPatternBrash.strokeLineCap = 'square'
    return mosaicPatternBrash
  }

  const watchUrlChange = async ()=> {
    let zoom = 0.5
    let group: Group

    // 新图片加载
    if (!window.__mosaic.has(url)) {
      // 加载远程图片
      const im = await loadRemoteImage(url, setLoading)
      // 创建fabricImage
      const image = new FabricImage(im, {
        width: im.width,
        height: im.height,
        hasControls: false,
        // 默认true 以中心点旋转
        // centeredRotation: true,
        // 默认true 以中心点缩放
        // centeredScaling: true,
      })

      // 缩放到合适大小
      // if (im.width > 800) {
        // 原图尺寸大于2000时缩小到2000（最终尺寸上传的图片）
        // if (im.width > 2000) {
        //   image.scaleToWidth(2000)
        // }
        // const d = Math.floor(800 / im.width * 100) / 100
        // zoom = 0.3//d < 0.3 ? 0.5 : 0.8
      // }
      group = makeGroup(false === preview, [image])
    } else {
      // 从缓存加载
      const snapshot = window.__mosaic.get(url)!
      // 设置画布
      zoom = snapshot.zoom
      group = snapshot.state as Group

      if (preview) {
        group.hasControls = false
      }
    }
    
    setZoomValue(zoom * 100)

    // 先清空
    canvas.getObjects().forEach((o)=> {
      canvas.remove(o)
    })

    // 再设置
    canvas.setZoom(zoom)
    canvas.add(group)
    canvas.setActiveObject(group)
    canvas.viewportCenterObject(group);
    canvas.renderAll()
  }

  useEffect(()=> {
    // 监听url变化
    setTimeout(watchUrlChange, 0)

    return ()=> {
      // 建立快照
      makeSnapshot(url)
      // 初始为移动操作
      setAction(ActionEnum.Move)
      // 初始画笔大小为10
      setBrushValue(defaultBrushValue)
      //
      canvas.isDrawingMode = false
      //
      canvas.freeDrawingBrush!.width = defaultBrushValue

      unbind(canvas)
    }
  }, [url])

  //
  // 初始化fabric
  //
  useEffect(()=> {
    if (!canvasRef.current) {
      return;
    }

    // 初始化
    window.__mosaic = new Map()
    
    // 设置画布尺寸与父级相同
    const parentEl = canvasRef.current.parentElement
    if (parentEl) {
      canvasRef.current.width = parentEl.clientWidth;
      canvasRef.current.height = parentEl.clientHeight;
    }

    // 默认背景
    canvas = new Canvas(canvasRef.current, {
      backgroundColor: '#f6f7fc'
    })
    
    // 设置画笔
    canvas.freeDrawingBrush = makePatternBrash()

    // 鼠标滚轮事件实现绽放图片
    canvas.on('mouse:wheel', function({ e }) {
      const zoom = canvas.getZoom() * 0.999 ** e.deltaY;
      const value = Math.min(Math.max(zoom, 0.1), 2)
      canvas.setZoom(value);
      
      setZoomValue(Math.floor(value * 100))

      e.preventDefault();
    })

    // 强制关闭右键菜单
    canvas.on('mouse:up', ()=> {
      setCurrentPoint(undefined)
      setCurrentTarget(undefined)
    })

    // 鼠标右键菜单
    canvas.on('contextmenu', ({e, target}: any)=> {
      setCurrentPoint(undefined)
      setCurrentTarget(undefined)
      if (target && canvas.getObjects().length > 1 && false === preview) {
        setCurrentTarget(target);
        setCurrentPoint(new Point(e.pageX, e.pageY));
      }
      e.preventDefault();
    })

    return ()=> {
      canvas.destroy()
    }
  }, [])
  //
  // 初始化fabric结束
  //

  // 画笔笔刷宽度
  const handleSliderChanged = (value: number)=> {
    setBrushValue(value)
    if (canvas.freeDrawingBrush) {
      canvas.freeDrawingBrush.width = value;
    }
  }

  // 放大尺寸
  const handleZoomChanged = (value: number)=> {
    setZoomValue(value)
    canvas.setZoom(value / 100)
  }

  const handleMoveAction = ()=> {
    setAction(ActionEnum.Move)

    canvas.isDrawingMode = false;

    const group = makeGroup(true)
    for (const o of canvas.getObjects()) {
      if (o.type === 'group') {
        canvas.setActiveObject(o)
        break;
      }
      group.add(o);
      canvas.remove(o)
    }

    if (group.getObjects().length > 0) {
      canvas.add(group)
      canvas.setActiveObject(group)
    }
  }

  const handleRectAction = ()=> {

    setAction(ActionEnum.Rect)

    canvas.selection = false
    canvas.isDrawingMode = false

    //
    canvas.on({
      'mouse:down': mouseDownHandle,
      'mouse:move': mouseMoveHandle,
      'mouse:up': mouseUpHandle,
    })

    // 取消编组
    const [first, ...objects] = canvas.getObjects()

    if (first.type === 'group') {
      (first as Group).getObjects().forEach((o)=> {
        lockObject(o)
        canvas.add(o);
      })
      canvas.remove(first)
    } else {
      lockObject(first)
      objects.forEach((o)=> lockObject(o))
    }
  }

  const handlePencilAction = ()=> {
    
    setAction(ActionEnum.Pencil)

    canvas.isDrawingMode = true;

    // 取消编组
    const [first, ...objects] = canvas.getObjects()

    if (first.type === 'group') {
      (first as Group).getObjects().forEach((o)=> {
        unlockObject(o)
        canvas.add(o);
      })
      canvas.remove(first)
      // canvas.renderAll();
    } else {
      unlockObject(first)
      objects.forEach((o)=> unlockObject(o))
    }
  }

  // 清空画布
  const handleClearAction = async ()=> {
    const [first, ...objects] = canvas.getObjects()
    // 当前处于移动状态
    if (first.type === 'group') {
      const [image] = (first as Group).getObjects()
      // 移除组内Path
      canvas.remove(first)
      const group = makeGroup()
      group.add(image)
      canvas.add(group)
      canvas.setActiveObject(group)
    } else {
      // 编辑模式下直接删除Path
      for(const obj of objects) {
        canvas.remove(obj);
      }
    }
    onChange?.()
  }

  // 回到原点
  const handleCenterAction = ()=> {
    handleMoveAction()
    setZoomValue(50)
    canvas.setZoom(0.5)
    canvas.viewportCenterObject(canvas.getActiveObject()!)
  }
  
  // 画板主菜单
  const handleToolbarCommand = (value: ActionEnum)=> {
    if (action === ActionEnum.Rect) {
      // 点击矩形时不做处理
      if (value === ActionEnum.Rect) {
        return
      }
      unbind(canvas)
    }
    
    if (value === ActionEnum.Question) {
      setVisibleHelp(true)
      return
    }

    if (value === ActionEnum.Download) {
      handleDonwloadAction();
      return
    }

    // 马克笔
    if (value === ActionEnum.Pencil) {
      handlePencilAction()
      return
    }

    // 矩形
    if (value === ActionEnum.Rect) {
      handleRectAction()
      return
    }

    // 移动到原点
    if (value === ActionEnum.Focus) {
      handleCenterAction()
      return
    }

    // 清除所有覆盖物
    if (value === ActionEnum.Reset) {
      handleClearAction()
      return
    }

    // 选择移动按钮时对象自动编组
    if (value === ActionEnum.Move) {
      handleMoveAction()
      return
    }
  }

  const handleContextMenuCommand = (value: CommandEnum)=> {
    if (value === CommandEnum.Delete) {
      if (currentTarget) {
        canvas.remove(currentTarget);
        setCurrentTarget(undefined)
        setCurrentPoint(undefined)
      }
      return
    }

    setCurrentPoint(undefined)

    if (value === CommandEnum.Clear) {
      handleClearAction()
      return
    }
    
    if (value === CommandEnum.Close) {
      return
    }
  }

  // 下载图片
  const handleDonwloadAction = async ()=> {
    const group = await makeGroupOfClone()
    const dataURL = group.toDataURL({
      quality: 1,
      format: url.split('.').pop() as 'png' | 'jpeg',
      // enableRetinaScaling: true,
      // withoutTransform: true,
    });
    const a = document.createElement('a');
    a.href = dataURL
    a.download = 'image.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  useImperativeHandle(ref, ()=> ({
    clearSnapshot(reset = false) {
      handleMoveAction()
      if (false === reset) {
        window.__mosaic.clear()
      }
    },
    // 获取打码文件
    takeFiles: async()=> {
      const fileMap = new Map<string, File>()
      
      // 再次建立快照
      await makeSnapshot(url)

      window.__mosaic.forEach((value: MosaicSnapshot<Group>, key: string)=> {
        const matched = key.match(/(([a-f|\d]){12}\.(jpe?g|png))$/ig)
        if (Array.isArray(matched)) {
          // > 1 保证有打码痕迹
          if (value.state.getObjects().length > 1) {
            const fileName = matched[0]
            const dataURL = value.state.toDataURL({
              quality: 1,
              format: fileName.split('.').pop() as 'png' | 'jpeg',
              // enableRetinaScaling: true,
              // withoutTransform: true,
            })
            fileMap.set(key, dataURLToFile(dataURL, fileName))
          }
        }
      })
      return fileMap
    }
  }))

  return (
    <div className={modStyl.editor}>
      {/* 画布 */}
      <canvas ref={canvasRef} className={modStyl.canvas}></canvas>
      {/* 工具条 */}
      {
        preview === false && (
          <Toolbar value={action} onCommand={handleToolbarCommand}/>
        )
      }
      {/* 缩放比例 */}
      {
        ActionEnum.Move === action && (
          <Slider value={zoomValue} min={10} max={200} suffix="%" onChange={handleZoomChanged}/>
        )
      }
      {/* 画笔粗细 */}
      {
        ActionEnum.Pencil === action && (
          <Slider value={brushValue} min={defaultBrushValue} max={60} onChange={handleSliderChanged}/>
        )
      }
      {/* 编辑模式和移动模式右键菜单 */}
      {
        currentTarget && currentPoint && (
          <ContextMenu point={currentPoint} showDelete={currentTarget.type !== 'image' || action === ActionEnum.Pencil} onCommand={handleContextMenuCommand}/>
        )
      }
      {/* 帮助信息 */}
      {
        visibleHelp && (
          <HelpModal onClose={()=> setVisibleHelp(false)}/>
        )
      }
      {/* 加载中... */}
      {
        loading && (
          <Spin/>
        )
      }
    </div>
  )
})

export default ImageEditor;