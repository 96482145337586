import React, { useMemo } from "react";

interface ObjectProps {
  /**
   * 按百分比缩放图片。
   * 小于100为缩小，大于100为放大。
   */
  p?: number

  /**
   * 指定目标缩放图的宽度。
   */
  w?: number

  /**
   * 指定目标缩放图的高度。
   */
  h?: number

  /**
   * 指定缩放的模式。
   * lfit（默认值）：等比缩放至指定宽高区域内最大图形。
   * mfit：等比缩放至覆盖指定宽高区域。
   */
  m?: 'lfit' | 'mfit' | 'fill' | 'pad' | 'fixed'

  /**
   * 指定目标缩放图的最长边。
   */
  l?: number

  /**
   * 指定目标缩放图的最短边。
   */
  s?: number
  
  /**
   * 当目标图片分辨率大于原图分辨率时，设置是否进行缩放。
   * 1（默认值）：返回按照原图分辨率转换的图片（可能和原图的体积不一样）。
   * 0：按指定参数进行缩放。
   */
  limit?: 1 | 0

  /**
   * 当缩放模式选择为pad（缩放填充）时，可以设置填充的颜色。
   * 默认值：FFFFFF（白色）
   */
  color?: string
}

interface Props extends ObjectProps {
  /**
   * 图片地址
   */
  src: string

  /**
   * 是否添加随机参数
   */
  rand?: boolean

  /**
   * widthFix 宽度固定，高度自适应缩放
   * heightFix 高度固定，宽度自适应缩放
   * shortFix 短边固定，长边自适应缩放
   * longFix 长边固定，短边自适应缩放
   * pad 缩略图，不对图片剪裁处理。
   * fill 缩略图，剪裁处理。
   * none 不处理图片 原图返回。
   */
  mode?: 'widthFix' | 'heightFix' | 'pad' | 'fill' | 'none'

  /**
   * 图片描述
   */
  alt?: string

  /**
   * HTML属性
   */
  style?: React.CSSProperties
  className?: string
  id?: string
}

/**
 * 阿里参考 https://help.aliyun.com/zh/oss/user-guide/resize-images-4
 * 对象存储
 */
const ObjectImage: React.FC<Props> = ({ src, rand = false, alt='', mode = 'pad', p, children, style, className, id, ...rest}) => {

  const source = useMemo(()=> {

    const s = new URL(src)
    // 清空参数列表
    s.search = ''

    if (mode === 'none') {
      return s.toString()
    }

    if (rand) {
      s.searchParams.set('_', Date.now().toString())
    }

    // 按比例缩放
    if (typeof p === 'number') {
      s.searchParams.set('x-oss-process', `image/resize,p_${p}`)
      return s.toString()
    }
    
    if (mode === 'widthFix') {
      s.searchParams.set('x-oss-process', `image/resize,w_${rest.w || 100}`)
    }

    if (mode === 'heightFix') {
      s.searchParams.set('x-oss-process', `image/resize,h_${rest.h || 100}`)
    }

    let { w, h } = rest

    if (typeof h === 'undefined') {
      h = w
    }
    if (typeof w === 'undefined') {
      w = h
    }
    if (typeof h === 'undefined') {
      h = w = w || 100
    }
    
    w = Math.min(Math.max(w!, 16), 4000)
    h = Math.min(Math.max(h!, 16), 4000)
    
    // 缩略图模式 不剪裁
    if (mode === 'pad') {
      s.searchParams.set('x-oss-process', `image/resize,m_pad,w_${w},h_${h}`)
    }

    // 缩略图模式 居中剪裁
    if (mode === 'fill') {
      s.searchParams.set('x-oss-process', `image/resize,m_fill,w_${w},h_${h}`)
    }

    return s.toString()
  }, [src, rand, p, mode, rest])

  const attributes = useMemo(()=> {
    const s = {} as React.ImgHTMLAttributes<HTMLImageElement>
    if (alt) {
      s.alt = alt
    }
    if (style) {
      s.style = style
    }
    if (className) {
      s.className = className
    }
    if (id) {
      s.id = id
    }
    return s
  }, [alt, style, className, id])

  if (children) {
    return (
      <>
        <img src={source} {...attributes}></img>
        {children}
      </>
    )
  }

  return <img src={source} {...attributes}/>
}

export default ObjectImage;
