import React, { createContext } from "react";
import { QueryParams } from "../../../services/data/dashboard";

type DataBoardDefault = {
  tabIndex: string
  projectId: number
  projectCode: string
  queryParams: QueryParams
}

export const defaultValue = {
  tabIndex: '2',
  projectId: 0,
  projectCode: '',
  queryParams: {}
}

export const DataBoardContext = createContext<DataBoardDefault>({
  ...defaultValue
})