import React from 'react';
import modStyl from './index.module.scss'
import { CommandEnum } from '../utils';
import { Point } from 'fabric';

interface Props {
  point: Point
  showDelete: boolean
  onCommand: (name: CommandEnum)=> void
}

const ContextMenu: React.FC<Props> = ({ showDelete, point, onCommand }) => {
  return (
    <div style={{left: `${point.x}px`, top: `${point.y}px`}} className={modStyl.wrapper}>
      {
        showDelete && (
          <button onClick={()=> onCommand(CommandEnum.Delete)} type="button" className={modStyl.remove}>删除</button>
        )
      }
      <button onClick={()=> onCommand(CommandEnum.Clear)} type="button" className={modStyl.clear}>清空</button>
    </div>
  )
}

export default ContextMenu
