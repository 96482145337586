import React, { useEffect, useMemo, useState } from "react";
import Condition from "./components/condition";
import Category from "./components/category";
import { DataBoardContext, defaultValue } from "./index.context";
import { QueryParams } from "../../../services/data/dashboard";
import modStyl from './index.module.scss'
import { RouteChildrenProps } from "react-router-dom";
import storageX, { Engine } from "../../../util/storageX";

const ssx = storageX(Engine.Session)

type State = {
  id: number
  code: string
  activeKey: string
}

const DataBoard: React.FC<RouteChildrenProps<any, State>> = (props)=> {

  const { location } = props
  
  const [tabIndex, setTabIndex] = useState<string>(defaultValue.tabIndex)

  const [queryParams, setQueryParams] = useState<QueryParams>({})
  
  const onSearch = (q: QueryParams)=> {
    setQueryParams({
      ...q
    })
  }

  const onChange = (index: string)=> {
    setTabIndex(index)
  }

  const {roleCode, projectId, projectCode} = useMemo(()=> {
    return {
      roleCode: ssx.get('roleCode'),
      projectId: location.state.id || ssx.get('projectCode') as number,
      projectCode: location.state.code || ssx.get('projectCode') as string
    }
  }, [location.state])

  useEffect(()=> {
    if (location.state.id) {
      ssx.set('projectId', location.state.id)
      ssx.set('projectCode', location.state.code)
    }
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <DataBoardContext.Provider value={{ tabIndex, projectId, projectCode, queryParams }}>
        <Condition activeKey={tabIndex} onSearch={onSearch} />
        <Category onChange={onChange}/>
      </DataBoardContext.Provider>
    </div>
  )
}
export default DataBoard