import { Tabs } from "antd";
import React, { useEffect } from "react";
import Charts from "./categories/charts";
import PatientTable from "./categories/patient-table";
import PageTable from "./categories/page-table";
import { getUpdateDeadline } from "../../../../services/data/dashboard";

import './category.less'
import modStyl from "./category.module.scss"
import { DataBoardContext } from "../index.context";

//#region 
type DeadlineProps = {

}
const Deadline: React.FC<DeadlineProps> = ()=> {
  const [deadline, setDeadline] = React.useState<string>('')

  const fetchData = async ()=> {
    const { data } = await getUpdateDeadline()
    setDeadline(data)
  }
  
  useEffect(()=> {
    fetchData()
  }, [])

  return (
    <span className={modStyl.deadline}>数据更新截止时间：<em>{deadline}</em></span>
  )
}
//#endregion

type Props = {
  onChange: (key: string)=> void
}

const Category: React.FC<Props> = ({ onChange })=> {
  
  const ctx = React.useContext(DataBoardContext)

  return (
    <Tabs
      activeKey={ctx.tabIndex}
      tabBarExtraContent={<Deadline/>}
      onChange={onChange}
      tabBarGutter={18}
      className="ant-tabs-override-category">
{/*       
      <Tabs.TabPane tab="数据看板" key="0">
        <Charts/>
      </Tabs.TabPane>
      <Tabs.TabPane tab="数据列表-患者层级" key="1">
        <PatientTable/>
      </Tabs.TabPane>
 */}
      <Tabs.TabPane tab="数据列表-页面层级" key="2">
        <PageTable/>
      </Tabs.TabPane>
    </Tabs>
  )
}

export default Category