import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Tabs } from 'antd'

import Setting from "./setting/setting";
import Monitoring from "./monitoring/monitoring";
import ResearchCenter from "./researchCenter/researchCenter";
import Participants from "./participants/participants";
import GroupManage from "./groupManage/groupManage";
import RegionalManagement from "./regionalManagement/regionalManagement";
import ProjectSettlement from "./projectSettlement/projectSettlement";
import ProjectReport from "./projectReport/projectReport";

import {GetProjectDetailInfo} from "../../../services/project/ProjectService";
import storageX, { Engine } from '../../../util/storageX';
import { RouteChildrenProps } from 'react-router-dom';

import './index.less'
import modStyl from './index.module.scss'

const ssx = storageX(Engine.Session)

type State = {
  id: number
  code: string
  activeKey: string
}

const ProjectDetail: React.FC<RouteChildrenProps<any, State>> = (props)=> {

  const { history, location } = props

  //location.state.activeKey
  const [activeKey, setActiveKey] = useState(()=> location.state.activeKey || '1')
  const [hidden, setHidden] = useState(false)

  const [status, setStatus] = useState('')
  const [data, setData] = useState<any>({})

  const {roleCode, projectId, projectCode} = useMemo(()=> {
    return {
      roleCode: ssx.get('roleCode'),
      projectId: location.state.id || ssx.get('projectCode') as number,
      projectCode: location.state.code || ssx.get('projectCode') as string
    }
  }, [location.state])


  const fetchDetail = async ()=> {

    const data = await GetProjectDetailInfo(projectId)

    const transform = {
      PROCESS: '进行中',
      STOP: '已暂停',
      END: '已完成'
    }

    type K = keyof typeof transform

    setStatus(transform[data.status as K] ?? '')
    setData(data)
    ssx.set('projectCode', data.code)
  }

  useEffect(()=> {
    if (location.state.id) {
      ssx.set('projectId', location.state.id)
      ssx.set('projectCode', location.state.code)
      fetchDetail()
    } else {
      history.push('/noConfigPage')
    }

    PubSub.subscribe("onProjectSetSave", (subscribeName: string, projectId: string) => {
      console.log('?', [subscribeName, projectId])
    });
  }, [])

  const handleChange = (key: string) => {
    setActiveKey(key)
    setHidden(['0', '1'].includes(key))
  }

  return (
    <div className={modStyl.wrapper}>
      <div className={modStyl.header}>
        <div className={modStyl.west}>{data.name}</div>
        <div className={modStyl.east} hidden={hidden}>
          <dl>
            <dt>截止日期：</dt>
            <dd>{data.endTimeVal}</dd>
          </dl>
          <dl>
            <dt>目标样本数：</dt>
            <dd>{data.groupGoal}</dd>
          </dl>
        </div>
      </div>
      <Tabs className="ant-tabs-override" tabBarGutter={58} size='large' activeKey={activeKey} onChange={handleChange}>
        <Tabs.TabPane tab="项目监控" key="1">
          <Monitoring projectId={projectId}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="项目报表" key="7">
          <ProjectReport {...props} projectId={projectId} detailTitle={data.name}/>
        </Tabs.TabPane>
        

  
        {
          (roleCode !== 'EDC_AM' && roleCode !== 'DCT_JS_PM' )  && (
            <>
            <Tabs.TabPane tab="项目区域管理" key="4">
              <RegionalManagement projectId={projectId}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="项目研究中心" key="2" >
              <ResearchCenter projectId={projectId}/>
            </Tabs.TabPane>
            </>
          )
        }
        {
          roleCode !== 'DCT_JS_PM' && (
            <Tabs.TabPane tab="项目参与者" key="3">
              <Participants projectId={projectId}/>
            </Tabs.TabPane>
          )
        }
   
        {
          (roleCode !== 'EDC_AM' && roleCode !== 'DCT_JS_PM' )  && (
            <Fragment>
              <Tabs.TabPane tab="分组管理" key="8">
                <GroupManage projectId={projectId} projectCode={data.code}/>
              </Tabs.TabPane>
              <Tabs.TabPane tab="结算管理" key="5">
                <ProjectSettlement projectId={projectId} detailTitle={data.name} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="项目设置" key="6">
                <Setting projectId={projectId} {...data} onSave={(key: string)=> setActiveKey(key)}/>
              </Tabs.TabPane>
            </Fragment>
          )
        }
        


      </Tabs>
    </div>
  )
}

export default ProjectDetail

