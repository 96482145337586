import React from 'react'

import { Cursor, Focus, MarkPen, Rect, Reset } from "../icons"

import Modal from "../modal"

import modStyl from './index.module.scss'

interface Props {
  onClose: ()=> void
}

const HelpModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal onClose={onClose} title="帮助">
      <div className={modStyl.wrapper}>
        <article>
          <h6>如何添加马赛克？</h6>
          <p>1、点击左侧工具栏“<MarkPen/>”图标进入编辑模式，即可在图片上进行涂鸦方式打码操作，同时可用画板正上方滑块调节画笔粗细。</p>
          <p>2、点击左侧工具栏“<Rect/>”图标进入编辑模式，即可在图片上进行框选方式打码操作</p>
        </article>
        <article>
          <h6>如何删除马赛克？</h6>
          <p>点击左侧工具栏“<Cursor/>”图标进入移动模式，在需要删除的马赛克上右键选择删除。</p>
        </article>
        <article>
          <h6>如何清空所有马赛克？</h6>
          <p>点击左侧工具栏“<Reset/>”图标或画布上右键选择清空。</p>
        </article>
        <article>
          <h6>如何放大、缩小图片？</h6>
          <p>可以使用鼠标滚轮进行缩放图片尺寸。</p>
        </article>
        <article>
          <h6>如何移动图片？</h6>
          <p>点击左侧工具栏“<Cursor/>”图标，在图片上长按即可移动图片位置。</p>
        </article>
        <article>
          <h6>如何旋转图片？</h6>
          <p>点击左侧工具栏“<Cursor/>”图标。鼠标移动到图片正上方第1个蓝色正方形后，按住鼠标旋转。</p>
        </article>
        <article>
          <h6>如何回到原点？</h6>
          <p>点击左侧工具栏“<Focus/>”图标。图片将以50%的大小显示在画板中，此功能用于图片缩略在画板中无法定位时使用。</p>
        </article>
      </div>
    </Modal>
  )
}

export default HelpModal