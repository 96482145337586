import React, { useMemo } from "react"
import { Button, Col, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { getTaskDistributeCenterList, getTaskDistributeProjectList } from "../../../../../services/dct/DctService"

import modStyl from './condition.module.scss'
import utils from "../../../../../util/utils"
import TenantConfiguration from "../../../../../util/tenantConfiguration"

export const StatusEnum = {
  '': "全部",
  '1': '待回复',
  '2': '已回复',
  '3': '已关闭',
}

export type QuestionStatus = keyof typeof StatusEnum

export type Params = {
  projectCode: string;
  subjectCode: string;
  questionStatus: QuestionStatus;
  centerCode: string;
  createUser: string;
}

type ConditionProps = {
  onSearch: (params: Partial<Params>)=> void
}

type SelectOption = {
  label: string;
  value: string;
}

const colCols = {
  span: 6
}
const fieldCols = {
  labelCol: {
    span: 5
  },
  wrapperCol: {
    span: 19
  }
}

const Condition: React.FC<ConditionProps> = (props)=> {

  const [projectCode, setProjectCode] = useState<string>()
  const [centerCode, setCenterCode] = useState<string>()
  const [createUser, setCreateUser] = useState<string>()
  const [subjectCode, setSubjectCode] = useState('')
  const [questionStatus, setQuestionStatus] = useState<QuestionStatus>('')

  // 所属中心
  const [centers, setCenters] = useState<SelectOption[]>([])
  // 所属项目
  const [projs, setProjs] = useState<SelectOption[]>([])

  // 
  const targetName = useMemo(()=> TenantConfiguration.read('label'), [])

  // 状态
  const [status] = useState(()=> {
    return Object.keys(StatusEnum).map((value: string)=> ({
      label:  StatusEnum[value as QuestionStatus],
      value: value,
    }))
  })

  // 中心列表
  type  CenterResult = {
    code: number;
    message?: string;
    data: { code: string, title: string }[];
  }
  const fetchCenters = async ()=> {
    const { code, data }: CenterResult = await getTaskDistributeCenterList()
    if(code === 0) {
      setCenters(
        data.map(({title, code})=> ({
          label: title,
          value: code
        }))
      )
      if(data.length === 1) {
        setCenterCode(data[0].code)
        return {
          centerCode: data[0].code
        }
      }
    }
    return {}
  }
  
  // 项目列表
  type ProjectResult = {
    code: number;
    message?: string;
    data: {projectName: string, projectCode: string}[];
  }
  const fetchProjs = async ()=> {
    const { code, data }: ProjectResult = await getTaskDistributeProjectList()
    if(code === 0) {
      setProjs(
        data.map(({projectName, projectCode})=> ({
          label: projectName,
          value: projectCode
        }))
      )
      if(data.length === 1) {
        setProjectCode(data[0]['projectCode'])
        return {
          projectCode: data[0]['projectCode']
        }
      }
    }
    return {}
  }

  const makeParams = (init={} as Partial<Params>)=> {
    return {
      centerCode: centerCode || '',
      createUser: createUser || '',
      questionStatus,
      projectCode: projectCode ?? '',
      subjectCode,
      ...init
    }
  }

  const submitControl = ()=> {
    props.onSearch(makeParams())
  }

  const resetControl = ()=> {
    const projCode = projs.length === 1 ? projs[0]['value'] : undefined
    const cenCode = centers.length === 1 ? centers[0]['value'] : undefined

    setProjectCode(projCode)
    setCenterCode(cenCode)
    setCreateUser('')
    setQuestionStatus('')
    setSubjectCode('')

    props.onSearch({
      centerCode: '',
      createUser: '',
      questionStatus: '',
      projectCode: '',
      subjectCode: '',
    })
  }

  const exportControl = ()=> {
    utils.exportPostFun('dct/project/cra/downLoadImagetList', makeParams())
  }

  const fetchData = async ()=> {
    const a = await fetchProjs()
    const b = await fetchCenters()

    props.onSearch(makeParams({
      ...a,
      ...b,
    }))
  }
  
  useEffect(()=> {
    fetchData()
  }, [])

  return (
    <div className={modStyl.wrapper}>
      <Form>
      <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属项目">
              <Select onChange={(value)=> setProjectCode(value)} value={projectCode} options={projs} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="所属中心">
              <Select onChange={(value)=> setCenterCode(value)} value={centerCode} options={centers} placeholder="请选择" showSearch allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="　采集人">
              <Input onChange={({target})=> setCreateUser(target.value)} value={createUser} placeholder="请输入采集人" allowClear/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label={`${targetName}编号`}>
              <Input onChange={({target})=> setSubjectCode(target.value)} value={subjectCode} placeholder={'请输入'+targetName+'姓名缩写/编号查找'} allowClear/>
            </Form.Item>
          </Col>
          <Col {...colCols}>
            <Form.Item {...fieldCols} label="状态">
              <Select onChange={(value)=> setQuestionStatus(value)} value={questionStatus} options={status} placeholder="请选择"/>
            </Form.Item>
          </Col>
          <Col {...colCols}/>
          <Col {...colCols}>
            <Button onClick={submitControl} type="primary">搜索</Button>
            <Button onClick={resetControl} style={{marginLeft: 10}}>重置</Button>
            <Button onClick={exportControl} style={{marginLeft: 10}} type="link">导出数据</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Condition