import { DatePicker, Form, Input, message, Modal, Radio, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import React, { useEffect, useMemo, useState } from "react";
import { createDataSource, GetProjectsEnume, GetProjectUserList } from "../../../services/download/DownloadService";
import TenantConfiguration from "../../../util/tenantConfiguration";
import { getTenantProjectList } from "../../../services/cdms/data-source";

type Props = {
  onClose(refresh?: boolean): void
}

const initialValues = {
  userCodeList: [],
  passWord: '',
  caseStartDate: '',
  caseEndDate: '',
  subjectStartTime: '',
  subjectEndTime: '',
  planCode: '',
  planVersion: '',
  type: 0,
  exportType: 0,
  isClosed: null
}

const CreateModal: React.FC<Props> = ({ onClose })=> {

  // 1 选择了起始 3 选择结束 4 起止都选择
  const [times, setTimes] = useState(0)
  const [users, setUsers] = useState<any[]>([])
  const [projects, setProjects] = useState<any[]>([])

  const [form] = Form.useForm();

  const targetName = useMemo(()=> TenantConfiguration.read('label'), [])

  const onDatePickerChange = (type: number, value: string)=> {
    let s = 0
    if (value === '') {
      s = Math.max(times - type, 0)
    } else {
      s = times + type
    }
    setTimes(s)
    form.setFieldValue('exportType', value === '' && s === 0 ? 0 : 3)
  }

  // 选择项目时加载数据
  const fetchProjectList = async ()=> {
    const {data} = await getTenantProjectList()
    setProjects(data.map(({code, name}: any)=> ({label: name, value: code})))
  }

  // 选择用户时加载数据
  const fetchUserList = async (projectCode: string)=> {
    const data = await GetProjectUserList({projectCode})
    setUsers(data.map(({code, name}: any)=> ({label: name, value: code})))
  }

  const onSubmit = async ()=> {
    try {
      const data = await form.validateFields()

      const keys = ['startDate', 'endDate', 'subjectStartTime', 'subjectEndTime']
      keys.forEach((key)=> {
        data[key] = (data[key] || '').length === 0 ? null : data[key].format('YYYY-MM-DD')
      })

      const ignore = ['planCode', 'planVersion']
      ignore.forEach((key)=> {
        if ((data[key] || '').length === 0) {
          data[key] = null
        }
      })

      const { code } = await createDataSource(data);
      if (code == 0) {
          message.success('创建成功！');
          onClose(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=> {
    fetchProjectList()
  }, [])

  return (
    <Modal
      title="创建数据下载任务"
      open={true}
      onOk={onSubmit}
      okText='确认创建'
      cancelText='关闭'
      maskClosable={false}
      destroyOnClose
      onCancel={()=> onClose(false)}
      width={680}>
      <Form
        form={form}
        labelCol={{span: 8}}
        wrapperCol={{span: 12}}
        name="nest-messages"
        onFinish={onSubmit}
        requiredMark={true}
        autoComplete="off"
        initialValues={initialValues}>
        <Form.Item
          label="项目"
          name='projectCode'
          rules={[{ required: true, message: '请选择项目' }]}
        >
          <Select
            placeholder='请选择项目'
            options={projects}
            showSearch
            filterOption={(input, option) => option.label.includes(input)}
            onChange={(value)=> fetchUserList(value)}
            allowClear/>
        </Form.Item>
        <Form.Item label="项目参与者账号" name='userCodeList'>
          <Select
            placeholder='请选择项目参与者账号'
            showSearch
            mode="multiple"
            filterOption={(input, option) => option.label.includes(input)}
            options={users}/>
        </Form.Item>
        <Form.Item label="超级密码" name="passWord" rules={[{ required: true, message: '请输入超级密码!' }]}>
          <Input type="password" allowClear placeholder="请输入超级密码"/>
        </Form.Item>
        <Form.Item label="病例完成起始时间" name="endDate" rules={[{ required: false, message: '请输入病例完成起始时间!' }]}>
          <DatePicker  onChange={(_, value)=> onDatePickerChange(1, value)} locale={locale} style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item label="病例完成截至时间" name="endDate" rules={[{ required: false, message: '请输入病例完成截至时间!' }]}>
          <DatePicker onChange={(_, value)=> onDatePickerChange(3, value)} locale={locale} style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item label="方案编码" name="planCode" rules={[{ required: false, message: '请输入方案编码!' }]}>
            <Input allowClear placeholder='请输入方案编码' />
        </Form.Item>
        <Form.Item label="版本号" name="planVersion" rules={[{ required: false, message: '请输入版本号!' }]}>
          <Input allowClear placeholder='请输入版本号' />
        </Form.Item>
        <Form.Item label={`${targetName}创建开始时间`} name="subjectStartTime" rules={[{ required: false, message: `请输入${targetName}创建开始时间!` }]}>
          <DatePicker locale={locale} style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item label={`${targetName}创建结束时间`} name="subjectEndTime" rules={[{ required: false, message: `请输入${targetName}创建结束时间!` }]}>
          <DatePicker locale={locale} style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item
            label="数据格式"
            name="type"
            rules={[{ required: false, message: '请选择是否导出筛败数据!' }]}>
          <Radio.Group >
            <Radio value={0}>Excel</Radio>
            <Radio value={1}>SAS</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item  label="结算状态"  name="isClosed">
          <Radio.Group >
            <Radio value={null}>全部</Radio>
            <Radio value={0}>未结算</Radio>
            <Radio value={1}>已结算</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="数据导出分类"
          name="exportType"
          rules={[{ required: false, message: '请选择是否导出筛败数据!' }]}>
          <Radio.Group disabled={times > 0}>
            <Radio value={0}>全部</Radio>
            <Radio value={1}>未筛败</Radio>
            <Radio value={2}>已入组</Radio>
            <Radio value={3}>已完成</Radio>
            <Radio value={4}>病例已审核</Radio>
            <Radio value={5}>病例签名已审核</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateModal