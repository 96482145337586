import React, { useState } from "react";
import { Modal } from "antd";
import Chat from "./Chat";
import storageX from "../../util/storageX";

type Props = {
  createUserCode: string;
  subjectCode: string;
  visible: boolean;
  onClose: () => void;
};

const ChatModal:React.FC<Props> = ({ subjectCode, createUserCode, onClose }) => {

  // 非当前创建者只读留言
  const [readonly] = useState(()=> {
    return storageX().user().userCode !== createUserCode
  });

  return (
    <Modal
      bodyStyle={{ height: 500, padding: 0 }}
      footer={null}
      width="960px"
      title="与患者对话"
      open={true}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
    >
      <Chat readonly={readonly} createUserCode={createUserCode} subjectCode={subjectCode}/>
    </Modal>
  );
};

export default ChatModal;
