import { Canvas, Pattern, Rect } from "fabric";

let isDown = false
let origX = 0
let origY = 0
let rect: Rect


export function mouseDownHandle(this: Canvas, o: any) {
  isDown = true;

  const source = new Image()
  source.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPLkyX8GPEBYWBifNAPjqAHDIgx27dqFNx3IysriTwejBjAwDv0wAADzZztR4yZAZQAAAABJRU5ErkJggg=='
  
  const pointer = this.getScenePoint(o.e);

  origX = pointer.x;
  origY = pointer.y;
  rect = new Rect({
    left: origX,
    top: origY,
    originX: 'left',
    originY: 'top',
    width: pointer.x - origX,
    height: pointer.y - origY,
    selectable: false,
    fill: new Pattern({ source }),
  });
  this.add(rect);
}

export function mouseMoveHandle(this: Canvas, o: any) {
  if (!isDown || !rect) {
    return
  }

  var pointer = this.getScenePoint(o.e);

  if(origX>pointer.x){
    rect.set({ left: Math.abs(pointer.x) });
  }
  if(origY > pointer.y){
    rect.set({ top: Math.abs(pointer.y) });
  }

  rect.set({ width: Math.abs(origX - pointer.x) });
  rect.set({ height: Math.abs(origY - pointer.y) });

  this.renderAll();
}

export function mouseUpHandle() {
  if (isDown) {
    isDown = false;
    rect!.setCoords();
  }
}
