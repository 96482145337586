/**
 * 数据中心模块路由配置
 */
import Workbench from '../components/data/workbench/workbench';// 工作台路由组件
import Subjects from '../components/data/workbench/all/subjects/subjects';// 受试者组件
import BatchCaseRewiew from '../components/data/batchCaseRewiew';// 批量病例审核
import MessageHome from '../components/data/message/Home';// 批量病例审核
import DataBoard from '../components/data/data-board';

const routes = [
    { 'path': '/data/workbench', 'component': Workbench },
    { 'path': '/data/workbench/all/subjects', 'component': Subjects },
    { 'path': '/data/batch/audit', 'component': BatchCaseRewiew },
    { 'path': '/data/message', 'component': MessageHome },
    { 'path': '/data/board/list', 'component': DataBoard },
];

export default routes
