import React, { useRef, useState } from "react"
import Statistics, { StatisticsActions } from "./components/Statistics"

import indexStyl from './index.module.scss'
import Condition, { ApproveStatus, Params, StatusEnum } from "./components/Condition"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import { getTaskDistributeImageList, getTaskDistributeImageSubjectList } from "../../../services/dct/DctService"
import DetailModal from "./components/DetailModal"

export type DataResult = {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: DataType[];
  }
}

export interface Thumbnail {
  id: string
  // 列表中是数组详情是字符串
  url: string[] | string;
  subjectName: string
  [name: string]: unknown
}

export interface DataType extends Thumbnail {
  id: string;
  label: string;
  projectName: string;
  centerName: string;
  subjectCode: string;
  subjectName: string;
  createUser: string;
  tenantCode: string;
  collector: string;
  // 列表中是数组详情是字符串
  url: string[] | string;
  approveStatus: ApproveStatus;
  visitName: string;
  version: string;

  deCode: string;
  dataCode: string;
  
  rejectReason: string | null;

  desensitization: 1 | 2;
  desensitizationPass: 1 | 2;

  formId: number;
  formCode: string;
  formName: string;

  completeUserCode: string;
  completeTime: string;
}

const ImageCheck = ()=> {

  const [visible, setVisible] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [records, setRecords] = useState<DataType[]>([])

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const statRef = useRef<StatisticsActions>(null)

  const conditionRef = useRef<Params>({
    subjectCode: "",
    projectCode: "",
    approveStatus: '', 
    centerName: "",
    createUser: "",
    startTime: "",
    endTime: ""
  })

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      width: 120,
      title: '所属项目',
      ellipsis: true,
      dataIndex: 'projectName',
    },
    {
      width: 120,
      title: '所属中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      title: '访视',
      dataIndex: 'visitName',
    },
    {
      width: 120,
      title: '表单',
      dataIndex: 'formName',
    },
    {
      width: 120,
      title: '采集人',
      dataIndex: 'collector',
    },
    {
      width: 160,
      title: sessionStorage.getItem('targetName') || '受试者',
      dataIndex: 'subjectName',
      render: (value: string, record: DataType)=> `${value}(${record['subjectCode']})`
    },
    {
      width: 160,
      title: '提交时间',
      dataIndex: 'createTime',
      render: (value: string)=> value || '-'
    },
    {
      width: 100,
      title: '审核状态',
      dataIndex: 'approveStatus',
      render: (value: ApproveStatus)=> StatusEnum[value]
    },
    {
      width: 160,
      title: '审核时间',
      dataIndex: 'completeTime',
      render: (value: string)=> value || '-'
    },
    {
      width: 120,
      title: '审核人员',
      dataIndex: 'completeUserCode',
      render: (value: string)=> value || '-'
    },
    {
      width: 100,
      title: '撤回原因',
      dataIndex: 'resetReason',
      ellipsis: true,
      render: (value: string)=> value || '-'
    },
    {
      width: 160,
      title: '撤回时间',
      dataIndex: 'resetTime',
      render: (value: string)=> value || '-'
    },
    {
      width: 120,
      title: '撤回人员',
      dataIndex: 'resetUserName',
      render: (value: string)=> value || '-'
    },
    {
      width: 80,
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (_: any, record: DataType) => (
        <Button onClick={()=> fetchSubjectRecords(record)} type="primary" size="small">查看详情</Button>
      ),
    },
  ];
  //#endregion

  const fetchSubjectRecords = async(record: DataType)=> {
    const { data } = await getTaskDistributeImageSubjectList(record.subjectCode, conditionRef.current.approveStatus)

    if (data.length > 1) {
      let index = 0
      for(const item of data) {
        if (item.formId === record.formId) {
          data.unshift(data.splice(index, 1).pop() as DataType)
          break;
        }
        index ++
      }
    }
    setRecords(data)
    setVisible(true)
  }

  const fetchData = async (params: Params, page = 1)=> {
    setLoading(true)
    const {code, data}: DataResult = await getTaskDistributeImageList({
      ...params,
      page,
      pageSize: pageSize
    })
    if (code) {
      return
    }
    setPageNum(page)
    setLoading(false)
    setTotal(data.total)
    setDataSource(data.rows)
  }

  const onSearch = (params: Partial<Params>)=> {
    conditionRef.current = {
      ...conditionRef.current,
      ...params
    }
    fetchData(conditionRef.current)
  }

  // 当图片审核状态变更后及时刷新列表数据
  const refreshControl = (needReload=false)=> {
    setVisible(false)
    if(needReload) {
      statRef.current?.refresh()
      fetchData(conditionRef.current)
    }
  }

  return (
    <>
    <div className={indexStyl.wrapper}>
      <Statistics ref={statRef}/>
      <Condition onSearch={onSearch}/>
      <div className={indexStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 420}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            onChange: (page: number)=> {
              fetchData(conditionRef.current, page)
            },
            total: total,
            current: pageNum,
            pageSize: pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,
            position: ["bottomRight"]}}/>
      </div>
    </div>
    {/* 新版打码详情 */}
    {
      visible && records.length > 0 && (
        <DetailModal onClose={refreshControl} data={records}/>
      )
    }
    </>
  )
}

export default ImageCheck