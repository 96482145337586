import modStyl from './CallPhoneNumber.module.scss'
import React, { useEffect, useRef, useState } from 'react';
import { Button, message as Message, Modal } from 'antd';
import { PhoneFilled } from '@ant-design/icons';
import { formatSeconds } from './utils';
import { finishCall, saveVisitRecord } from '../../../../services/dct/visit';
import storageX, { Engine } from '../../../../util/storageX';
import { busy, EventName, hangup, login, logout, makeCall, ready, start } from './web-agent';

type ButtonProps = {
  onClick: ()=> void
}
const CallButton: React.FC<ButtonProps> = ({onClick})=> {
  return (
    <Button onClick={onClick} type='primary' className={modStyl.button}>
      <PhoneFilled style={{transform: 'rotate(180deg)'}}/>
      <span>拨打电话</span>
    </Button>
  )
}

const HangUpButton: React.FC<ButtonProps> = ({onClick})=> {
  return (
    <Button onClick={onClick} danger={true} type='primary' className={modStyl.button}>
      <PhoneFilled style={{transform: 'rotate(224deg)'}}/>
      <span>挂断电话</span>
    </Button>
  )
}

let timer: any;

enum AgentStatusEnum {
  // 初始状态
  None,
  // 已登录
  Login,
  // 登录失败
  LoginFail,
  // 准备就绪(或挂断通话)
  Ready,
  // 振铃中
  Alerting,
  // 通话中
  Connected,
  // 座席被其他人点用
  Otherwork,
}

type Props = {
  phoneNumber: string;
  visitTaskId: number;
  // 用于标记需要刷新列表
  refresh: (state: boolean)=> void;
}
const CallPhoneNumber: React.FC<Props> = ({phoneNumber, visitTaskId, refresh})=> {
  // 
  const [status, setStatus] = useState(AgentStatusEnum.None)
  const [seconds, setSeconds] = useState(0)
  const [errMessage, setErrMessage] = useState('')

  const connected = useRef(false)
  const disconnected = useRef(false)

  // 主动挂断电话
  const handleHangup = ()=> {
    // 挂断
    hangup()
  }

  // 拨打电话
  const handleCall = ()=> {
    disconnected.current = false
    // 拨号
    const cNumber = storageX(Engine.Session).get('phoneNumber', phoneNumber) as string

    makeCall(cNumber)
  }

  const hangupUni = async (sessionId: string, visitTaskId: number)=> {
    await saveVisitRecord(visitTaskId, sessionId)
    // 打通再推
    if (connected.current) {
      connected.current = false
      await finishCall(sessionId)
    }
    refresh(true)
  }
  
  const eventHandler = (event: WebAgentNS.SimpleEvent<WebAgentNS.ConnectedEventExt>)=> {
    const { type } = event
    // console.log('????registerEventHandler', event)
    if (type === 'EVENT_AGENT_NOTREADY') {
      // 挂断后会自动推送置忙 这里要过滤掉
      if(disconnected.current) {
        setStatus(AgentStatusEnum.Ready)
      } else {
        setStatus(AgentStatusEnum.Login)
      }
    }
    // 登录回调
    else if (type === 'EVENT_AGENT_LOGIN') {
      setStatus(AgentStatusEnum.Ready)
    }
    else if (type === 'EVENT_AGENT_LOGIN_FAIL') {
      setStatus(AgentStatusEnum.LoginFail)
      setErrMessage(event.ext.errorMessage as string)
    }
    // 外呼坐席振铃事件
    else if(type === 'EVENT_OUTBOUND_ALERTING_TP') {
      window.CR.answer()
      setStatus(AgentStatusEnum.Alerting)
    }
    // 外呼客户接通事件 客户接听开始计时
    else if(type === 'EVENT_OUTBOUND_CONNECTED_OP') {
      connected.current = true
      setStatus(AgentStatusEnum.Connected)
      timer = setInterval(()=> setSeconds((preSeconds)=> preSeconds+1), 1000)
    }
    // 坐席挂断或客户挂断
    else if (['EVENT_OP_DISCONNECT', 'EVENT_TP_DISCONNECT'].includes(type)) {
      clearInterval(timer)
      // 标记
      disconnected.current = true

      const sessionId = event.ext.sessionId.split(':').shift() as string
      // 
      setSeconds(0)
      //
      setStatus(AgentStatusEnum.Ready)
      // 挂断
      hangupUni(sessionId, visitTaskId)
    }
  }

  // 退出
  useEffect(()=> {
    PubSub.subscribe(EventName, (_, data)=> {
      eventHandler(data)
    })

    setTimeout(()=> login(), 400)

    return ()=> {
      PubSub.unsubscribe(EventName)
      if (connected.current) {
        clearInterval(timer)
        hangup()
      } else {
        logout()
      }
    }
  }, [])

  return (
    <div className={modStyl.callPhoneNumber}>
      <div className={modStyl.r}>
        {status === AgentStatusEnum.None && <span>建立连接...</span>}
        {status === AgentStatusEnum.Login && <span>登录成功</span>}
        {status === AgentStatusEnum.LoginFail && <span>{errMessage}</span>}
        {status === AgentStatusEnum.Ready && <span>&nbsp;</span>}
        {status === AgentStatusEnum.Alerting && <span className={modStyl.ringing}>拨号中...</span>}
        {status === AgentStatusEnum.Connected && <span className={modStyl.seconds}>{formatSeconds(seconds)}</span>}
      </div>
      <div className={modStyl.r}>
        {/* 打电话 */}
        {
          status === AgentStatusEnum.Ready &&  (
            <CallButton onClick={handleCall}/>
          )
        }
        {/* 挂断电话 */}
        {
          (status === AgentStatusEnum.Alerting  || status === AgentStatusEnum.Connected) && (
            <HangUpButton onClick={handleHangup}/>
          )
        }
      </div>
    </div>
  )
}

export default CallPhoneNumber