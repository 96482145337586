import tool from "./tool";
import { message } from "antd";

type Remind = {
  site?: string;
  type: 'range' | 'subjectExpr';
  condition: ('gt' | 'lt' | 'gte' | 'lte' | 'eq')[];
  rule: (number | string)[];
  message: string[];
}

type Rule = {
  type: string;
  rule: string;
  condition: string;
}

type Field = {
  type: string;
  label: string;
  dataCode?: string;
  remind?: Remind;
  reminds?: Remind[];
  validationRules?: Rule[];
}

// 基本比较运算符
const comparisonOperators = ['lt', 'lte', 'gt', 'gte', 'eq']

// 基础比较运算函数和comparisonOperators一一对应 请勿修改顺序
const filter = (index: string, right: number)=> {
  const filters = [
    // lt
    (value: number)=> value < right,
    // lte
    (value: number)=> value <= right,
    // gt
    (value: number)=> value > right,
    // gte
    (value: number)=> value >= right,
    // eq
    (value: number)=> value == right,
  ];
  return filters[Number(index)]
}

/**
 * 获得比较结果索引
 * @param idx 符号索引
 * @param value 当字段前值
 * @param right 比较的参考值
 * @returns 
 */
const startFindIndexCompare = (idx: string, value: any[], right: number)=> {
  return value.findIndex(filter(idx, right))
}

/**
 * 获得比较结果
 * @param idx 符号索引
 * @param value 当字段前值
 * @param right 比较的参考值
 * @returns 
 */
const startCompare = (idx: string, value: number, right: number)=> {
  return filter(idx, right)(value)
}

// 处理range校验逻辑
const startRange = (remind: Remind, value: string, centerCode?: string)=> {
  if(null === value) {
    return null
  }
  const { site, condition, rule, message } = remind
  // site 校验
  if(typeof site === 'string') {
    if(site !== '*' && site !== centerCode) {
      return null;
    }
  }
  // 比较
  if(Array.isArray(condition) || Array.isArray(rule)) {
    for(const i in condition) {
      if(rule[i] === '' || rule[i] === null) {
        continue
      }
      for(const n in comparisonOperators) {
        if(comparisonOperators[n] === condition[i]) {
          if(startCompare(n, Number(value), Number(rule[i]))) {
            return message[i]
          }
          // 已经匹配到操作符 这里直接退出
          break;
        }
      }
    }
  }
  return null;
}

// 处理checkbox校验
const startCheckboxRange = (remind: Remind, value: string[])=> {
  if(Array.isArray(remind.condition) && Array.isArray(remind.rule)) {
    const { condition, rule, message } = remind
    for(const i in condition) {
      // 遍历运算符获取结果返回
      for(const n in comparisonOperators) {
        if(condition[i] === comparisonOperators[n]) {
          if(startFindIndexCompare(n, value, Number(rule[i])) > -1) {
            return {
              status: true,
              message: message[i]
            }
          }
        }
      }
    }
  }
  return null;
}

// 复杂的区间运算比较
const complexOperators = [
  ['lt', 'gt'],
  ['lt', 'gte'],
  ['lte', 'gt'],
  ['lte', 'gte'],
  ['gt', 'gt'],
  ['gt', 'lt'],
  ['gt', 'lte'],
  ['gt', 'gte'],
  ['gte', 'lt'],
  ['gte', 'lte'],
]

// 返回复杂的区间运算比较函数
const complexFilter = (idx: string, rule: number[])=> {
  const filters = [
    // lt, gt
    (value: number)=> value < rule[0] || value > rule[1],
    // lt, gte
    (value: number)=> value < rule[0] || value >= rule[1],
    // lte, gt
    (value: number)=> value <= rule[0] || value > rule[1],
    // lte, gte
    (value: number)=> value <= rule[0] || value >= rule[1],
    // gt, gt
    (value: number)=> value > rule[0] || value > rule[1],
    // gt, lt
    (value: number)=> value > rule[0] || value < rule[1],
    // gt, lte
    (value: number)=> value > rule[0] || value <= rule[1],
    // gt, gte
    (value: number)=> value > rule[0] || value >= rule[1],
    // gt, gte
    (value: number)=> value >= rule[0] || value < rule[1],
    // gte, lte
    (value: number)=> value >= rule[0] || value <= rule[1],
  ];
  return filters[Number(idx)]
}

const startCheckValue = (value: number, field: Field, contentPart='')=> {
  // 'lt', 'lte', 'gt', 'gte', 'eq'
  const semantics = [
    '小于',
    '小于等于',
    '大于',
    '大于等于',
    '等于',
  ]

  if(Array.isArray(field.validationRules)) {
    for(const item of field.validationRules) {
      if(item.rule === '' || item.rule === null || !comparisonOperators.includes(item.condition)) {
        continue
      }
      if(item.type === 'range') {
        for(const i in comparisonOperators) {
          // lt === lt
          if(item.condition === comparisonOperators[i]) {
            // 没有匹配到
            if(!filter(i, Number(item.rule))(value)) {
              // 获取remind配置项中的message
              let content = null;
              if(field.remind) {
                if(Array.isArray(field.remind.message)) {
                  content = field.remind.message[0]
                }
              }
              message.error(content ?? `${field.label}${contentPart}需要${semantics[i]}${item.rule}`, 1)
              return false;
            }
          }
        }
      }
    }
  }
  return true;
}

// 替换校验字段规则为真实数据(表单提交时使用)
export const replaceFieldRule = (rules: Rule[], values: any[])=> {
  // feature: 处理number字段和number字段数值比较
  if(Array.isArray(rules)) {
    // 未做深拷贝
    rules.forEach((item: any)=> {
      if (item.type === 'range') {
        if(item._rule) {
          item.rule = item._rule
        }
        // 不能是 number-string
        if(typeof item.rule === 'string' && !/^(\d)+$/.test(item.rule)) {
            // 从表单数据中获取需要的字段值
          let found = false
          for(const curr of values) {
            // dataCode#1
            if(curr.dataCode === item.rule) {
              found = true
              item._rule = item.rule
              item.rule = curr.value
              break;
            }
          }
          if(!found) {
            item._rule = item.rule
            item.rule = ''
          }
        }
      }
    })
  }
}

// 替换字段remind提醒rule(number,decimal组件使用)
export const replaceFieldRemind = (remind: Remind, savedValues: any[])=> {
  // feature: 使用列表中的其他字段进行比较
  if(remind) {
    remind.rule = remind.rule.map((mix: string | number)=> {
        if(typeof mix === 'number') {
            return mix;
        }
        // 获得其他字段数据
        if(typeof mix === 'string') {
            for(const {dataCode, value} of savedValues) {
                if(dataCode === mix) {
                    return value
                }
            }
            return null
        }
        return mix;
    })
  }
}

export default {
  //限制输入格式
  checkValue(val: any, field: any) {
    let fieldType = field.type;
    let inputType = field.inputType;
    let validationRules = field.validationRules;
    //表单类型为number类型
    if (fieldType === "number") {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      if (validationRules && validationRules.length > 0) {
        for (let validationItem of validationRules) {
          if (validationItem.type === "range") {
            if (validationItem.condition === "maxLength") {
              val = val.slice(0, validationItem.rule);
            }
          }
        }
      }
      // if (val.substr(0, 1) === '0') {
      //     val = val.substr(1, val.length);
      // }
      // if (val.substr(0, 1) === "0") {
      //   val = val;
      // }
      // 统一转化为字符串用于计算
      val = val + "";
    }
    //表单类型为input-select
    else if (fieldType === "input-select") {
      if (inputType === "decimal") {
        // 小数点前的位数
        let maxLength;
        //小数点后的位数
        let maxPrecision;
        if (validationRules && validationRules.length > 0) {
          for (let validationItem of validationRules) {
            if (validationItem.type === "range") {
              if (validationItem.condition === "maxLength") {
                maxLength = validationItem.rule;
              } else if (validationItem.condition === "maxPrecision") {
                maxPrecision = validationItem.rule;
              }
            }
          }
          val = tool.setFloat(val, maxLength, maxPrecision);
        }
      }
      // 非空校验
      else if (inputType === "inputrange") {
        val = val.replace(/[^\d.]/g, ""); // 清除“数、小数”以外的字符
        if (validationRules && validationRules.length > 0) {
          for (let validationItem of validationRules) {
            if (validationItem.type === "integrity") {
              if (
                validationItem.condition === "ne" &&
                validationItem.rule === "null"
              ) {
              }
            }
          }
        }
      }
    }
    //表单类型为decimal
    else if (fieldType === "decimal") {
      // 小数点前的位数
      let maxLength;
      //小数点后的位数
      let maxPrecision;
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      if (validationRules && validationRules.length > 0) {
        for (let validationItem of validationRules) {
          if (validationItem.type === "range") {
            if (validationItem.condition === "maxLength") {
              maxLength = validationItem.rule;
            } else if (validationItem.condition === "maxPrecision") {
              maxPrecision = validationItem.rule;
            }
          }
        }
        val = tool.setFloat(val, maxLength, maxPrecision);
      }
    }
    return val;
  },
  //限制输入区间 提示输入错误信息
  checkValueByRange(val: any, field: Field) {
    if(['number', 'decimal'].includes(field.type)) {
      if(val === '') {
        return true;
      }
      return startCheckValue(val, field);
    }
    if(field.type === 'input') {
      if(!val || val.length === 0) {
        return true;
      }
      return startCheckValue(val.length, field, '输入长度')
    }
    return true;
  },
  //通过正则表达式提示输入错误信息
  checkValueByRep(val: any, field: any) {
    let fieldType = field.type;
    let validationRules = field.validationRules;
    //表单类型为number类型
    let judge = true;
    if (fieldType === "input") {
      if (validationRules && validationRules.length > 0) {
        for (let validationItem of validationRules) {
          if (validationItem.type === "format") {
            if (validationItem.condition === "regular") {
              let regExp = new RegExp(validationItem.rule);
              // 允许保存恢复空值的初始状态
              if (!val || regExp.test(val)) {
                judge = true;
              } else {
                judge = false;
              }
            }
          }
        }
      }
    }

    return judge;
  },
  // 数据异常提醒
  checkValueAnnotation(
    val: any,
    field: Field,
    centerCode?: any,
    savedValues?: any
  ) {
    let result: any = {
      status: false,
      message: "",
    };
    if('' === val) {
      return result
    }
    if (
      field.type == "input" ||
      field.type == "number" ||
      field.type == "decimal" ||
      field.type == "date" ||
      field.type == "select"
    ) {
      if (field.remind) {
        const remind = field.remind as Remind
        if(remind.type === 'range') {
          const message = startRange(field.remind, val)
          if(typeof message === 'string') {
            return {
              status: true,
              message: message
            }
          }
        } else if (remind.type === 'subjectExpr') {
          if(field.dataCode !== 'weight') {
            return result
          }
  
          if(Array.isArray(savedValues)) {
            let tallVal = 0;
            for(const item of savedValues) {
              if(item.dataCode === 'tall') {
                tallVal = item.value
                break;
              }
            }
  
            if(!tallVal) {
              return result
            }
  
            if(Array.isArray(remind.condition) && Array.isArray(remind.rule)) {
              const left = Number(val) / Math.pow(Number(tallVal) / 100, 2)
              for(const i in remind.condition) {
                const right = Number(remind.rule[i])
                if(remind.condition[i] === 'lt') {
                  if(left < right) {
                    return {
                      status: true,
                      message: remind.message[i]
                    }
                  }
                } else if(remind.condition[i] === 'gt') {
                  if(left > right) {
                    return {
                      status: true,
                      message: remind.message[i]
                    }
                  }
                }
              }
            }
          }
        }
        return result
      }

      if (field.reminds) {
        if(!Array.isArray(field.reminds)) {
          return result;
        }
        const { reminds } = field
        for(const idx in reminds) {
          if(reminds[idx].type === 'range') {
            const message = startRange(reminds[idx], val, centerCode)
            if(typeof message === 'string') {
              return {
                status: true,
                message: message
              }
            }
          }
        }
      }
      // 来个兜底
      return result
    }

    if (field.type == "checkbox") {
      if (field.remind) {
        const remind = field.remind as Remind
        if(remind.type === 'range') {
          const message = startCheckboxRange(remind, val)
          if(message) {
            return message
          }
        }
        return result
      }
      
      if (Array.isArray(field.reminds)) {
        const messages = []
        for(const i in field.reminds) {
          const remind = field.reminds[i]
          if(remind.type === 'range') {
            const message = startCheckboxRange(remind, val)
            if(message) {
              messages.push(message.message)
            }
          }
        }

        if (messages.length > 0) {
          return {
            status: true,
            message: messages.join(""),
          }
        }
      }
    }

    return result;
  },
  /**
   * remind区间字段显隐判断
   */
  checkRemindValueByRange(condition: any, rule: any, value: any) {
    const result: boolean[] = [];

    if(!Array.isArray(condition)) {
      return result
    }

    if(condition.length === 1) {
      for(const n in comparisonOperators) {
        if(comparisonOperators[n] == condition[0]) {
          if(startCompare(n, value, rule[0])) {
            result.push(true)
          }
        }
      }
      return result
    }

    for(const n in complexOperators) {
      const operators = complexOperators[n]
      if(operators.join(',') === condition.slice(0, 2).join(',')) {
        if(complexFilter(n, rule)(value)) {
          result.push(true)
        }
      }
    }
    return result
  },
  /**
   * 为空校验
   */
  isEmpty(value: any, param: any) {
    let validationRules = param.validationRules;
    if (validationRules && validationRules.length > 0) {
      for (let validationItem of validationRules) {
        if (validationItem.type === "integrity") {
          if (
            validationItem.condition === "ne" &&
            validationItem.rule === "null"
          ) {
            if (value === null && value === undefined && value === "") {
              return true;
            }
            if (
              param.type == "input-select" &&
              value &&
              Array.isArray(value) &&
              (!value[0] || !value[1])
            ) {
              // value[0] 是输入框，value[1] 是选项得分
              return true;
            }
          }
        }
      }
    }
    return false;
  },
};
