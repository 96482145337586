import React, { PropsWithChildren } from "react";
import { CloseCircle } from "../icons";

import modStyl from './index.module.scss'

interface Props {
  title: string
  onClose: () => void
}

const Modal: React.FC<PropsWithChildren<Props>> = ({ title, children, onClose }) => {
  return (
    <div className={modStyl.lock}>
      <div className={modStyl.wrapper}>
        <div className={modStyl.header}>
          <h2>{title}</h2>
          <button onClick={onClose} type="button">
            <CloseCircle filled/>
          </button>
        </div>
        <div className={modStyl.content}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;