import { Modal } from "antd";
import React, { ReactElement, useEffect, useState } from "react";

interface Props {
  title: string
  onClose: ()=> void
  children: ReactElement
}

const FullModal: React.FC<Props> = ({ title, children, onClose })=> {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight - 55)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight - 55)
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal onCancel={onClose} destroyOnClose centered title={title} width={width} style={{maxWidth: '100vw'}} bodyStyle={{padding: 0, height}} open={true} footer={null} maskClosable={false}>
      {children}
    </Modal>
  )
}

export default FullModal;
