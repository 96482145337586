/*
批量结算
*/

import { requestGet, requestPostRes, requestPostFileRes } from '../EdcService';

const urlPrefix = "/data";

// 项目枚举 
export const getProjectEnume = (params) => {
    const url = `${urlPrefix}/close/projectList`;
    return requestGet(url, params)
};

export const getSettlementList = (params) => {
    const url = `${urlPrefix}/close/list`;
    return requestPostRes(url, params)
};


export const getSettleModule = (params) => {
    const url = `${urlPrefix}/close/template`;
    return requestGet(url, params)
};

export const batchSettlement = (params) => {
    const url = `${urlPrefix}/close/batch`;
    return requestPostFileRes(url, params)
};

export const getSettlementUrl = (params) => {
    const url = `${urlPrefix}/close/download`;
    return requestGet(url, params)
};
