import React, { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import Slider from "react-slick";
import ObjectImage from "../../../object-image";
import { Thumbnail } from "..";

import './album-slider.less'

interface AlbumSliderProps {
  slickGoTo(index: number): void
}

interface Props<T> {
  onChange: (index: number)=> void
  thumbs: T[]
}

const AlbumSlider = <T extends Thumbnail, >({ thumbs, onChange }: Props<T>, ref: ForwardedRef<AlbumSliderProps>) => {

  const sliderRef = React.createRef<Slider>();

  useImperativeHandle(ref, ()=> ({
    slickGoTo(index: number) {
      sliderRef.current!.slickGoTo(index)
    }
  }))

  return (
    <Slider
      ref={sliderRef}
      className="imageck-slider"
      speed={0}
      slidesToShow={thumbs.length > 8 ? 8 : thumbs.length}
      slidesToScroll={1}
      dots={false}
      infinite={true}
      centerMode={true}
      swipeToSlide={true}
      variableWidth={true}
      focusOnSelect={true}
      adaptiveHeight={true}
      afterChange={onChange}>
        {
          thumbs.map((item, i)=> (
            <ObjectImage key={item.id} src={item.url as string} mode="heightFix" h={50} alt={item.subjectName} rand>
              <i>{i+1}</i>
            </ObjectImage>
          ))
        }
    </Slider>
  );
}

export default forwardRef(AlbumSlider)
