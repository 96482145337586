import { requestGetRes, requestPostRes } from "../EdcService";

interface Result<Data> {
  code: number;
  message: string;
  success: boolean;
  data: Data
}

interface ListResult<T> {
  page: number;
  pageSize: number;
  total: number
  rows: T[]
}

export type SignAgreementResult = {
  agreement: string;
  allocationCode: unknown | null;
  centerCode: string;
  code: string;
  createTime: string;
  createUser: string;
  hasScreen: unknown | null;
  icon: string;
  id: number | null;
  joinDate: string;
  projectCode: string;
  state: number;
}

// 根据code查询签署模板信息
export const signAgreementQuery = (subjectCode: string, fromDoctor=false)=> {
  return requestGetRes(`data/subject/signAgreementQuery/${subjectCode}?judgment=${fromDoctor ? 1 : 2}`)
}

// 项目报表 - 入组数据报表（君实）
export type JoinProjectListResult = {
  createdTime: string
  createdUser: string
  finishTime: string
  id: number	
  projectCode: string
  taskName: string
  taskState: number
  url: string
}
export const getJoinProjectList = (projectCode: string, page: number, pageSize: number): Promise<Result<ListResult<JoinProjectListResult>>>=> {
  return requestPostRes('data/join/list', {
    projectCode,
    page,
    pageSize
  })
}

export const createJoinProjectTask = (projectCode: string): Promise<Result<any>>=> {
  return requestPostRes(`/data/join/task/${projectCode}`)
}