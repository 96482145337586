import React, { useEffect, useMemo, useRef, useState } from "react"

import modStyl from './index.module.scss'

import Condition, { QuestionStatus, Params, StatusEnum } from "./components/condition"
import { Button, Empty, Table } from "antd"
import { ColumnsType } from "antd/lib/table"
import DetailModal, { fetcChallList, makeLast, Options } from "./components/detail-modal"
import TenantConfiguration from "../../../../util/tenantConfiguration"

type Value = {createUser: string, role: string, createTime: string}

type CellsProps = {
  label: string
  value: Value
}
const Cells: React.FC<CellsProps> = ({ label, value })=> {
  if (value ) {
    return (
      <dl className={modStyl.blk}>
      <dd>{label}者: {value.createUser}</dd>
      <dd>角色: {value.role}</dd>
      <dd>{label}时间: {value.createTime.substring(0, 16)}</dd>
    </dl>
    )
  }
  return <span>-</span>
}

export type DataResult = {
  code: number;
  message?: string;
  data: {
    page: number;
    pageSize: number;
    total: number;
    rows: DataType[];
  }
}

export type DataType = {
  id: string
  qid: string
  label: string
  projectName: string
  content: string
  subjectCode: string
  dataCode: string
  deCode: string
  enter: string
  formId: null
  formName: string
  questionId: string
  questionText: string
  questionStatus: QuestionStatus;
  visitName: string;
  isLock: null
}

const Chall = ()=> {

  const [visible, setVisible] = useState(false)
  
  const [pageSize] = useState(20)
  const [pageNum, setPageNum] = useState(1)
  
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const [dataSource, setDataSource] = useState<DataType[]>([])

  const targetName = useMemo(()=> TenantConfiguration.read('label'), [])

  const conditionRef = useRef<Params>({
    centerCode: "",
    createUser: "",
    subjectCode: "",
    projectCode: "",
    questionStatus: '', 
  })

  const [options, setOptions] = useState({} as Options)

  // 计算详情需要的翻面参数
  const makeOptions = (index: number)=> {
    const last = makeLast(index, total, pageNum, pageSize)
    return {
      index,
      last,
      condition: conditionRef.current,
      pageNum,
      pageSize,
      total,
      records: dataSource
    }
  }

  //#region 表格列
  const columns: ColumnsType<DataType> = [
    {
      width: 140,
      title: '项目名称',
      ellipsis: true,
      dataIndex: 'projectName',
    },
    {
      width: 120,
      align: 'center',
      title: '所属中心',
      dataIndex: 'centerName',
    },
    {
      width: 120,
      align: 'center',
      title: '采集人',
      dataIndex: 'createUser',
    },
    {
      width: 120,
      align: 'center',
      title: `${targetName}编号`,
      dataIndex: 'subjectCode',
    },
    {
      width: 130,
      align: 'center',
      title: '所在访视',
      dataIndex: 'visitName',
    },
    {
      width: 120,
      align: 'center',
      title: '表单名称',
      dataIndex: 'formName',
    },
    {
      width: 120,
      align: 'center',
      title: '页面编号',
      dataIndex: 'qid',
      render: (value)=> value || '-'
    },
    {
      width: 130,
      align: 'center',
      title: '表单是否补充录入',
      dataIndex: 'enter',
      render: (value: number)=> ['未完成', '已完成', '无需补充录入'][value] || '-',
    },
    {
      width: 100,
      title: '表单是否锁定',
      align: 'center',
      dataIndex: 'isLock',
      render: (value: number)=> value === 1 ? '是' : '否'
    },
    {
      width: 160,
      title: '质疑字段',
      dataIndex: 'label',
      render: (value)=> value || '-'
    },
    {
      width: 160,
      title: '质疑文本',
      dataIndex: 'questionText',
      render: (value)=> value || '-'
    },
    {
      width: 170,
      title: '质疑发起者信息',
      dataIndex: 'questionUser',
      render: (value: Value)=> <Cells label='发起' value={value}/>
    },
    {
      width: 160,
      title: '质疑回复',
      dataIndex: 'content',
      render: (value)=> value || '-'
    },
    {
      width: 170,
      title: '质疑回复者信息',
      dataIndex: 'replierUser',
      render: (value: Value)=> <Cells label='回复' value={value}/>
    },
    {
      width: 170,
      title: '质疑关闭',
      dataIndex: 'closeUser',
      render: (value: Value)=> <Cells label='关闭' value={value}/>
    },
    {
      fixed: 'right',
      width: 100,
      title: '状态',
      dataIndex: 'questionStatus',
      render: (value: QuestionStatus)=> {
        return StatusEnum[value]
      }
    },
    {
      fixed: 'right',
      width: 100,
      title: '操作',
      key: 'action',
      render: (_: any, record: DataType, index: number) => (
        <Button onClick={ ()=> {
          setVisible(true)
          setOptions(makeOptions(index))
        }} type="primary" size="small">查看详情</Button>
      ),
    },
  ];
  //#endregion

  const fetchData = async (params: Params, page = 1)=> {
    setLoading(true)
    const [a, b] = await fetcChallList(page, pageSize, params)
    setLoading(false)
    setPageNum(page)
    setTotal(a)
    setDataSource(b)
  }

  const onSearch = (params: Partial<Params>)=> {
    conditionRef.current = {
      ...conditionRef.current,
      ...params,
    }
    fetchData(conditionRef.current)
  }

  // 当图片审核状态变更后及时刷新列表数据
  const refreshControl = (needReload=false)=> {
    setVisible(false)
    if(needReload) {
      fetchData(conditionRef.current)
    }
  }

  return (
    <>
    <div className={modStyl.wrapper}>
      <Condition onSearch={onSearch}/>
      <div className={modStyl.tableWrapper}>
        <Table
          loading={loading}
          size="middle"
          rowKey="id"
          scroll={{y: window.innerHeight - 350}}
          columns={columns}
          dataSource={dataSource}
          locale={{emptyText: <Empty description="暂无数据"/>}}
          pagination={{
            onChange: (page: number)=> fetchData(conditionRef.current, page),
            total: total,
            current: pageNum,
            pageSize: pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false,
            showQuickJumper: false,
            showTotal: total=> `共${total}条，每页${pageSize}条`,
            position: ["bottomRight"]}}/>
      </div>
    </div>
    {
      visible && (
        <DetailModal onClose={refreshControl} options={options}/>
      )
    }
    </>
  )
}

export default Chall