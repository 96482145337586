/**
 * 活动报名
 */
 import { requestPostFileRes, requestPost, requestGet, requestPostRes, requestGetRes } from '../EdcService';
 import axios from 'axios'
 const urlPrefix = "/dct/data";

// 获取项目code
export const GetDctProjectUser = (params) => {
    const url = `${urlPrefix}/project/user`;
    return requestPostRes(url, params)
};

 // 数据总览
export const GetDctDataOverview = (params) => {
    const url = `${urlPrefix}/summary/statistics`;
    return requestPostRes(url, params)
};
 // 运营监控
 export const GetDctMonitor = (params) => {
    const url = `${urlPrefix}/operation/monitoring`
    return requestPostRes(url, params)
};
// 全部监控-模态框
export const GetDctMonitorModal = (params) => {
    const url = `${urlPrefix}/task/status`
    return requestPostRes(url, params)
};
// 待办任务
export const GetDctTasks = (params) => {
    const url = `${urlPrefix}/pending/task`
    return requestPostRes(url, params)
};
// 助理统计
export const GetDctAssistantStatistics = (params) => {
    const url = `${urlPrefix}/assistant/statistics`
    return requestPostRes(url, params)
};

/**
 * 图片审核
 */
// 统计
 export const GetDctImageStatistics = (params) => {
    const url = `dct/imageTask/statistics`
    return requestPostRes(url, params)
};
// 开始审核/指派任务
export const GetDctAssignTask = (params) => {
    const url = `dct/taskDistribute/image/task`
    return requestPostRes(url, params)
};
// 图片审核
export const GetDctApprovalOperation = (params) => {
    const url = `/dct/imageTask/approvalOperation`
    return requestPostRes(url, params)
};
// 图片审核撤销
export const RevDctApprovalImage = (data)=> {
    return requestPostRes('/dct/imageTask/reset', data)
}

// 取消通过 
export const CancelDctApprovalOperation = (params) => {
    const url = `/dct/imageTask/approvalOperation/cancel`
    return requestPostRes(url, params)
};

/**
 * 录入
 */
// 统计
export const GetDctInputStatistics = (params) => {
    const url = `dct/inputTask/statistics`
    return requestPostRes(url, params)
};
// 任务指派 
export const GetDctAssignInputTask = (params) => {
    const url = `dct/taskDistribute/input/task`
    return requestPostRes(url, params)
};
// 放弃任务
export const GiveUpDctDiscard = (params) => {
    const url = `dct/inputTask/discard`
    return requestPostRes(url, params)
};
// 图片不完整
export const GetDctIncomplete = (params) => {
    const url = `dct/inputTask/incomplete`
    return requestPostRes(url, params)
};
// 提交
export const SubmitInputTask = (params) => {
    const url = `dct/inputTask/submit`
    return requestPostRes(url, params)
};

// 获取表单信息
// control 控制未脱敏的图片显示特定图标
export const GetFormDataById = (params, control) => {
    const { id, dataTenantCode } = params;
    const queries = {
        dataTenantCode,
    }
    if(control) {
        queries.show = 1;
    }
    return requestGetRes(`/data/form/${params.id}`, queries)
};
// 保存表单信息
export const saveFormData = (params) => {
    const url = `/data/data?dataTenantCode=${params.dataTenantCode}`;
    return requestPostRes(url, params)
};

/**
 * 质疑管理 
 */
// 回复状态
export const GetReplyStatus = (params) => {
    const url = `dct/project/question/status`;
    return requestGetRes(url, params)
};
// 列表
 export const GetQueryList = (params) => {
    const url = `dct/project/page/cra/list`;
    return requestPostRes(url, params)
};
// 列表详情
export const GetQueryListDetails = (params) => {
    const url = `dct/project/question/detail`;
    return requestPostRes(url, params)
    
};

// 回复质疑 
export const ReplyImageQuestion = (params) => {
    const url = `dct/imageTask/imageQuestion/reply`;
    return requestPostRes(url, params)
};

// 关闭质疑 
export const CloseImageQuestion = (params) => {
    const url = `dct/imageTask/imageQuestion/close`;
    return requestPostRes(url, params)
};

/**
 * 项目报表 
 */
// 列表
 export const GetDCTProjectReport = (params) => {
    const url = `dct/projectReport/pm/list`;
    return requestPostRes(url, params)
};

// 详情-汇总
export const GetDetailsummary = (params) => {
    const url = `dct/projectReport/pm/detail/summary`;
    return requestPostRes(url, params)
};

// 详情-用户
export const GetDetailUser = (params) => {
    const url = `dct/projectReport/pm/detail/user/page`;
    return requestPostRes(url, params)
};

// 下载 
export const DownloadReportDetail = (params) => {
    const url = `dct/projectReport/project/report/download`;
    return requestPostRes(url, params)
};

// 重新生成 
export const RebuildReport = (params) => {
    const url = `dct/projectReport/pm/generate/report/code`;
    return requestPostRes(url, params)
};

// 项目枚举 
export const GetProjectEnume = (params) => {
    const url = `dct/project/list`;
    return requestGet(url, params)
};

// 项目中心列表 
export const GetProjectCenter= (params) => {
    const url = `/project/center/projectCenter/${params.projectCode}`;
    return requestPostRes(url, params)
};

// 图片不完整枚举 
export const GetFormInfo = (params) => {
    const url = `dct/inputTask/get/form/info`;
    return requestPostRes(url, params)
};


/**
 * 划词排序
 * @param {} params
 */
 export const GetDataOcrSort = (params) => {
    const url = `/ocr/get/ocrField`;
    return requestPostRes(url,params)
};

/**
 * 重置密码
 */
 export const GetChangePassWord = (params) => {
    const url = `/plat/user/pass/change`;
    return requestPostRes(url,params)
};

/**
 * 查询受试者审核列表
 */
export const GetAuditList = (params) => {
    const url = `/data/subject/audit/list`;
    return requestPostRes(url,params)
};

/**
 * 结算审核导出数据
 * @param {*} data 
 */
export const downLoadAuditList = (data) => {
    const url = `data/subject/audit/downLoadAuditList`;
    return new Promise((resolve, reject) => {
        axios.request({
            url,
            method: "post",
            data,
            responseType: 'blob'
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });

}

/**
 * 结算审核导入模板
 * @param {} params 
 * @returns 
 */
export const GetAccountTemplate = (params) => {
    const url = `/data/subject/getFileUrl`;
    return new Promise((resolve, reject) => {
        axios.request({
            url: url,
            method: "get",
            responseType: 'blob'
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
    
}
/**
 * 
 * @returns 
 * 结算审核导入文件
 * @param {} params 
 * @returns 
 */

export const importAccountExcel = (params) =>{
    console.log(params)
    const url = `/data/subject/import`;
    return requestPostFileRes(url, params)
}

/**
 * 结算审核-列表
 */
export const getAccountList = (params) => {
    const url = `/data/subject/audit/getFormlist`;
    return requestPostRes(url,params)
};
/**
 * 结算审核-通过
 * @param {*} params 
 * @returns 
 */
export const accountAuditPass = (params) => {
    const url = `/data/subject/audit/formPass`;
    return requestPostRes(url,params)
};
/**
 * 结算审核-拒绝
 * @param {*} params 
 * @returns 
 */
export const accountAuditReject = (params) => {
    const url = `/data/subject/audit/formReject`;
    return requestPostRes(url,params)
};

/**
 * 结算审核-撤销
 */
export const accountAuditRollback = (params) => {
    const url = `/data/subject/audit/formRollback`;
    return requestPostRes(url, params)
};

/**
 * 结算审核-编辑备注
 */
export const accountEditRemark = (params) => {
    const url = `/data/subject/audit/editFormRemark`;
    return requestPostRes(url, params)
};


/**
 * 审核拒绝原因枚举
 */
 export const GetAuditRejectReasons = (params) => {
    const url = `/data/subject/audit/reject/reasons`;
    return requestGetRes(url, params)
};

/**
 * 拒绝原因信息
 */
 export const GetAuditInfo = (params) => {
    const url = `/data/subject/audit/info`;
    return requestGetRes(url, params)
};


/**
 * 审核通过
 */
 export const GetAuditPass = (params) => {
    const url = `/data/subject/audit/pass`;
    return requestPostRes(url,params)
};

/**
 * 审核拒绝
 */
 export const GetAuditReject = (params) => {
    const url = `/data/subject/audit/reject`;
    return requestPostRes(url,params)
};

/**
 * 审核失败重新提交审核
 */
 export const AuditResubmit = (params) => {
    const url = `/data/subject/audit/resubmit/${params.code}`;
    return requestPostRes(url,params)
};

/**
 * 立即提交审核
 */
 export const submitAudit = (params) => {
    const url = `/data/subject/audit/submit/${params.code}`;
    return requestPostRes(url,params)
};

/**
 * 获取项目和租户联动下拉框数据
 */
 export const GetTenantSelects = (params) => {
    const url = `/project/tenant/selects`;
    return requestGetRes(url,params)
};

/**
 * 审核撤销
 */
 export const GetAuditRollback = (params) => {
    const url = `/data/subject/audit/rollback`;
    return requestPostRes(url, params)
};

/**
 * 报名统计
 */
 export const GetRegistrationStatistic = (params) => {
    const url = `/plat/apply/registrationStatistic`;
    return requestPostRes(url, params)
};

/**
 * 病例统计
 */
 export const GetCaseStatistic = (params) => {
    const url = `/data/caseStatistic`;
    return requestPostRes(url, params)
};

/**
 * 病例审核-编辑备注
 */
 export const GetAuditEditRemark = (params) => {
    const url = `/data/subject/audit/editRemark`;
    return requestPostRes(url, params)
};

/**
 * 可选表单列表
 */
 export const GetAuditFormSelectList = (params) => {
    const url = `/data/subject/formSelectList`;
    return requestGetRes(url,params)
};

// 获取项目列表
export const getTaskDistributeProjectList = ()=> {
    return requestGetRes('dct/taskDistribute/projectCode/list')
}

// 获取项目列表
export const getTaskDistributeCenterList = ()=> {
    return requestGetRes('dct/taskDistribute/center/list')
}

// 获取审核图片列表
export const getTaskDistributeImageList = (params)=> {
    return requestPostRes('dct/taskDistribute/image/list', params)
}
// 获取审核图片列表
export const getTaskDistributeInputList = (params)=> {
    return requestPostRes('dct/taskDistribute/input/list', params)
}
// 获取审核数据详情
export const getTaskDistributeInputDetail = (formId)=> {
    return requestGetRes(`dct/taskDistribute/input/detail?formId=${formId}`)
}
// 获取图片审核详情
export const getTaskDistributeImageDetail = (formId)=> {
    return requestGetRes(`dct/taskDistribute/image/detail?formId=${formId}`)
}

// 获取单个受试者图片审核列表
export const getTaskDistributeImageSubjectList = (subjectCode, status)=> {
    return requestGetRes(`dct/taskDistribute/subjectCode/image/list?subjectCode=${subjectCode}&approveStatus=${status}`)
}

// 获取单个受试者数据采集审核列表
export const getTaskDistributeInputSubjectList = (subjectCode, status)=> {
    return requestGetRes(`dct/taskDistribute/subjectCode/input/list?subjectCode=${subjectCode}&approveStatus=${status}`)
}