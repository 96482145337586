import { MessageInstance } from "antd/lib/message";
import { GetCaseSts } from "../../../../services/data/DataService";
import storageX, { Engine } from "../../../../util/storageX";
import utils from "../../utils/utils";

type AliOptionsResult = {
  accessKeyId: string;
  accessKeySecret: string;
  bucket: string;
  pathPre: string;
  region: string;
  stsToken: string;
}

type AliOptionsResponse = {
  code: number;
  message: string;
  data: AliOptionsResult
}

/*识别的数据结构*/
export type Field = {
  deCode?: string;
  dataCode?: string;
  label?: string;
  type?: string;
  children?: Field[];
  [key: string]: any; // 允许其他任意属性
}

export type DataAction = 'DataActionUpdate' | 'DataActionAnnotation' | 'DataActionRemark' | 'DataActionAnswer'

export type GlobalValueItem = {
  visitName?: string;
  // 标记OCR识别化验单结果 range 表示范围 unit 表示单位
  reference?: 'range' | 'unit' | null;
  dataCode: string;
  deCode: string;
  value: string | string[] | object;
}

export type ValueItem = {
  dataCode: string;
  deCode: string;
  inputState?: string; // Runing
  projectCode?: string;
  question?: boolean;
  subjectCode?: string; // GYVR100005
  value: string | string[];
}

export type FormData = {
  imageUrl: string;
  [key: string]: unknown;
}

export type ProjectData = {
  // 表单ID
  formId: number;
  subjectCode: string;
  subjectName: string | null;
  subjectIcon: string;
  joinDate: string;
  createUser: string;
  projectName: string;
  projectCode: string;
  projectType: string;
  groupCode: string;
  groupName: string | null;
  subjectState: string | null;
  auditState: string | null;
  signState: string | null;
  commentState: string | null;
  progress: ('Wait' | 'Complete')[];
  singleSignState: string | null;
  centerName: string;
  tasks: {
    "type": string;
    "state": string;
    "message": string;
    "number": number;
  }[];  
}

export type UserData = {
  token: string;
  userName: string;
  userCode: string;
  tenantName: string;
  tenantCode: string;
  roleCode: string;
  roleCodes: string[];
}

export type Properties = {
  // 化验单参考值分隔符 默认 ['~', '--', '-', '－', '～']
  delimiters?: string[];
  // foo/bar?keyword=${keyword}&name=${name}&id=1900
  server: string;
  query: {
    [key: string]: string | number | boolean;
  };
  payload: {
    [key: string]: string | number | boolean;
  };
}

export type OCRAutoCompoleteProps = {
  autoOcr: 0,
  formId: number;
  formIsRead: boolean;
  isUpdate?: boolean;
  savedValues: ValueItem[];
  dataActions: DataAction[];
  updateFun?: ()=> void;
  formConfig: any[];
  data: {
    col: string;
    isAdd?: boolean;
    dataCode: string;
    deCode: string;
    label: string;
    type: string;
    sort?: any;
    properties: Properties;
    validationRules: any[];
    logicOperations: any;
    remind: any;
    reminds: any[];
  };
}

// 识别结果
export type ScanResult = {
  code: number;
  message: string;
  success: boolean;
  data: any[]
}

// 君实 获取OCRRules化验单识别规则字段 optional
export type PickFieldsOptionalMap = {
  [key: string]: {
    type: string;
    inputType?: 'inputrange';
    other?: {
      key: string;
      type: 'input';
    },
    optional?: {
      [key: string]: string
    },
    defaultValue?: any
  }
}

// 从作用域将字段转为关联
export type PickFieldMap = {
  [key: string]: {
    type: string;
    deCode: string;
    dataCode: string;
    [name: string]: number|string|boolean;
  }
}

// 获取当前组件的作用域 当前组件在list中scope为list 否则为整个表单
export const searchFieldScope = (dataCode: string, formConfig: any[])=> {
  let scope: any[] = []
  let listScope = false;
  let find = false;
  let listLevel = 0;
  const callback = (list: any[], level: number)=> {
    for(const item of list) {
      // 找到了直接退出
      if(find) {
        return
      }

      // 当前出现跨层时重置列表作用域标识
      if(level < listLevel) {
        listScope = false;
      }

      // 标记列表作用域
      if(item.type === 'list') {
        listScope = true;
        listLevel = level;
        scope = [item]
      }

      if(Array.isArray(item.children)) {
        callback(item.children, level+1)
      } else {
        if(item.type === 'ocr-autofill' && item.dataCode === dataCode) {
          find = true;
          // 仅列表当前项为作用域
          if(listScope) {
            scope = list
          }
          return;
        }
      }
    }
  }
  callback(formConfig, 0)
  return listScope ? scope : formConfig
}

// 获取表单配置中带有OcrRules配置字段
export const pickOcrFillFields = (scopeBody: any[])=> {
  const fieldMap: PickFieldsOptionalMap = {}
  const callback = (list: any[])=> {
    list.forEach(({ dataCode, OcrRules, optional, children, inputType, type, other, defaultValue })=> {
      if(children) {
        callback(children)
      } else {
        if(Array.isArray(OcrRules)) {
          fieldMap[dataCode] = {
            type,
            inputType,
            optional,
            other,
            defaultValue
          }
        }
      }
    })
  }
  callback(scopeBody)
  return fieldMap
}


// 带有OcrRules配置字段对应清空
export const clearOcrFillFields = (scopeBody: any[])=> {
  const fieldArray : ValueItem[] = [] 
  const callback = (list: any[])=> {
    list.forEach(({ dataCode, OcrRules, deCode, children})=> {
      if(children) {
        callback(children)
      } else {
        if(Array.isArray(OcrRules)) {
          fieldArray.push({
            dataCode,
            deCode,
            value: ''
          })
        }
      }
    })
  }
  callback(scopeBody)
  return fieldArray
}
// 获取表单配置的所有字段
export const pickFields = (scopeBody: any[])=> {
  const fieldMap: PickFieldMap = {}
  const callback = (list: any[])=> {
    list.forEach(({children, ...item})=> {
      if(children) {
        callback(children)
      } else {
        fieldMap[item.dataCode] = item
      }
    })
  }
  callback(scopeBody)
  return fieldMap
}

export const getUserCache = ()=> {
  return storageX().user()
}

export const getProjectCache = (formId: number)=> {
  const proj = storageX(Engine.Session).json().get('photoEditItem', {}) as ProjectData
  return {
    ...proj,
    formId,
  }
}

/**
 * values中的list数据
 * {"xxb": "2", "isblood#2": "2", "hxbjsa#2": "1"} ==> {hxbjsa: "2", hxbjsa:"2"}
 * @param shareData 
 * @param dataCode 当前OCRAutofill字段的dataCode
 * @returns 
 */
export const parseListTypeData = (data: FormData, scopeBody: PickFieldMap)=> {
  const keys = Object.keys(scopeBody)
  // 分离list类型的数据 dateCode#index
  type Mapping = {
    [key: string]: unknown
  }
  const pickData: Mapping = {}

  // 挑出当前作用域可用字段（当前OCRAutofile组件在list中时为当前list项，否则是整个form）
  for(const name in data) {
    // 优先全量匹配
    if(keys.includes(name)) {
      const [dataCode] = name.split('#')
      pickData[dataCode] = data[name]
    } else {
      // 数据key没有追加list索引
      if(name.indexOf('#') === -1) {
        // 没有匹配到时遍历匹配
        for(const key of keys) {
          if(name.startsWith(`${key}#`)) {
            pickData[name] = data[name]
          }
        }
      }
    }
  }
  return pickData
}

/**
 * 用于解析OCR识别接口 合并解析模板变量 例：/path/to?id=${id}&keyword=${keyword}&title=${title}
 * @param content 
 * @param shareData 
 * @returns 
 */
const parseTemplateLiterals = (content: string, data: FormData, userData: UserData, projectData: ProjectData) => {

  const re = new RegExp(/(\$\{(\w+)\})/, 'g');

  let defaults = {} as FormData
  let match: RegExpExecArray | null;
  while((match = re.exec(content)) !== null) {
    defaults[match[2]] = '';
  }

  const keys = Object.keys({...defaults, ...data})
  const values = Object.values({...defaults, ...data})

  
  // eslint-disable-next-line no-new-func, prefer-template
  return new Function(...keys, 'project', 'user', `
    try {
      return \`${content}\`;
    } catch(e) {
      console.log('template.literals', e)
      // throw new Error('parsed template fail.');
    }
  `)(...values, projectData, userData)
}

/**
 * 用于解析OCR识别接口 queryParams,payload 参数值
 * @param value 
 * @param shareData 
 * @returns 
 */
export const parseParamsValue = (value: string, data: FormData, userData: UserData, projectData: ProjectData): string => {
  // 基础数据直接使用
  if(['number', 'boolean'].includes(typeof value)) {
    return value;
  }
  // ! 表示普通字符串不需要解析
  if(value.startsWith('!')) {
    return value.substring(1)
  }

  const [nameValue, defaultValue] = value.split('|')
  const startWithAt = nameValue.startsWith('@')
  const startWithDollar = nameValue.startsWith('$')
  if(startWithAt || startWithDollar) {
    const pureName = nameValue.substring(1);
    // 解析项目信息
    if(startWithAt) {
      type ProjectKeys = keyof ProjectData;
      return (projectData[pureName as ProjectKeys] as string) ?? (defaultValue ?? '');
    }

    // 解析登录者信息
    if(startWithDollar) {
      type UserKeys = keyof UserData;
      return (userData[pureName as UserKeys] as string) ?? (defaultValue ?? '');
    }
  }


  // 从values中查询
  return (data[nameValue] as string) ?? (defaultValue ?? '');
}

/**
 * 合并OCR识别接口参数
 * @param props 
 * @param imageUrl 
 * @param data 
 * @param projectData 
 * @returns 
 */
export const mergeServerUrl = (props: Properties, data: FormData, userData: UserData, projectData: ProjectData)=> {
  const server = parseTemplateLiterals(props.server, data, userData, projectData)

  // 没有配置queryParams直接跳过
  if(!props.query || Object.keys(props.query).length === 0) {
    return server
  }

  // 解析地址中的参数
  const canParse = URL.canParse(server)
  const url = new URL(server, canParse ? undefined : window.location.origin)

  // 将参数合并
  for(const name in props.query) {
    let value = props.query[name]
    if(typeof value === 'string') {
      value = parseParamsValue(value, data, userData, projectData)
    }
    url.searchParams.set(name, String(value))
  }

  return canParse ? url.toString() : `${url.pathname}${url.search}`
}

/**
 * 组装上传需要的路径
 * @param pathPrefix OSS公共前缀
 * @param file 上传的文件
 * @returns 
 */
export const makeUploadName = (pathPrefix: string, file: File)=> {
  const {tenantCode, userName } = storageX().user()

  if(!tenantCode || !userName) {
    return null;
  }

  // const now = new Date().getTime()
  const now = crypto.randomUUID().replaceAll('-', '')
  const ext = file.name.split('.').pop()
  const part = [tenantCode, userName, `${now}.${ext}`].join('/')

  return pathPrefix + part;
}

/**
 * 组装上传图片需要的头信息
 * @param formId 表单ID
 * @returns 
 */
export const makeUploadHeaders = (formId: number)=> {
  const { projectCode, subjectCode } = getProjectCache(formId)
  return utils.getHeaders({
    "x-formId-code": formId,
    // @ts-ignore 首页进来时点击条目的projectCode
    "x-projectCode-code": projectCode,
    // @ts-ignore 首页进来时点击条目的subjectCode
    "x-subjectCode-code": encodeURIComponent(subjectCode)
  }) ?? {};
}

type AliOSSUploadOptions = [string, Omit<AliOptionsResult, 'pathPre'>];

/**
 * 组装AliOSS需要的参数
 * @param error 
 * @returns 
 */
export const makeUploadOptions = async (error: MessageInstance['error']): Promise<null | AliOSSUploadOptions>=> {
  const key = '--alioss-options';

  const x = storageX(Engine.Session);
  
  const cache = x.json().get(key);
  if(cache) {
    return cache as AliOSSUploadOptions;
  }

  const {code, message, data} : AliOptionsResponse = await GetCaseSts()

  if(code !== 0) {
    error(message)
    return null;
  }

  const {pathPre, ...options} = data

  const prefix = pathPre.split(',').find(path=> path.includes('image'))
  if(!prefix) {
    error('参数有误')
    return null;
  }

  x.expire(2000).set(key, [prefix, options])

  return [prefix, options]
}

/**
 * 上传图片成功后根据OSS响应数据生成缩略图
 * http://huimei-edc.oss-cn-shanghai.aliyuncs.com/edc/data/image-test/TENANT-0667/QLCACRC/1720665724548.png?uploadId=6C519DF47CDE402D9F21CB57D077E742
 * @param url 上传响应 
 * @param width 缩略图尺寸
 * @returns 
 */
export const makeThumb = (url: string, width=100)=> {
  const parsed = new URL(url.split('?').shift() as string)
  const sourceUrl = parsed.toString()
  const match = /\/([a-f0-9]{32})\.(jpg|jpeg|png)$/g.exec(parsed.pathname)
  let uuid = ''
  if(Array.isArray(match)) {
    uuid = match[1]
  }

  return {
    name: parsed.pathname,
    url: sourceUrl,
    uid: uuid,
    thumbUrl: `${sourceUrl}?x-oss-process=image/resize,w_${width},limit_0,m_fill&_=${Date.now()}`,
  }
}

// ocr识别的作用域数据，同级的chirdren数据
export const  filterChildrenByDataCode = (fields: Field[], targetDataCode: string): Field[] | [] => {
  let parentChildren: Field[] | [] = [];

  const traverse = (items: Field[], parent: Field | null) => {
      for (const item of items) {
          if (item.dataCode === targetDataCode) {
              // 找到目标对象后，返回其父级的 children
              if (parent && parent.children) {
                  parentChildren = parent.children;
              }
              return;
          }

          if (item.children && item.children.length > 0) {
              // 递归查找子元素
              traverse(item.children, item);
          }
      }
  };

  traverse(fields, null);

  return parentChildren;
};