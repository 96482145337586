import React, { ReactElement, useEffect, useState } from "react";
import { Cursor, Download, Focus, MarkPen, Question, Rect, Reset } from "../icons";

import modStyl from './index.module.scss'
import { ActionEnum } from "../utils";
import Spin from "../spin";

const items = [
  {
    key: 'move',
    label: "移动",
    type: ActionEnum.Move,
    render: (filled: boolean)=> <Cursor filled={filled}/>
  },
  {
    key: 'pencil',
    label: "画笔",
    type: ActionEnum.Pencil,
    render: (filled: boolean)=> <MarkPen filled={filled}/>
  },
  {
    key: 'rect',
    label: "矩形",
    type: ActionEnum.Rect,
    render: (filled: boolean)=> <Rect filled={filled}/>
  },
  {
    key: 'reset',
    label: "重置",
    type: ActionEnum.Reset,
    render: (filled: boolean)=> <Reset filled={filled}/>
  },
  {
    key: 'focus',
    label: "原点",
    type: ActionEnum.Focus,
    render: (filled: boolean)=> <Focus filled={filled}/>
  },
  {
    key: 'divider',
    label: '',
    type: ActionEnum.Divider
  },
  {
    key: 'question',
    label: '帮助',
    type: ActionEnum.Question,
    render: (filled: boolean)=> <Question filled={filled}/>
  },
  {
    key: 'download',
    label: "下载",
    type: ActionEnum.Download,
    render: ()=> <Download/>
  },
];

interface Props {
  value: ActionEnum
  onCommand(name: ActionEnum): void;
}

const Toolbar: React.FC<Props> = ({ onCommand, value }) => {
  const [active, setActive] = useState(value);
  // 下载状态
  const [pending, setPending] = useState(false)

  const handleClick = (name: ActionEnum)=> {
    if ([ActionEnum.Move, ActionEnum.Pencil].includes(name)) {
      setActive(name)
    }
    if (ActionEnum.Download === name) {
      setPending(true)
      setTimeout(()=> setPending(false), 2000)
    }
    onCommand(name)
  }

  useEffect(()=> {
    setActive(value)
  }, [value])

  const takeChild = (key: string, label: string, type: ActionEnum, render?: (filled: boolean)=> ReactElement)=> {
    if (render) {
      const filled = active === type
      return (
        <li key={key}>
          <button onClick={()=> handleClick(type)} className={filled ? modStyl.active : ''} type="button">
            {render(filled)}
            <span className={modStyl.tooltip}>{label}</span>
            {
              type === ActionEnum.Download && pending && (
                <Spin size={32} showText={false}/>
              )
            }
          </button>
        </li>
      )
    }
    return (<li key={key} className="divider"></li>)
  }

  return (
    <div className={modStyl.wrapper}>
      <ul>
        {
          items.map(({ key, label, type, render }) => takeChild(key, label, type, render))
        }
      </ul>
    </div>
  );
};

export default Toolbar;
