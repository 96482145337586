/**
 * 病例审核
 */
import React from 'react';
import './index.less';
import {
    Button, message as Message, Table,
    message, Modal, Checkbox, Input
} from "antd";
import 'moment/locale/zh-cn';
import {
    GetAuditList, GetAuditRejectReasons, GetAuditPass, GetAuditReject, GetAuditRollback, GetAuditEditRemark, GetAuditFormSelectList
} from "../../../../../services/dct/DctService";
import {
    GetSubjectDetail, GetFormsById, GetAgreement
} from "../../../../../services/data/DataService";
import Form from '../../../../crf_form/Index';
import ModalLabor from '../../../../../components/project/detail/setting/modalLabor/modalLabor';
import SubjectModal from "../../../visit/components/SubjectModal";

const CheckboxGroup = Checkbox.Group;

class CaseAuditList extends React.Component {

    constructor(props) {
        super(props);
        let tenantCode = '';
        this.state = {
            selectedRowKeys: [], // 勾选项
            dataList: [], // 列表集合
            columns: [
                {
                    title: '任务编号',
                    width: 160,
                    dataIndex: 'subjectCode',
                },
                {
                    title: '租户名称',
                    width: 160,
                    dataIndex: 'tenantName',
                },
                {
                    title: '姓名',
                    width: 160,
                    dataIndex: 'name',
                },
                {
                    title: '所属项目',
                    width: 200,
                    dataIndex: 'projectName',
                },
                {
                    title: '所属中心',
                    width: 200,
                    dataIndex: 'centerName',
                },
                {
                    title: '上传人',
                    width: 100,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.userName}</div>
                                <div className={'sub-title'}>{record.userCode}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '上传时间',
                    width: 180,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.uploadTime ? record.uploadTime.replace('T', ' ') : ''}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核状态',
                    width: 120,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.auditState == 1 ? '待审核' : record.auditState == 2 ? '通过' : '未通过'}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '结算状态',
                    width: 120,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>
                                    {record.isClosed == 1 ? '已结算' : '未结算'}
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核人',
                    width: 120,
                    dataIndex: 'handleUser',
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.handleUser}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '审核时间',
                    width: 180,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.handleTime ? record.handleTime.replace('T', ' ') : ''}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '更新时间',
                    width: 180,
                    render: (text, record, index) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.updateTime ? record.updateTime.replace('T', ' ') : ''}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '拒绝原因',
                    width: 200,
                    render: (text, record) => {
                        return (
                            <div className={'con_div'}>
                                <div className={'title'}>{record.rejectReasons}</div>
                                <div className={'sub-title'}>{record.repeatRemark}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '备注',
                    width: 200,
                    dataIndex: 'remark'
                },
                {
                    title: '操作',
                    width: !["DC_DM","DC_AM"].includes(sessionStorage.getItem('roleCode')) ? 450 : 250,
                    fixed: 'right',
                    render: (text, record) =>
                        
                        <div
                            onClick={eve => {
                                eve.stopPropagation();
                            }}
                            className={'partPop'}
                        // hidden={record.status == 0}
                        >
                            <Button type="link" onClick={() => this.toDetail(record)} >
                                查看
                            </Button>
                            {record.isHaveAgreement && <Button type="link" onClick={() => this.viewAgreement(record)} >
                                查看协议
                            </Button>}
                            {
                             !["DC_DM","DC_AM"].includes(sessionStorage.getItem('roleCode')) && 
                                <>
                                <Button type="link" onClick={() => this.editRemark(record)} >
                                编辑备注
                                </Button>
                                <Button type="link" className={(record.auditState == 2) ? 'already-audit' : ''} onClick={() => this.toAgree(record)} >
                                {/* 通过 */}
                                {(record.auditState == 1 || record.auditState == 3) ? '通过' : '已通过'}
                                </Button>
                                <Button type="link" className={`audit-reject-say-no ${(record.auditState == 3 || record.auditState == 2) ? 'already-audit' : ''}`} onClick={() => this.toReject(record)} >
                                {/* 拒绝 */}
                                {(record.auditState == 1 || record.auditState == 2) ? '拒绝' : '已拒绝'}
                                </Button>
                                {(record.auditState == 3 || record.auditState == 2) && <Button type="link" className={`audit-reject-say-no`} onClick={() => this.toRevoke(record, 1)} >
                                撤销
                                </Button>}
                                {(record.auditState == 3 || record.auditState == 2) && <Button type="link" className={`audit-reject-say-no`} onClick={() => this.toRevoke(record, 2)} >
                                部分撤销
                                </Button>}
                               </>
                            }
                          
                        </div>
                },
            ],// 表格 行 配置
            page: 1,//当前页码数
            pageSize: 10,//页码大小
            pagination: { // 分页组件元素对象信息
                onChange: (pageNo) => {
                    this.getPageData(pageNo);
                },
                page: 1,
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: false,
            },
            clickedRow: {}, // 点击的条目
            dialogLoading: false,
            dialogVisible: false,
            filterVal: {
                auditState: '',
                tenantCode: tenantCode,
                projectCode: ''
            }, // 筛选条件
            rejectReasonOriginArr: [], // 拒绝原始原因保存
            rejectReasonArr: [], // 拒绝原因
            rejectCheckedValues: [], // 拒绝选中原因
            rejectCheckedExtra: '',
            formModalVisible: false,
            showExtraReason: false,
            allCount: 0,
            clickedTenantCode: '', // 当前点击的租户

            visibleLabor: false, //查看协议框modal
            laborAgreementContent: '', //协议内容
            crfParams: null, // crf表单初始化

            remarkVisible: false, // 备注模态框
            remarkValues: '', // 备注信息
            subjectId: null, // 受试者id
            repeatSubjectCodeList: [], // 重复受试者code列表

            cancleVisible: false, // 部分撤销
            checkedList: [], // 撤销列表
            indeterminate: false,
            checkAll: false, // 是否全选
            plainOptions: [], // 撤销选项
            subjectCode: null
        }
    }

    formRef = React.createRef();

    componentDidMount() {
       
        this.getPageData();
        this.toGetAuditRejectResaons();
    }

    // 编辑备注
    editRemark = (record) => {
        this.setState({
            remarkVisible: true,
            subjectId: record.subjectId,
            remarkValues: record.remark,
        })
    }

    // 撤销
    toRevoke = (record, type) => {
        this.setState({
            subjectCode: record.subjectCode,
        })

        // 部分撤销
        if (type == 2 && (record.auditState == 1 || record.auditState == 2)) {
            this.setState({
                cancleVisible: true,
                checkedList: [],
                indeterminate: false,
                checkAll: false
            }, () => {
                this.cancelFormlist(record.subjectCode)
            })
        }
        else {
            this.getAuditRollback(record.subjectCode, [], 1)
        }
    }

    // 获取撤销表单列表
    cancelFormlist = (subjectCode) => {
        let params = {
            subjectCode
        }
        GetAuditFormSelectList(params).then(res => {
            if (res.code == 0) {
                if (!res.data || res.data.length == 0) {
                    return
                }

                let options = [];
                res.data.map(item => {
                    let obj = {
                        label: item.formName,
                        value: item.formId
                    }
                    options.push(obj)
                })
                this.setState({
                    plainOptions: options
                })
            }
        })
    }

    showModal = () => {
        this.setState({
            dialogVisible: true,
            rejectReasonArr: this.state.rejectReasonOriginArr
        });
    };

    handleOk = () => {
        this.setState({ dialogLoading: true });
        let { clickedRow, rejectCheckedValues, rejectCheckedExtra } = this.state;
        if (rejectCheckedValues && rejectCheckedValues.length == 0) {
            message.info('请选择拒绝原因');
            this.setState({ dialogLoading: false });
            return;
        }
        let checkedVal = rejectCheckedValues;
        checkedVal = (checkedVal || []).filter(val => {
            if (val === '其他') {
                return false;
            } else {
                return true;
            }
        })
        let reasons = checkedVal.join(';');
        this.rejectSubmit(clickedRow.subjectCode ? [clickedRow.subjectCode] : [...this.state.selectedRowKeys], reasons, rejectCheckedExtra, () => {
            // 全部拒绝
            if (!clickedRow.subjectCode) {
                this.setState({
                    selectedRowKeys: []
                });
            }
        });
        this.getPageData();
    };

    rejectSubmit = (subjectCodes, reasons, remark = '', cb) => {
        this.toGetAuditReject({
            subjectCode: subjectCodes,
            reasons: reasons,
            remark: remark
        }).then(() => {
            this.setState({
                dialogLoading: false,
                dialogVisible: false,
                rejectCheckedValues: [],
                rejectReasonArr: [],
                rejectCheckedExtra: '',
            });
            message.success('拒绝成功');
            cb && cb();
            this.getPageData();
        })
    }

    rejectResononsChange = (checkedValues) => {
        let showExtraReason = false;
        showExtraReason = (checkedValues || []).some((val) => {
            if (val == '其他') {
                return true;
            }
        });
        this.setState({
            rejectCheckedValues: checkedValues,
            showExtraReason: showExtraReason
        })
    }

    handleCancel = () => {
        this.setState({ dialogVisible: false });
    };

    /**
     * 选中条目
     * @param {object} selectedRowKeys 
     */

    onSelectChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
        selectedRowKeys && this.props.updateCount(selectedRowKeys.length);
    }

    // 勾选通过
    selectAllPass = () => {
        let rows = this.state.selectedRowKeys;
        if (rows && rows.length === 0) {
            message.info('请勾选您要通过的项目');
            return;
        }
        this.toGetAuditPass({
            subjectCodes: rows
        }).then(() => {
            message.success('通过成功');
            this.setState({
                selectedRowKeys: []
            })
            this.getPageData();
        });
    }

    // 勾选拒绝
    selectAllReject = () => {
        let rows = this.state.selectedRowKeys;
        if (rows && rows.length === 0) {
            message.info('请勾选您要拒绝的项目');
            return;
        }
        this.showModal();

        // this.rejectSubmit(rows, '', () => {
        //     this.setState({
        //         selectedRowKeys: []
        //     })
        // });
    }

    /**
     * 获取拒绝原因 
     */
    toGetAuditRejectResaons = () => {
        GetAuditRejectReasons().then(res => {
            if (res.code == 0) {
                const msgArr = (res.data || []).map(val => val.text);
                this.setState({
                    rejectReasonOriginArr: msgArr
                });
            }
        })
    }
    /**
     * 审核通过
     */
    toGetAuditPass = (params = {}) => {
        return new Promise(resolve => {
            GetAuditPass({
                subjectCodes: params.subjectCodes || []
            }).then(res => {
                if (res.code == 0) {
                    resolve(res.data);
                }
            })
        });
    }
    /**
     * 审核拒绝
     */
    toGetAuditReject = (params = {}) => {
        return new Promise(resolve => {
            GetAuditReject({
                reasons: params.reasons || '',
                remark: params.remark || '',
                subjectCodes: params.subjectCode || []
            }).then(res => {
                if (res.code == 0) {
                    resolve();
                }
            })
        });
    }
    /**
     * 获取表单前置信息
     */
    toGetSubjectDetail = (subjectCode = '') => {
        return new Promise(resolve => {
            GetSubjectDetail(subjectCode).then(res => {
                if (res.code == 0) {
                    return resolve(res.data || {});
                }
            })
        });
    }
    /**
     *  获取表单前置条目信息
     */
    toGetFormsById = (id) => {
        return new Promise(resolve => {
            GetFormsById(id).then(res => {
                if (res.code == 0) {
                    // 给表单统一都加上访视名称
                    if(res.data){
                        (res.data?.forms || []).map(e=>{
                            e.visitName = res.data?.name
                        })
                    }
                    return resolve(res.data || {});
                }
            })
        });
    }
    /*
     * 获取列表数据
     */
    getPageData = async (currPage, currType, currstartDate, currendDate, filterVal = {
        subjectCode:'',
        createUser: '',
        auditState: '',
        tenantCode: '',
        projectCode: '',
        centerCode: '',
        isFilter: false
    }) => {
        this.setState(filterVal)
        let { page, pageSize } = this.state.pagination;
        const params = {
            page: currPage || page,
            pageSize: pageSize,
        }
        let _filterVal = this.state.filterVal;
        if (filterVal.isFilter) {
            _filterVal = filterVal;
            this.setState({
                filterVal: filterVal,
            })
        } else {
            _filterVal = this.state.filterVal;
        }
        if (_filterVal.createUser && _filterVal.createUser.length > 0) {
            params.createUser = _filterVal.createUser;
        }
        if (_filterVal.auditState) {
            params.auditState = parseInt(_filterVal.auditState);
        }
        if (_filterVal.tenantCode) {
            params.tenantCode = _filterVal.tenantCode;
        }
        if (_filterVal.projectCode) {
            params.projectList = [_filterVal.projectCode];
        }
        if (_filterVal.centerCode) {
            params.centerCode = _filterVal.centerCode;
        }
        if (_filterVal.subjectCode) {
            params.subjectCode = _filterVal.subjectCode;
        }
        const res = await GetAuditList(params);
        if (res && res.code == 0) {
            this.setState({
                allCount: res.data.total,
                dataList: [...res.data.rows],
                pagination: {
                    ...this.state.pagination,
                    page: res.data.page,
                    current: res.data.page,
                    pageSize: res.data.pageSize,
                    total: res.data.total,
                }
            })
        }
    }

    /**
     * 查看合同
     */
    viewAgreement = (data) => {
        let params = {
            subjectCode: data.subjectCode
        }

        GetAgreement(params).then(res => {
            if (res.code == 0) {
                this.setState({
                    laborAgreementContent: res.data,
                    visibleLabor: true
                })
            }
        })
    }

    /**
     * 
     */
    closeLaborModal = () => {
        this.setState({
            laborAgreementContent: '',
            visibleLabor: false
        })
    }

    /**
      * 详情
      */
    toDetail = (item) => {
        this.setState({
            subjectCode: item.subjectCode,
            formModalVisible: true
        })
    }

    /**
     * 通过
     */
    toAgree = (record) => {
        // 已通过
        if (record.auditState == 2) {
            return;
        }
        this.toGetAuditPass({
            subjectCodes: [record.subjectCode]
        }).then(() => {
            message.success('通过成功');
            this.getPageData();
        });
    }
    /**
     * 拒绝
     */
    toReject = (record) => {
        // 已通过或者已拒绝不能再次点击
        if (record.auditState == 2 || record.auditState == 3) {
            return;
        }
        this.setState({
            rejectReasonArr: [],
            rejectCheckedValues: [],
            clickedRow: record,
            rejectCheckedExtra: ''
        }, () => {
            let resoansArr = this.state.rejectReasonOriginArr;
            if (resoansArr && resoansArr.length > 0) {
                this.showModal();
            } else {
                this.rejectSubmit(this.state.clickedRow, '');
            }
        });
    }
    /**
     * 其他原因填写
     */
    extraReasonChange = (event) => {
        this.setState({
            rejectCheckedExtra: event.target.value
        });
    }
    /**
     * 当关闭表单触发
     */
    onCloseModal = () => {
        console.log(this.formRef);
        if (this.formRef) {
            this.formRef.current.closeModal();
        }
    }
    /**
     * 关闭弹框
     */
    closeFormModal = () => {
        this.setState({
            formModalVisible: false
        });
    }

    // 关闭修改备注模态框
    onCloseRemark = () => {
        this.setState({
            remarkVisible: false
        })
    }

    // 确认修改备注
    handleRemarkOk = () => {
        let { remarkValues, subjectId } = this.state;
        let params = {
            remark: remarkValues,
            subjectId
        }
        GetAuditEditRemark(params).then(res => {
            if (res.code == 0) {
                message.success('修改成功！')
                this.setState({
                    remarkVisible: false
                }, () => {
                    this.getPageData()
                })
            } else {
                message.success(res.message)
            }
        })
    }

    // 修改备注
    onChangeRemark = (e) => {
        this.setState({
            remarkValues: e.target.value
        })
    }

    // 部分撤销-取消
    onCloseCancel = () => {
        this.setState({
            cancleVisible: false,
        })
    }

    // 部分撤销-确认
    handleCancelOk = () => {
        let { subjectCode, checkedList } = this.state;
        this.getAuditRollback(subjectCode, checkedList, 2)
    }

    // 撤销操作
    getAuditRollback = (subjectCode, formIdList, cancelType) => {
        // 撤销类型cancelType：1-撤销 2-部分撤销
        let params = {
            subjectCode,
            formIdList,
            cancelType
        }
        GetAuditRollback(params).then(res => {
            if (res.code == 0) {
                message.success('撤销成功！');
                this.setState({
                    cancleVisible: false,
                    showExtraReason: false
                }, () => {
                    this.getPageData();
                })
            }
        })
    }

    // 全选
    onCheckAllChange = (e) => {
        let { plainOptions } = this.state;

        let options = [];
        if (plainOptions && plainOptions.length > 0) {
            plainOptions.map(item => {
                options.push(item.value)
            })
        }

        this.setState({
            checkedList: e.target.checked ? options : [],
            indeterminate: false,
            checkAll: e.target.checked
        })
    }

    onCheckedList = (list) => {
        this.setState({
            checkedList: list,
            indeterminate: !!list.length && list.length < this.state.plainOptions.length,
            checkAll: list.length === this.state.plainOptions.length
        })
    }

    render() {
        const currentRole = sessionStorage.getItem('roleCode');
        let { columns, dataList, pagination, selectedRowKeys, dialogVisible, dialogLoading,
            rejectReasonArr, clickedTenantCode, allCount, showExtraReason, rejectCheckedExtra, crfParams, remarkVisible, remarkValues,
            repeatSubjectCodeList, cancleVisible, checkedList, indeterminate, checkAll, plainOptions } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            // selections: [
            //     Table.SELECTION_ALL,
            //     Table.SELECTION_INVERT,
            //     Table.SELECTION_NONE,
            //     {
            //         key: 'odd',
            //         text: 'Select Odd Row',
            //         onSelect: changableRowKeys => {
            //             let newSelectedRowKeys = [];
            //             newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            //                 if (index % 2 !== 0) {
            //                     return false;
            //                 }
            //                 return true;
            //             });
            //             this.setState({ selectedRowKeys: newSelectedRowKeys });
            //         },
            //     },
            //     {
            //         key: 'even',
            //         text: 'Select Even Row',
            //         onSelect: changableRowKeys => {
            //             let newSelectedRowKeys = [];
            //             newSelectedRowKeys = changableRowKeys.filter((key, index) => {
            //                 if (index % 2 !== 0) {
            //                     return true;
            //                 }
            //                 return false;
            //             });
            //             this.setState({ selectedRowKeys: newSelectedRowKeys });
            //         },
            //     },
            // ],
        };
        return (
            <div className='dctReport_list'>
                <div className={'table-list'}>
                    <Table
                        rowSelection={rowSelection}
                        locale={{ emptyText: "暂无数据" }}
                        columns={columns}
                        dataSource={dataList}
                        showHeader={true}
                        defaultExpandAllRows={true}
                        expandIconAsCell={false}
                        pagination={dataList.length !== 0 && pagination}
                        rowKey={'subjectCode'}
                        footer={() => <div className='case-audit-table-footer' >{allCount > 0 && <>共{allCount}条记录</>}</div>}
                        scroll={{ x: 2000 }}
                    />
                    {
                        dialogVisible &&
                        <Modal
                            className='case-audit-checkbox-wrapper'
                            visible={dialogVisible}
                            title="请选择拒绝理由"
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                    返回
                                </Button>,
                                <Button key="submit" type="primary" loading={dialogLoading} onClick={this.handleOk}>
                                    确认
                                </Button>
                            ]}
                        >
                            <Checkbox.Group options={rejectReasonArr} onChange={this.rejectResononsChange} />
                            {
                                showExtraReason &&
                                <textarea className='case-audit-text-area' onChange={this.extraReasonChange} value={rejectCheckedExtra} style={{ resize: 'none' }}></textarea>
                            }
                        </Modal>
                    }

                    {this.state.visibleLabor && <ModalLabor
                        visible={this.state.visibleLabor}
                        closeModal={this.closeLaborModal}
                        content={this.state.laborAgreementContent}
                        readOnly={true}
                    />}

                    {/* 表单填写弹框 */}
                    {
                        this.state.formModalVisible && (
                            <SubjectModal usingQuestion subjectCode={this.state.subjectCode} onClose={()=> this.closeFormModal()}/>
                        )
                    }

                    {/* 编辑备注 */}
                    <Modal
                        visible={remarkVisible}
                        title={'修改备注'}
                        onCancel={this.onCloseRemark}
                        width={'30%'}
                        centered
                        closable={false}
                        destroyOnClose
                        maskClosable={false}
                        footer={[
                            <Button key="back" onClick={this.onCloseRemark}>
                                取消
                            </Button>,
                            <Button key="submit" type="primary" loading={dialogLoading} onClick={this.handleRemarkOk}>
                                确认
                            </Button>
                        ]}
                    >
                        <Input onChange={this.onChangeRemark} allowClear value={remarkValues} />
                    </Modal>

                    {/* 部分撤销 */}
                    <Modal
                        visible={cancleVisible}
                        title={'请选择撤销的内容'}
                        onCancel={this.onCloseCancel}
                        width={'30%'}
                        centered
                        closable={false}
                        destroyOnClose
                        maskClosable={false}
                        footer={[
                            <Button key="back" onClick={this.onCloseCancel}>
                                取消
                            </Button>,
                            <Button key="submit" type="primary" loading={dialogLoading} onClick={this.handleCancelOk}>
                                确认
                            </Button>
                        ]}
                    >
                        <div className='part_cancel'>
                            <Checkbox indeterminate={indeterminate} onChange={this.onCheckAllChange} checked={checkAll}>
                                全选
                            </Checkbox><br /><br />
                            <CheckboxGroup options={plainOptions} value={checkedList} onChange={this.onCheckedList} />
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default CaseAuditList;